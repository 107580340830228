import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useAddSubscriberMutation } from "../../Redux/services/SubscribeApi";

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [addSubscriber] = useAddSubscriberMutation();
  const handleNavClick = sectionId => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => scrollToSection(sectionId), 100);
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleAddSubscriber = async () => {
    if (email) {
      const response = await addSubscriber(email).unwrap();
      try {
        if (response.success) {
          alert(t("subscribedSuccessfully"));
          setEmail("");
        }
      } catch (error) {
        console.log(error);
        alert("An error occurred");
      }
    } else {
      alert("Please enter email");
    }
  };

  return (
    <div
      className="container-fluid bg-primary text-light footer wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5 px-lg-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4">{t("footerAddressTitle")}</h4>
            <p>
              {/* <i className="fa fa-map-marker-alt me-3"></i> */}
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="text-center align-items-center  text-white fs-6 fw-normal rounded-circle me-3 "
                style={{
                  "--bs-btn-hover-color": "#4294E3",
                  width: "15px",
                  height: "15px",
                }}
              />
              {t("footerAddress")}
            </p>
            <p>
              {/* <i className="fa fa-phone-alt me-3"></i> */}
              <FontAwesomeIcon
                icon={faPhoneAlt}
                className="text-center align-items-center  text-white fs-6 fw-normal rounded-circle me-3 "
                style={{
                  "--bs-btn-hover-color": "#4294E3",
                  width: "15px",
                  height: "15px",
                }}
              />
              {t("footerNumber")}
            </p>
            <p>
              {/* <i className="fa fa-envelope me-3"></i> */}
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-center align-items-center  text-white fs-6 fw-normal rounded-circle me-3 "
                style={{
                  "--bs-btn-hover-color": "#4294E3",
                  width: "15px",
                  height: "15px",
                }}
              />
              {t("footerEmail")}
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                href={t("footerFacebook")}
              >
                {/* <i className="fab fa-facebook-f"></i> */}
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="p-2 fs-6 fw-normal "
                  style={{
                    "--bs-btn-hover-color": "#4294E3",
                    width: "17px",
                    height: "17px",
                  }}
                />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href={t("footerInstagram")}
              >
                {/* <i className="fab fa-instagram"></i> */}
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="p-2 fs-6 fw-normal "
                  style={{
                    "--bs-btn-hover-color": "#4294E3",
                    width: "17px",
                    height: "17px",
                  }}
                />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href={t("footerLinkedin")}
              >
                {/* <i className="fab fa-linkedin-in"></i> */}
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="p-2 fs-6 fw-normal "
                  style={{
                    "--bs-btn-hover-color": "#4294E3",
                    width: "17px",
                    height: "17px",
                  }}
                />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href={t("footerYoutube")}
              >
                {/* <i className="fab fa-youtube"></i> */}
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="p-2 fs-6 fw-normal "
                  style={{
                    "--bs-btn-hover-color": "#4294E3",
                    width: "17px",
                    height: "17px",
                  }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4">{t("footerQuickLinks")}</h4>
            <Link
              className="btn btn-link text-decoration-none"
              to=""
              onClick={() => handleNavClick("about")}
            >
              {t("footerAbout")}
            </Link>
            <Link
              className="btn btn-link text-decoration-none"
              to=""
              style={{ fontFamily: "Font Awesome 5 Free" }}
              onClick={() => handleNavClick("contact")}
            >
              {t("footerContactUs")}
            </Link>

            <Link
              className="btn btn-link text-decoration-none"
              // target="_blank"
              to="/terms"
            >
              {t("footerTerms")}
            </Link>
          </div>
          <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4">{t("footerPopularLinks")}</h4>
            <Link
              className="btn btn-link text-decoration-none"
              to=""
              onClick={() => handleNavClick("about")}
            >
              {t("footerAbout")}
            </Link>
            <Link
              className="btn btn-link text-decoration-none"
              to=""
              onClick={() => handleNavClick("contact")}
            >
              {t("footerContactUs")}
            </Link>

            <Link
              className="btn btn-link text-decoration-none"
              // target="_blank"
              to="/terms"
            >
              {t("footerTerms")}
            </Link>
          </div>
          <div className="col-md-6 col-lg-3">
            <h4 className="text-white mb-4">{t("footerNewsletter")}</h4>
            <p>{t("footerNewsletterText")}</p>
            <div className="position-relative w-100 mt-3">
              <input
                className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={t("youremail")}
                style={{ height: "48px" }}
              />
              <button
                type="button"
                onClick={handleAddSubscriber}
                className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
              >
                <i className="fa fa-paper-plane text-primary-gradient fs-4"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-lg-5">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link
                className="border-bottom text-decoration-none"
                to="#"
              >
                AddMe
              </Link>
              , {t("footerAllRightsReserved")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
