import classNames from 'classnames';

const Button = ({ variant, className, children, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(className)}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;