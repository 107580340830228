import React, { useEffect, useState } from "react";
import useAsyncCall from "../../hooks/useAsyncCall";

import { useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa6";

import zazaCard from "../../assets/zazaCard/MAKETEBELtextorangebgcropped.png";
import AddmeLogo from "../../assets/logoUpdated.png";
import AddmeWaveLogoWhite from "../../assets/addmewavelogowhite.png";
import "../../Fonts/fonts.css";
import Share from "../share/share";
import { useTranslation } from "react-i18next";

import Modal from "../eventRegistrationModal/Modal";
import EventAuthUser from "../eventAuthorizedUsers/EventAuthUser";

const contactInfo = [
  {
    icon: <FaFacebook />,
    link: "https://www.facebook.com/groups/1029884185118988",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },

  {
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/groups/13031222/",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaWhatsapp />,
    link: "https://chat.whatsapp.com/LKQw4kT7NrnH2Mwet7iWLn",
    className: "bg-success",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
];

export default function Bc463({ isUserPage }) {
  const username = window.location.pathname.split("/")[1].toLocaleLowerCase();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [card, setCard] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getCard = useAsyncCall();

  const cardImage = card?.cardImage;

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${username}`);
  };

  useEffect(() => {
    const checkIsCardPage = async () => {
      getCard
        .request("get", `/card/getcard/${username}`)
        .then(e => {
          setCard(e.data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    };

    checkIsCardPage();
  }, [username]);

  return (
    <div
      className="d-flex flex-column  justify-content-center  text-center  "
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        className="d-flex flex-column space-between "
        style={{
          //   height: "100vh",

          fontFamily: "DejaVu_Sans",
          backgroundColor: "#ec7345",
        }}
      >
        <header
          className="d-flex flex-row gap-1  ms-3 mt-2   align-items-md-center position-fixed "
          style={{
            width: "100vw",
            backgroundColor: "#ec7345",
            left: "-16px",
            top: "-8px",
            padding: " 5px",
            boxShadow: "0px 0px 10px 0px #6966EE",
          }}
        >
          <img
            src={zazaCard}
            alt=""
            className="logo ms-2 d-none d-md-block"
            style={{ width: "150px", height: "50px" }}
          />

          <figure className="d-none d-md-flex gap-md-1 align-items-md-center position-relative justify-content-between w-100">
            <div className="position-absolute ">
              <div
                className="position-absolute   align-items-md-center d-md-flex "
                style={{
                  width: "200px",
                  top: "-27px",
                }}
              >
                <a
                  className="text-decoration-none "
                  target="_blank"
                  href="https://addme.ge/"
                  style={{
                    color: "#6966EE",
                  }}
                  rel="noreferrer"
                >
                  Powered by
                  <img
                    src={AddmeLogo}
                    alt=""
                    style={{ width: "80px", height: "50px" }}
                  />
                </a>{" "}
              </div>
            </div>
          </figure>

          <img
            className="logo d-md-none ms-2"
            src={zazaCard}
            alt=""
            style={{ width: "90px", height: "25px" }}
          />
          <figure className="d-flex d-md-none gap-1 align-items-center">
            <div className="position-relative">
              <div
                className="position-absolute   align-items-center d-flex "
                style={{
                  width: "200px",
                  top: "-30px",
                }}
              >
                <a
                  className="text-decoration-none "
                  target="_blank"
                  href="https://addme.ge/"
                  style={{
                    color: "#6966EE",
                    fontFamily: "georgian_bold",
                    fontSize: "0.7rem",
                  }}
                  rel="noreferrer"
                >
                  Powered by
                  {/* mobile */}
                  <img
                    src={AddmeLogo}
                    alt=""
                    style={{ width: "70px", height: "60px" }}
                  />
                </a>{" "}
              </div>
            </div>{" "}
          </figure>
        </header>

        <main className="d-md-flex flex-column justify-content-md-center   pt-5 mt-md-5  ">
          {/* Banner */}
          <figure className="mx-auto d-flex  justify-content-center my-3 ">
            <img
              src={`https://storage.googleapis.com/addmeimages/${cardImage}`}
              alt=""
              className="img-account-profile rounded-circle mb-2"
              width={200}
              height={200}
            />
          </figure>
          <section
            className="d-flex flex-column  justify-content-center align-items-center mb-3 mt-4"
            style={{
              fontSize: "0.8rem",
              fontFamily: "DejaVu_Sans",
            }}
          >
            <span className="text-center   text-black fw-bold text-white">
              MaketebeL-თან ერთად შეგიძლია
            </span>
            <span className="text-center   text-black fw-bold text-white">
              იპოვო სანდო პარტნიორი ორგანიზაცია
            </span>
          </section>
          <section className="d-flex gap-3  justify-content-center align-items-center  mt-3 mx-auto mb-3">
            {contactInfo.map((item, index) => (
              <a
                href={item.link}
                target="_blank"
                key={index}
                className={item.className}
                style={item.style}
                rel="noreferrer"
              >
                {item.icon}
              </a>
            ))}
          </section>
          <div className="row">
            {card?.cardItemInputs.find(x => x.name === "Firstname") !== null &&
              card?.cardItemInputs.find(x => x.name === "Lastname") !== null && (
                <div className="col-12 text-white">
                  {card?.cardItemInputs.find(x => x.name === "Firstname")?.value}{" "}
                  {card?.cardItemInputs.find(x => x.name === "Lastname")?.value}
                </div>
              )}
            {card &&
              card?.cardItemInputs.map((item, index) => {
                if (
                  item.name === "Adress" ||
                  item.name === "Company" ||
                  item.name === "Position"
                ) {
                  return (
                    <div
                      className="col-12 text-white"
                      key={index}
                    >
                      {item?.value}
                    </div>
                  );
                } else if (item.name === "Phone1" || item.name === "Phone2") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`tel:${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Website") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={item?.value}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Whatsapp") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`https://wa.me/${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Wechat") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`weixin://dl/chat?${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Skype") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`skype:${item?.value}?chat`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Telegram") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`https://t.me/${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Viber") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`viber://chat/?number=${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.name === "Email") {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={`mailto:${item?.value}`}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                } else if (item.icon) {
                  return (
                    <div className="col-4">
                      <a
                        target="_blank"
                        href={item?.value}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          width={48}
                          height={48}
                          alt="icon"
                        />
                      </a>
                    </div>
                  );
                }
              })}
          </div>

          {/* Agenda */}
          <section className="container d-flex flex-column justify-content-center align-items-center  gap-2    mt-2 ">
            <div className="d-flex justify-content-center mt-3 "></div>
            <div className="d-flex flex-column gap-3 justify-content-center align-items-center  text-white"></div>
          </section>
          {/* Trainers */}

          {/* Offer */}
        </main>
        <footer className="d-flex flex-column gap-3  align-items-center mt-1 mb-5 pb-5">
          <div
            className="py-1 position-relative align-content-center  d-flex  justify-content-center    text-white  text-decoration-none small rounded-3 mb-md-2   "
            style={{
              backgroundColor: "#6966EE",
              cursor: "pointer",
              paddingLeft: "20px",
              paddingRight: "4px",
            }}
            onClick={copyToClipboard}
          >
            <img
              src={AddmeWaveLogoWhite}
              alt=""
              className="position-absolute "
              style={{ width: "25px", height: "20px", left: "-4px", top: "3px" }}
            />
            <span className="">
              Create Your{" "}
              <span
                style={{
                  fontFamily: "georgian_bold",
                }}
              >
                AddMe
              </span>
            </span>
          </div>
          <div>
            {/* <Modal />
            <EventAuthUser /> */}
          </div>
        </footer>
        <div className="position-fixed sticky-bottom w-100">
          <Share
            username={username}
            isUserPage={isUserPage}
          />
        </div>
      </div>
    </div>
  );
}
