import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import ImageCrop from "../../components/image/imageCrop";
import ImageCropProvider from "../../providers/imageCropProvider";

import Button from "../../components/ui/button";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-number-input";
import Incognito from "../../assets/img/incognito.png";
import QrCodeTypeTwo from "../../assets/qr_download.png";
import QrCodeTypeOne from "../../assets/qr_web.png";
import Modal from "react-bootstrap/Modal";
import "../../assets/share.css";
import Navbar from "../../components/navbar/navbar";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { FaCopy } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { updateCardItemInput, toggleAnonymous } from "../../Redux/slices/UserSlice";
import { useGetUserProfileDataQuery } from "../../Redux/services/UserApi";
import {
  useGetCardQRsQuery,
  useCreateCardMutation,
  useDeleteCardInputItemMutation,
  useDeleteCardMutation,
  useAddCardInputItemMutation,
  useGetCardsQuery,
  useUpdateCardMutation,
} from "../../Redux/services/CardApi";

import { skipToken } from "@reduxjs/toolkit/query";
import AccountDetails from "../accountDetailsCard/AccountDetailsCard";
import PremiumFeatureCard from "./components/PremiumFeatureCard";

import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import Sidebar from "../../components/sidebar/Sidebar";

const Profile = () => {
  const { t } = useTranslation();
  const [showModalFirst, setShowModalFirst] = useState(false);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [cardInputValue, setCardInputValue] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [activeCardId, setActiveCardId] = useState("");
  const [activeCard, setActiveCard] = useState({});

  const dispatch = useDispatch();
  const { data: userData } = useGetUserProfileDataQuery();
  const { data: userCard, isSuccess: successFetch } = useGetCardsQuery();
  const { data: cardQRs, isFetching: fetchingQRs } = useGetCardQRsQuery(
    activeCardId ? activeCardId : skipToken
  );

  const [createNewCard] = useCreateCardMutation();
  const [updateCard, { isLoading: updateCardLoader }] = useUpdateCardMutation();
  const [deleteCard] = useDeleteCardMutation();
  const [deleteCardInputItem] = useDeleteCardInputItemMutation();
  const [addCardInputItem] = useAddCardInputItemMutation();

  const { token: accessToken, user, cards } = useSelector(state => state.user);

  const decodedToken = accessToken ? jwtDecode(accessToken) : null;

  const inputChangeHandler = async event => {
    event.preventDefault();
    const responseBody = {};
    const formData = new FormData(event.currentTarget);
    formData.forEach((value, property) => (responseBody[property] = value));

    const response = await updateCard(responseBody);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("updateCardSuccess"), type: "success" })
        );
      } else {
        dispatch(setToastNotification({ message: t("error"), type: "error" }));
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
      console.log(error);
    }
  };

  const handleInputChange = (cardIndex, inputIndex, value, id) => {
    dispatch(updateCardItemInput({ cardIndex, inputIndex, value }));
  };

  const handleIsAnonymousChange = (cardIndex, e) => {
    const newValue = e.target.checked;

    dispatch(toggleAnonymous({ cardIndex, newValue }));
  };

  function onCardImageChange(image, e) {
    const reader = new FileReader();

    reader.readAsDataURL(image);
    reader.onload = () => {
      document.getElementById(`hidden_base64_${e}`).value =
        reader.result.split(",")[1];
      document.getElementById(`hidden_name_${e}`).value = image.name;
      document.getElementById(`hidden_size_${e}`).value = image.size;
      document.getElementById(`hidden_type_${e}`).value = image.type;
    };
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://addme.ge/auth/signup?referral=${user.username}`
    );
    setTimeout(() => {
      dispatch(setToastNotification({ message: t("linkCopied"), type: "success" }));
    }, 1000);
  };

  const handleCreateCard = async () => {
    const response = await createNewCard(cardInputValue);

    console.log(response);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardCreated"), type: "success" })
        );

        setShowCardModal(false);
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }

    setCardInputValue("");
  };

  const handleDeleteCard = async id => {
    const response = await deleteCard(id);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardDeleted"), type: "success" })
        );

        setSelectedCardIndex(0);
        setActiveCardId(cards[0]?.cardId);
        setActiveCard(cards[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomCardValue = e => {
    setCardInputValue(e.target.value);
  };

  const handleShowCard = (index, id) => {
    setSelectedCardIndex(index);
    setActiveCardId(id);
  };

  const handleAddItemToCard = async (id, item, index) => {
    const response = await addCardInputItem({ id, item });
    console.log(response.data);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardInputItemAdd"), type: "success" })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const handleDeleteItem = async id => {
    const response = await deleteCardInputItem(id);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("cardInputItemDelete"),
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const visibleCards = cards?.slice(0, user?.pricingPlan.cardLimit);

  useEffect(() => {
    if (successFetch) {
      const getActiveCard = visibleCards?.find(card => card.cardId === activeCardId);
      if (selectedCardIndex === 0) {
        setActiveCardId(cards[0]?.cardId);
      }
      if (getActiveCard) {
        setActiveCard(getActiveCard);
      } else {
        setActiveCard(cards[0]);
      }
    }
  }, [
    activeCardId,
    cards,
    selectedCardIndex,
    successFetch,
    userCard?.data,
    visibleCards,
  ]);

  return (
    <>
      {/* <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      /> */}
      <div className="d-flex px-4 mt-5 ">
        <div className="container-xl">
          <div className="row justify-content-end mt-md-5 pt-md-5 pb-md-3  ">
            <div className="col-md-6 col-12 align-items-center d-flex justify-content-center mb-lg-0 mb-3 ">
              <span
                onClick={copyToClipboard}
                className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4 d-flex justify-content-center align-items-center gap-2"
              >
                {t("referalLinkButton")}
                <FaCopy />
              </span>
            </div>

            {(decodedToken?.Role === "Admin") |
            (decodedToken?.Role === "SuperAdmin") ? (
              <div className="col-md-6 col-12 align-items-center d-flex justify-content-center mb-lg-0 mb-3 ">
                <Link
                  to="/admin"
                  className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                >
                  Admin
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>

          <hr className="mt-0 mb-4"></hr>
          <div
            className="row"
            style={{ justifyContent: "center" }}
          >
            <div className="col-xl-8">
              <div className="row">
                <div className="col-xl-12">
                  <AccountDetails />
                  {(user?.pricingPlan?.name !== "Free") & successFetch ? (
                    <PremiumFeatureCard
                      selectedCardIndex={selectedCardIndex}
                      activeCard={activeCard}
                      activeCardId={activeCardId}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {user?.pricingPlan?.cardLimit >= 1 && (
                  <div>
                    {visibleCards?.map((item, index) => (
                      <button
                        onClick={() => handleShowCard(index, item.cardId)}
                        className={`btn btn-primary-gradient mx-2 mb-3  ${
                          visibleCards[selectedCardIndex]?.cardId === item.cardId
                            ? "active"
                            : ""
                        }`}
                      >
                        {item?.cardName}
                      </button>
                    ))}

                    <button
                      className="btn btn-primary-gradient mb-3"
                      onClick={() => setShowCardModal(true)}
                      disabled={
                        visibleCards?.length === user?.pricingPlan?.cardLimit
                      }
                    >
                      {t("createCard")}
                    </button>
                    <Modal
                      onHide={() => setShowCardModal(false)}
                      show={showCardModal}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Body>
                        <div className="d-flex justify-content-center align-items-center gap-3 ">
                          <div className="d-flex flex-column gap-3 ">
                            <h6 className="small">{t("createCard")}</h6>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={cardInputValue}
                              onChange={handleCustomCardValue}
                              className="form-control"
                              placeholder={t("createCardPlaceholder")}
                            />
                          </div>
                          <button
                            className="btn btn-primary-gradient align-self-end "
                            onClick={handleCreateCard}
                          >
                            შექმნა
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
                {cards?.map((item, index) => {
                  return (
                    <>
                      {selectedCardIndex === index && (
                        <div
                          className="col-xl-12"
                          key={index}
                        >
                          <div className="card mb-4">
                            <div className="card-header">{t("cardDetails")}</div>
                            <div className="card-body">
                              <form
                                onSubmit={inputChangeHandler}
                                noValidate
                                autoComplete="off"
                                className="container"
                              >
                                <input
                                  hidden
                                  name="CardId"
                                  value={item.cardId}
                                />
                                <div className="row gx-3 mb-3 text-center">
                                  <ImageCropProvider>
                                    <ImageCrop
                                      id={`image_${item.cardId}_${index}`}
                                      image={`https://storage.googleapis.com/addmeimages/${item.cardImage}`}
                                      width={185}
                                      height={185}
                                      showChange={true}
                                      classes={"rounded-circle"}
                                      onChange={onCardImageChange}
                                    />
                                  </ImageCropProvider>
                                  <input
                                    name="CardImageBase64"
                                    id={`hidden_base64_image_${item.cardId}_${index}`}
                                    hidden
                                  />
                                  <input
                                    name="CardImageName"
                                    id={`hidden_name_image_${item.cardId}_${index}`}
                                    hidden
                                  />
                                  <input
                                    name="CardImageSize"
                                    id={`hidden_size_image_${item.cardId}_${index}`}
                                    hidden
                                  />
                                  <input
                                    name="CardImageType"
                                    id={`hidden_type_image_${item.cardId}_${index}`}
                                    hidden
                                  />
                                  <span>{t("imageSize")}</span>
                                </div>
                                <div className="row gx-3 mb-3">
                                  {item?.cardItemInputs?.map((subItem, subIndex) => {
                                    return (
                                      <div className="col-md-6">
                                        {subItem.icon ? (
                                          <div className="input-group mb-3 d-flex flex-column ">
                                            <div className="d-flex">
                                              <div className="input-group-prepend">
                                                <span
                                                  className="input-group-text"
                                                  id="basic-addon1"
                                                >
                                                  <img
                                                    width={30}
                                                    height={30}
                                                    src={`${window.location.origin}/${subItem.icon}`}
                                                    alt="icon"
                                                  />
                                                </span>
                                              </div>
                                              {subItem.isPhoneFormat ? (
                                                <PhoneInput
                                                  placeholder="Enter phone number"
                                                  className="form-control d-flex"
                                                  name={subItem.id}
                                                  defaultCountry="GE"
                                                  withCountryCallingCode={true}
                                                  country="GE"
                                                  value={subItem.value}
                                                  onChange={e =>
                                                    handleInputChange(
                                                      index,
                                                      subIndex,
                                                      e
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name={subItem.id}
                                                  placeholder={subItem.placeholder}
                                                  value={subItem.value}
                                                  onChange={e =>
                                                    handleInputChange(
                                                      index,
                                                      subIndex,
                                                      e.target.value,
                                                      subItem.id
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                            {subItem?.limit > 1 && (
                                              <>
                                                <button
                                                  className="btn btn-primary-gradient rounded-3 mt-2 "
                                                  type="button"
                                                  disabled={
                                                    subItem.limit === subItem.count
                                                  }
                                                  onClick={() => {
                                                    handleAddItemToCard(
                                                      item.cardId,
                                                      subItem,
                                                      subIndex
                                                    );
                                                  }}
                                                >
                                                  {t("add")}
                                                </button>
                                                {subItem.count > 1 && (
                                                  <button
                                                    className="btn btn-danger rounded-3 mt-2"
                                                    type="button"
                                                    onClick={() =>
                                                      handleDeleteItem(subItem.id)
                                                    }
                                                  >
                                                    {t("delete")}
                                                  </button>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        ) : subItem.isPhoneFormat ? (
                                          <PhoneInput
                                            placeholder="Enter phone number"
                                            className="form-control"
                                            name={subItem.id}
                                            defaultCountry="GE"
                                            withCountryCallingCode={true}
                                            country="GE"
                                            value={subItem.value}
                                            onChange={e =>
                                              handleInputChange(index, subIndex, e)
                                            }
                                          />
                                        ) : (
                                          <div className="form-group mb-3">
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder={subItem.placeholder}
                                              value={subItem.value}
                                              name={subItem.id}
                                              onChange={e =>
                                                handleInputChange(
                                                  index,
                                                  subIndex,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="row gx-3 mb-3">
                                  <div className="col-md-6">
                                    <div className="form-check">
                                      <input
                                        id={`anonym_${item.cardId}_${index}`}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item.isAnonymous}
                                        onChange={e =>
                                          handleIsAnonymousChange(index, e)
                                        }
                                        name="IsAnonymous"
                                        value={item.isAnonymous}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={`anonym_${item.cardId}_${index}`}
                                      >
                                        <img
                                          src={Incognito}
                                          alt="Icon"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  type="submit"
                                  text={t("saveCards")}
                                  isLoading={updateCardLoader}
                                />
                                {user?.pricingPlan?.name !== "Free" &&
                                  selectedCardIndex !== 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger mt-2"
                                      onClick={() => handleDeleteCard(item.cardId)}
                                    >
                                      {t("delete")}
                                    </button>
                                  )}
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <div
        className="text-center d-flex justify-content-center   align-items-center    sticky-bottom "
        style={{
          backgroundColor: "white",
          gap: "5rem",
          left: "0px",
        }}
      >
        <div
          className="border-top d-md-none"
          style={{
            width: "100vw",
            position: "absolute",
            top: "0",
          }}
        ></div>

        <button
          className="share-item py-2 px-3"
          onClick={() => setShowModalSecond(true)}
        >
          <img
            src={QrCodeTypeOne}
            alt=""
            className="img-fluid "
            width={54}
            height={54}
          />
        </button>
        <button
          className="share-item py-2 px-3"
          onClick={() => setShowModalFirst(true)}
        >
          <img
            src={QrCodeTypeTwo}
            alt=""
            className="img-fluid "
            width={54}
            height={54}
          />
        </button>

        <Modal
          show={showModalFirst}
          onHide={() => setShowModalFirst(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex flex-column  justify-content-center align-items-center ">
              <img
                src={cardQRs?.data?.type2QR}
                width={250}
                height={250}
                alt="QR Code"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showModalSecond}
          onHide={() => setShowModalSecond(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            {fetchingQRs ? (
              <div className="container d-flex justify-content-center align-content-center">
                <div
                  className="spinner-border "
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column  justify-content-center align-items-center ">
                <img
                  src={cardQRs?.data?.type1QR}
                  alt=""
                  width={250}
                  height={250}
                  className=""
                />
                <a
                  className="d-block"
                  href={cardQRs?.data?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cardQRs?.data?.link}
                </a>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <div
          className="border-top d-md-none"
          style={{
            width: "100vw",
            position: "absolute",
            bottom: "0",
          }}
        ></div>
      </div>
    </>
  );
};

export default Profile;
