import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { resetToastNotification } from "../../Redux/slices/ToastNotificationSlice";

const ToastComponent = () => {
  const { type, message } = useSelector(state => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      const duration = 5000;
      if (type === "success") {
        toast.success(message, { duration });
      } else if (type === "error") {
        toast.error(message, { duration });
      }
      dispatch(resetToastNotification());
    }
  }, [message, type, dispatch]);

  return null;
};

export default ToastComponent;
