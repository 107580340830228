import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Navbar from "../../components/navbar/navbar";

import {
  useGetPricingPlansQuery,
  useCreatePricingPlanMutation,
  useEditPricingPlanMutation,
  useDeletePricingPlanMutation,
} from "../../Redux/services/PricingPlanApi";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import { useDispatch } from "react-redux";
import CreatePricingTier from "./components/CreatePricingTier";
import PricingTiers from "./components/PricingTiers";
import EditablePricingTier from "./components/EditablePricingTier";

export default function PricingTier() {
  const [pricingTier, setPricingTier] = useState({
    name: "",
    cardLimit: 0,
    shortLinkLimit: 0,
    qrLimit: 0,
    canChangeUsername: false,
    canAnalytics: false,
    canChooseTemplate: false,
    canChooseQRLogo: false,
    viberLimit: 0,
    phoneLimit: 0,
    whatsappLimit: 0,
    skypeLimit: 0,
    emailLimit: 0,
    youtubeLimit: 0,
    firstnameLimit: 0,
    telegramLimit: 0,
    tiktokLimit: 0,
    adressLimit: 0,
    githubLimit: 0,
    pinterestLimit: 0,
    twitterLimit: 0,
    instagramLimit: 0,
    spotifyLimit: 0,
    companyLimit: 0,
    facebookLimit: 0,
    linkedinLimit: 0,
    wechatLimit: 0,
    websiteLimit: 0,
    udemyLimit: 0,
    lastnameLimit: 0,
    positionLimit: 0,
  });
  const dispatch = useDispatch();

  const { data: pricingTiers, isLoading: pricingTierLoading } =
    useGetPricingPlansQuery();
  const [editPricingPlan] = useEditPricingPlanMutation();
  const [deleteTier] = useDeletePricingPlanMutation();
  const [createTier] = useCreatePricingPlanMutation();

  const [editablePricingTier, setEditablePricingTier] = useState({});
  const [editableModal, setEditableModal] = useState(false);
  const [toggleLimitCard, setToggleLimitCard] = useState(false);

  const { t } = useTranslation();

  const handleInputValues = e => {
    setPricingTier(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.type === "checkbox") {
      setPricingTier(prev => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    }
  };

  const handleEditInputValues = e => {
    setEditablePricingTier(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.type === "checkbox") {
      setEditablePricingTier(prev => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    }
  };

  const handleCreatePricingTier = async () => {
    const response = await createTier(pricingTier).unwrap();
    console.log(response);
    try {
      if (response.success) {
        dispatch(
          setToastNotification({
            message: "Pricing Tier created successfully",
            type: "success",
          })
        );

        setPricingTier({
          name: "",
          cardLimit: 0,
          shortLinkLimit: 0,
          qrLimit: 0,
          canChangeUsername: false,
          canAnalytics: false,
          canChooseTemplate: false,
          canChooseQRLogo: false,
          viberLimit: 0,
          phoneLimit: 0,
          whatsappLimit: 0,
          skypeLimit: 0,
          emailLimit: 0,
          youtubeLimit: 0,
          firstnameLimit: 0,
          telegramLimit: 0,
          tiktokLimit: 0,
          adressLimit: 0,
          githubLimit: 0,
          pinterestLimit: 0,
          twitterLimit: 0,
          instagramLimit: 0,
          spotifyLimit: 0,
          companyLimit: 0,
          facebookLimit: 0,
          linkedinLimit: 0,
          wechatLimit: 0,
          websiteLimit: 0,
          udemyLimit: 0,
          lastnameLimit: 0,
          positionLimit: 0,
        });
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Error creating Pricing Tier",
          type: "error",
        })
      );
    }
  };

  const handleDeletePricingTier = async id => {
    const response = await deleteTier(id).unwrap();

    try {
      if (response.success) {
        dispatch(
          setToastNotification({
            message: "Pricing Tier deleted successfully",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Error deleting Pricing Tier",
          type: "error",
        })
      );
    }
  };

  const handleEditPricingTier = async id => {
    const response = await editPricingPlan({ id, editablePricingTier }).unwrap();
    try {
      if (response.success) {
        dispatch(
          setToastNotification({
            message: "Pricing Tier updated successfully",
            type: "success",
          })
        );

        setEditableModal(false);
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Error updating Pricing Tier",
          type: "error",
        })
      );
      console.log(error);
    }
  };

  const handleCancelBtn = () => {
    setEditableModal(false);
    setEditablePricingTier(null);
  };

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container mt-lg-5 pt-lg-5">
        <div>
          <div>
            <Link
              to="/admin"
              className="btn btn-primary"
            >
              Back To Admin Page
            </Link>
          </div>
        </div>

        <CreatePricingTier
          pricingTier={pricingTier}
          toggleLimitCard={toggleLimitCard}
          handleInputValues={handleInputValues}
          setToggleLimitCard={setToggleLimitCard}
          handleCreatePricingTier={handleCreatePricingTier}
        />

        <div className="row">
          <div className="col-md-12 mx-4">
            <h1>Pricing Tiers</h1>
          </div>
        </div>

        <PricingTiers
          pricingTiers={pricingTiers}
          pricingTierLoading={pricingTierLoading}
          handleDeletePricingTier={handleDeletePricingTier}
          setEditablePricingTier={setEditablePricingTier}
          setEditableModal={setEditableModal}
        />
      </div>
      <Modal
        show={editableModal}
        onHide={() => setEditableModal(false)}
      >
        <Modal.Body>
          <EditablePricingTier
            editablePricingTier={editablePricingTier}
            handleEditInputValues={handleEditInputValues}
            handleEditPricingTier={handleEditPricingTier}
            handleCancelBtn={handleCancelBtn}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
