import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUser } from "../slices/UserSlice";
import config from "../../config/config";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  tagTypes: ["User", "Users", "QRCode"],
  endpoints: builder => ({
    getUserProfileData: builder.query({
      query: () => ({
        url: "/api/user/GetProfileData",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.success) {
            dispatch(setUser(data.data));
          } else {
            console.log("Failed to fetch user profile data");
          }
        } catch (error) {
          console.log("Something went wrong");
        }
      },
      providesTags: ["User"],
    }),
    updateProfile: builder.mutation({
      query: data => ({
        url: "/api/user/UpdateProfileData",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["User"],
    }),

    deactivateAccount: builder.mutation({
      query: () => ({
        url: "/api/User/DeactivateAccount",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    createCustomQR: builder.mutation({
      query: ({ url, name }) => ({
        url: `/api/User/GenerateCustomQR?link=${encodeURIComponent(
          url
        )}&name=${encodeURIComponent(name)}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["QRCode"],
    }),
    getCustomQR: builder.query({
      query: () => ({
        url: "/api/User/GetCustomQRs",
        method: "GET",
      }),
      providesTags: ["QRCode"],
    }),
    deleteCustomQR: builder.mutation({
      query: id => ({
        url: `/api/User/DeleteCustomQR?id=${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["QRCode"],
    }),

    getUsers: builder.query({
      query: () => ({
        url: "/api/user/getusers",
        method: "GET",
      }),

      providesTags: ["Users"],
    }),
    getUser: builder.query({
      query: id => ({
        url: `/api/User/GetUser/${id}`,
        method: "GET",
      }),

      providesTags: ["UserProfile"],
    }),

    navigateToCustomQRURL: builder.query({
      query: username => ({
        url: `/api/User/GetCustomQRLink/${username}`,
        method: "GET",
      }),
    }),
    saveProfilePicture: builder.mutation({
      query: data => ({
        url: "api/User/UpdateProfilePicture",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserProfileDataQuery,
  useUpdateProfileMutation,
  useDeactivateAccountMutation,
  useCreateCustomQRMutation,
  useGetCustomQRQuery,
  useDeleteCustomQRMutation,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useNavigateToCustomQRURLQuery,
  useSaveProfilePictureMutation,
} = userApi;
