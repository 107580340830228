export default function CreatePricingTier({
  pricingTier,
  handleInputValues,
  setToggleLimitCard,
  toggleLimitCard,
  handleCreatePricingTier,
}) {
  return (
    <>
      <div className="row mt-2">
        <div className="col-md-4">
          <label className="form-label">Pricing Tier name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="name"
            onChange={e => handleInputValues(e)}
            value={pricingTier.name}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Card Limit</label>
          <input
            type="number"
            className="form-control"
            placeholder="Card Limit"
            name="cardLimit"
            onChange={e => handleInputValues(e)}
            value={pricingTier.cardLimit}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Short Link Limit</label>
          <input
            type="number"
            className="form-control"
            name="shortLinkLimit"
            placeholder="Short Link Limit"
            onChange={e => handleInputValues(e)}
            value={pricingTier.shortLinkLimit}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">QR Code Limit</label>
          <input
            type="number"
            className="form-control"
            placeholder="QR Code Limit"
            name="qrLimit"
            onChange={e => handleInputValues(e)}
            value={pricingTier.qrLimit}
          />
        </div>
        <div className="card mt-3 px-0">
          <div
            className="card-header "
            onClick={() => setToggleLimitCard(prev => !prev)}
            style={{
              cursor: "pointer",
            }}
          >
            <h3>Social media Limits</h3>
          </div>
          {toggleLimitCard && (
            <div className="card-body row ">
              <div className="col-md-4">
                <label className="form-label">Viber Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Viber Limit"
                  name="viberLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.viberLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Phone Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone Limit"
                  name="phoneLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.phoneLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Whatsapp Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Whatsapp Limit"
                  name="whatsappLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.whatsappLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Skype Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Skype Limit"
                  name="skypeLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.skypeLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Email Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Email Limit"
                  name="emailLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.emailLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Youtube Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Youtube Limit"
                  name="youtubeLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.youtubeLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Firstname Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Firstname Limit"
                  name="firstnameLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.firstnameLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Telegram Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Telegram Limit"
                  name="telegramLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.telegramLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Tiktok Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Tiktok Limit"
                  name="tiktokLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.tiktokLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Adress Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Adress Limit"
                  name="adressLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.adressLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Github Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Github Limit"
                  name="githubLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.githubLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Pinterest Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Pinterest Limit"
                  name="pinterestLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.pinterestLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Twitter Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Twitter Limit"
                  name="twitterLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.twitterLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Instagram Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Instagram Limit"
                  name="instagramLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.instagramLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Spotify Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Spotify Limit"
                  name="spotifyLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.spotifyLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Company Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Company Limit"
                  name="companyLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.companyLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Facebook Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Facebook Limit"
                  name="facebookLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.facebookLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Linkedin Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Linkedin Limit"
                  name="linkedinLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.linkedinLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Wechat Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Wechat Limit"
                  name="wechatLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.wechatLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Website Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Website Limit"
                  name="websiteLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.websiteLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Udemy Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Udemy Limit"
                  name="udemyLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.udemyLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Lastname Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Lastname Limit"
                  name="lastnameLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.lastnameLimit}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Position Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Position Limit"
                  name="positionLimit"
                  onChange={e => handleInputValues(e)}
                  value={pricingTier.positionLimit}
                />
              </div>
            </div>
          )}
        </div>

        <div class="form-check form-switch mt-3 ">
          <label
            className="form-check-label"
            htmlFor="username"
          >
            Change username
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="username"
            name="canChangeUsername"
            onChange={e => handleInputValues(e)}
            value={pricingTier.canChangeUsername}
            checked={pricingTier.canChangeUsername}
          />
        </div>

        <div class="form-check form-switch">
          <label
            className="form-check-label"
            htmlFor="analytics"
          >
            Has access to analytics
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="analytics"
            name="canAnalytics"
            onChange={e => handleInputValues(e)}
            value={pricingTier.canAnalytics}
            checked={pricingTier.canAnalytics}
          />
        </div>

        <div class="form-check form-switch">
          <label
            className="form-check-label"
            htmlFor="template"
          >
            Has access to Template
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="template"
            name="canChooseTemplate"
            onChange={e => handleInputValues(e)}
            value={pricingTier.canChooseTemplate}
            checked={pricingTier.canChooseTemplate}
          />
        </div>

        <div class="form-check form-switch mt-3 ">
          <label
            className="form-check-label"
            htmlFor="canChooseQRLogo"
          >
            Can choose QR Logo
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="canChooseQRLogo"
            name="canChooseQRLogo"
            onChange={e => handleInputValues(e)}
            value={pricingTier.canChooseQRLogo}
            checked={pricingTier.canChooseQRLogo}
          />
        </div>
        <button
          onClick={handleCreatePricingTier}
          className="btn btn-primary mt-3"
        >
          Create Pricing Tier
        </button>
      </div>
    </>
  );
}
