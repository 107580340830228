import { useForm, useFormContext } from "react-hook-form";
import InputError from "./inputError";
import { findInputError } from "../../utils/findInputError";
import { isFormInvalid } from "../../utils/isFormValid";
import { useState } from "react";
import parse from "html-react-parser";
const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  multiline,
  showPasswordToggle,
  compareTo,
  compareFrom,
  validateCheckbox,
  disabled,
  value,
}) => {
  const {
    register,
    watch,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const [inputType, setInputType] = useState(type);
  const [togglePasswordClass, setTogglePasswordClass] = useState("fa-eye-slash");

  if (value) {
    setValue(name, value);
  }
  if (compareTo) {
    if (!validation) {
      validation = {};
    }
    Object.assign(validation, {
      validate: val => {
        if (watch(compareTo.key) !== val) {
          return compareTo.message;
        }
      },
    });
  }

  if (compareFrom) {
    if (!validation) {
      validation = {};
    }
    Object.assign(validation, {
      validate: val => {
        trigger(compareFrom.key);
      },
    });
  }

  function togglePassword() {
    if (togglePasswordClass === "fa-eye") {
      setTogglePasswordClass("fa-eye-slash");
      setInputType("password");
    } else {
      setTogglePasswordClass("fa-eye");
      setInputType("text");
    }
  }

  return inputType === "checkbox" ? (
    <div className={`form-check ${isInvalid ? "is-invalid" : ""}`}>
      <div className="position-relative">
        <input
          className="form-check-input"
          type={inputType}
          id={id}
          {...register(name, validation)}
        />
        <label
          className="form-check-label"
          htmlFor={id}
        >
          {parse(label)}
        </label>
      </div>
      {isInvalid && <InputError message={inputErrors.error.message} />}
    </div>
  ) : (
    <div className={`form-group ${isInvalid ? "is-invalid" : ""}`}>
      <div className="position-relative">
        {label ? (
          <label
            htmlFor={id}
            className="font-semibold capitalize"
          >
            {label}
          </label>
        ) : (
          ""
        )}
        {multiline ? (
          <textarea
            id={id}
            className="form-control"
            placeholder={placeholder}
            {...register(name, validation)}
          ></textarea>
        ) : (
          <input
            id={id}
            type={inputType}
            className="form-control"
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, validation)}
          />
        )}
        {showPasswordToggle && (
          <i
            className={`fa ${togglePasswordClass} toggle-password`}
            onClick={togglePassword}
          ></i>
        )}
      </div>
      {isInvalid && <InputError message={inputErrors.error.message} />}
    </div>
  );
};

export default Input;
