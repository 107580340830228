import "./App.css";
import { RouterProvider } from "react-router-dom";
import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { removeAuthorization, removeToken } from "./Redux/slices/UserSlice";

import { router } from "./Routes/Router";
import { Toaster } from "react-hot-toast";
import ToastComponent from "./components/toast/Toast";

function App() {
  const { isAuthorized, token } = useSelector(state => state.user);
  const dispatch = useDispatch();
  localStorage.setItem("token", token);
  localStorage.setItem("isAuthorized", isAuthorized);

  useState(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));

      if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
        localStorage.setItem("isAuthorized", isAuthorized);
      } else {
        dispatch(removeAuthorization());
        localStorage.setItem("isAuthorized", isAuthorized);
        dispatch(removeToken());
      }
    } else {
      dispatch(removeAuthorization());
      dispatch(removeToken());
      localStorage.setItem("isAuthorized", isAuthorized);
    }
  });

  return (
    <>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
      <ToastComponent />
    </>
  );
}

export default App;
