import { Link } from "react-router-dom";

export default function UserCustomQRs({ userData, handleDownload }) {
  return (
    <>
      {userData?.data?.customQrs?.map(customQr => (
        <div
          className="card col-4 p-0 mb-3 ms-3 "
          key={customQr.id}
        >
          <div className="card-header  d-flex  justify-content-center align-content-center    ">
            <h3>{customQr.name}</h3>
          </div>
          <div className="card-body  d-flex  justify-content-center align-content-center  ">
            <img
              src={customQr?.qr}
              alt="qr"
              className="img-thumbnail w-50"
            />
          </div>
          <div className=" col-12 align-items-center d-flex flex-column gap-3  justify-content-md-start justify-content-center  mb-3 ">
            <Link
              to={`/qranalytics/${customQr.guid}?typeId=2`}
              className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
            >
              Analytics
            </Link>
            <button
              className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
              onClick={() => handleDownload(customQr?.qr, customQr?.name)}
            >
              Save QR
            </button>
          </div>
        </div>
      ))}
    </>
  );
}
