export default function EditablePricingTier({
  editablePricingTier,
  handleEditInputValues,
  handleEditPricingTier,
  handleCancelBtn,
}) {
  return (
    <>
      {editablePricingTier && (
        <div className="col-md-4 mb-3 w-100 ">
          <div className="card">
            <div className="card-header">
              <label className="form-label">Edit Pricing Tier Name</label>
              <input
                type="text"
                placeholder="Pricing Title"
                name="name"
                value={editablePricingTier.name}
                onChange={e => handleEditInputValues(e)}
              />
            </div>
            <div className="card-body">
              <div className="d-flex flex-column ">
                <label className="form-label">Card Limit</label>
                <input
                  type="number"
                  placeholder="Card Limit"
                  name="cardLimit"
                  id="cardLimit"
                  value={editablePricingTier.cardLimit}
                  onChange={e => handleEditInputValues(e)}
                />
              </div>
              <div className="d-flex flex-column ">
                <label className="form-label">Card ShortURL</label>
                <input
                  type="number"
                  placeholder="Card ShortURL"
                  name="shortLinkLimit"
                  id="shortLinkLimit"
                  value={editablePricingTier.shortLinkLimit}
                  onChange={e => handleEditInputValues(e)}
                />
              </div>
              <div className="d-flex flex-column ">
                <label className="form-label">Card QR code Limit</label>

                <input
                  type="number"
                  placeholder="Card QR code Limit"
                  name="qrLimit"
                  id="qrLimit"
                  value={editablePricingTier.qrLimit}
                  onChange={e => handleEditInputValues(e)}
                />
              </div>

              <div class="form-check form-switch mt-3 ">
                <label
                  className="form-check-label"
                  htmlFor="canChangeUsername"
                >
                  Edit access to change username
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="canChangeUsername"
                  name="canChangeUsername"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.canChangeUsername}
                  checked={editablePricingTier.canChangeUsername}
                />
              </div>
              <div class="form-check form-switch">
                <label
                  className="form-check-label"
                  htmlFor="canAnalytics"
                >
                  Edit access to analytics
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="canAnalytics"
                  name="canAnalytics"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.canAnalytics}
                  checked={editablePricingTier.canAnalytics}
                />
              </div>

              <div class="form-check form-switch">
                <label
                  className="form-check-label"
                  htmlFor="canChooseTemplate"
                >
                  Edit access to Template
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="canChooseTemplate"
                  name="canChooseTemplate"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.canChooseTemplate}
                  checked={editablePricingTier.canChooseTemplate}
                />
              </div>
              <div class="form-check form-switch">
                <label
                  className="form-check-label"
                  htmlFor="canChooseQRLogo"
                >
                  Edit access to choose QR Logo
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="canChooseQRLogo"
                  name="canChooseQRLogo"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.canChooseQRLogo}
                  checked={editablePricingTier.canChooseQRLogo}
                />
              </div>
              <div class="d-flex flex-column  mt-3 ">
                <label
                  className="form-check-label"
                  htmlFor="phoneLimit"
                >
                  Edit Phone Limit
                </label>
                <input
                  type="number"
                  id="phoneLimit"
                  name="phoneLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.phoneLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="viberLimit"
                >
                  Edit Viber Limit
                </label>
                <input
                  type="number"
                  id="viberLimit"
                  name="viberLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.viberLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="whatsappLimit"
                >
                  Edit Whatsapp Limit
                </label>
                <input
                  type="number"
                  id="whatsappLimit"
                  name="whatsappLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.whatsappLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="skypeLimit"
                >
                  Edit Skype Limit
                </label>
                <input
                  type="number"
                  id="skypeLimit"
                  name="skypeLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.skypeLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="emailLimit"
                >
                  Edit Email Limit
                </label>
                <input
                  type="number"
                  id="emailLimit"
                  name="emailLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.emailLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="youtubeLimit"
                >
                  Edit Youtube Limit
                </label>
                <input
                  type="number"
                  id="youtubeLimit"
                  name="youtubeLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.youtubeLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="firstnameLimit"
                >
                  Edit Firstname Limit
                </label>
                <input
                  type="number"
                  id="firstnameLimit"
                  name="firstnameLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.firstnameLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="telegramLimit"
                >
                  Edit Telegram Limit
                </label>
                <input
                  type="number"
                  id="telegramLimit"
                  name="telegramLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.telegramLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="tiktokLimit"
                >
                  Edit Tiktok Limit
                </label>
                <input
                  type="number"
                  id="tiktokLimit"
                  name="tiktokLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.tiktokLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="adressLimit"
                >
                  Edit Adress Limit
                </label>
                <input
                  type="number"
                  id="adressLimit"
                  name="adressLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.adressLimit}
                />
              </div>

              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="githubLimit"
                >
                  Edit Github Limit
                </label>
                <input
                  type="number"
                  id="githubLimit"
                  name="githubLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.githubLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="pinterestLimit"
                >
                  Edit Pinterest Limit
                </label>
                <input
                  type="number"
                  id="pinterestLimit"
                  name="pinterestLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.pinterestLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="twitterLimit"
                >
                  Edit Twitter Limit
                </label>
                <input
                  type="number"
                  id="twitterLimit"
                  name="twitterLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.twitterLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="instagramLimit"
                >
                  Edit Instagram Limit
                </label>
                <input
                  type="number"
                  id="instagramLimit"
                  name="instagramLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.instagramLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="spotifyLimit"
                >
                  Edit Spotify Limit
                </label>
                <input
                  type="number"
                  id="spotifyLimit"
                  name="spotifyLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.spotifyLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="companyLimit"
                >
                  Edit Company Limit
                </label>
                <input
                  type="number"
                  id="companyLimit"
                  name="companyLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.companyLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="facebookLimit"
                >
                  Edit Facebook Limit
                </label>
                <input
                  type="number"
                  id="facebookLimit"
                  name="facebookLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.facebookLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="linkedinLimit"
                >
                  Edit Linkedin Limit
                </label>
                <input
                  type="number"
                  id="linkedinLimit"
                  name="linkedinLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.linkedinLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="wechatLimit"
                >
                  Edit Wechat Limit
                </label>
                <input
                  type="number"
                  id="wechatLimit"
                  name="wechatLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.wechatLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="websiteLimit"
                >
                  Edit Website Limit
                </label>
                <input
                  type="number"
                  id="websiteLimit"
                  name="websiteLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.websiteLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="udemyLimit"
                >
                  Edit Udemy Limit
                </label>
                <input
                  type="number"
                  id="udemyLimit"
                  name="udemyLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.udemyLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="lastnameLimit"
                >
                  Edit Lastname Limit
                </label>
                <input
                  type="number"
                  id="lastnameLimit"
                  name="lastnameLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.lastnameLimit}
                />
              </div>
              <div class="d-flex flex-column ">
                <label
                  className="form-check-label"
                  htmlFor="positionLimit"
                >
                  Edit Position Limit
                </label>
                <input
                  type="number"
                  id="positionLimit"
                  name="positionLimit"
                  onChange={e => handleEditInputValues(e)}
                  value={editablePricingTier.positionLimit}
                />
              </div>

              <div className="d-flex gap-4 ">
                <button
                  className="btn btn-warning"
                  onClick={() => handleCancelBtn()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => handleEditPricingTier(editablePricingTier.id)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
