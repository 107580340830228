import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import config from "../../config/config";
import Input from "../../components/form/input";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../components/ui/button";
import useAsyncCall from "../../hooks/useAsyncCall";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const forgotPassword = useAsyncCall();
  const onSubmit = methods.handleSubmit(data => {
    setIsLoading(true);
    console.log(data.Email);
    forgotPassword
      .request("post", `/user/SendPasswordReset/${data.Email}`)
      .then(e => {
        setSuccess(true);
        setSuccessText(t("forgotPasswordSuccess"));
        setIsLoading(false);
      });
  });

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="register-photo mt-lg-5 pt-lg-5  ">
        <div className="form-container">
          <div className="image-holder"></div>
          <FormProvider {...methods}>
            <form
              onSubmit={e => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="container"
            >
              <h8 className="text-center mb-5">
                <strong>{t("forgotPasswordText")}</strong>
              </h8>
              <div className="grid gap-5 md:grid-cols-2 mt-3">
                <Input
                  {...{
                    name: "Email",
                    type: "email",
                    id: "email",
                    placeholder: t("signUpEmail"),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpEmailRequired"),
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: t("signUpEmailNotValid"),
                      },
                    },
                  }}
                />
              </div>

              <div className="form-group">
                {success && (
                  <p className="font-semibold text-green-500 flex items-center gap-1">
                    {successText}
                  </p>
                )}
                <div className={!success ? "invalid-feedback d-block" : "d-none"}>
                  {errorText}
                </div>
                <Button
                  func={onSubmit}
                  text={t("forgotPasswordButton")}
                  isLoading={isLoading}
                />
              </div>
              {/* <a href="/auth/signup" className="already">{t('forgotPasswordButton')}</a> */}
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
