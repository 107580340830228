import { useState } from "react";

export default function ContainerBasicSettings() {
  const [toggleMainCard, setToggleMainCard] = useState(false);
  const [toggleFontCard, setToggleFontCard] = useState(false);
  const handleToggleMainCard = () => {
    setToggleMainCard(prev => !prev);
  };
  const handleToggleFontCard = () => {
    setToggleFontCard(prev => !prev);
  };
  return (
    <div className="card">
      <div
        className="card-header"
        onClick={handleToggleMainCard}
      >
        <h3>Container Basic Settings</h3>
      </div>
      <div className={`card-body ${toggleMainCard ? "d-none" : "d-block"}`}>
        <div>
          <label>Background</label>
          <input type="color" />
        </div>
        <div>
          <label>Border</label>
          <input type="color" />
        </div>
        <div>
          <label>Border Radius</label>
          <ul>
            <li>0%</li>
            <li>25%</li>
            <li>50%</li>
          </ul>
        </div>
        <div className="card mb-2">
          <div
            className="card-header"
            onClick={handleToggleFontCard}
          >
            <h3>Font settings</h3>
          </div>
          <div className={`card-body ${toggleFontCard ? "d-none" : "d-block"}`}>
            <div>
              <p>Font Size</p>
              <ul>
                <li>10px</li>
                <li>15px</li>
                <li>20px</li>
              </ul>
            </div>
            <div>
              <p>Font weight</p>
              <ul>
                <li>100</li>
                <li>200</li>
                <li>300</li>
              </ul>
            </div>
            <div>
              <label>Font Color</label>
              <input type="color" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
