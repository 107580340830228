import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useAsyncCall from "../../hooks/useAsyncCall";

export default function TemplateOne({ username }) {
  const [card, setCard] = useState({});
  const { cardId } = useParams();
  const navigate = useNavigate();

  const getCard = useAsyncCall();

  useEffect(() => {
    const url = cardId ? `/Card/GetCard/${cardId}` : `/Card/GetCard/${username}`;
    getCard.request("get", url).then(res => {
      setCard(res.data);
    });
  }, []);

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${username}`);
  };

  return (
    <div>
      {cardId && <h1>Template One</h1>}
      <div className="container-xxl mx-auto d-flex justify-content-center align-items-center flex-column">
        <div
          className=""
          style={{
            width: "400px",
            height: "400px",
            backgroundImage: `url(https://storage.googleapis.com/addmeimages/${card?.cardImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "10% 10% 0 0",
          }}
        ></div>
        <div
          className="d-flex align-items-start"
          style={{
            width: "400px",
          }}
        >
          <div className="d-flex gap-2">
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Firstname" && (
                  <h3
                    key={index}
                    className="fs-4 text-black "
                  >
                    {input.value}
                  </h3>
                )}
                {input.name === "Lastname" && (
                  <h3
                    key={index}
                    className="fs-4 text-black"
                  >
                    {input.value}
                  </h3>
                )}
              </>
            ))}
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Position" && (
                  <p
                    key={index}
                    className="text-black"
                  >
                    {input.value}
                  </p>
                )}
                {input.name === "Company" && (
                  <p
                    key={index}
                    className="text-black"
                  >
                    {input.value}
                  </p>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      {cardId && <Link to={"/auth/card"}>Back to Profile</Link>}
    </div>
  );
}
