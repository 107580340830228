import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import config from "../../config/config";
import Input from "../../components/form/input";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../components/ui/button";
import useAsyncCall from "../../hooks/useAsyncCall";
import { Link, useParams } from "react-router-dom";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const resetPassword = useAsyncCall();
  const [successText, setSuccessText] = useState("");
  const [login, setLogin] = useState("");
  const onSubmit = methods.handleSubmit(data => {
    data.Token = token;
    setIsLoading(true);
    resetPassword.request("post", "/user/resetpassword", data).then(e => {
      if (e.success) {
        setSuccess(true);
        setSuccessText(t("resetPasswordSuccess"));
        setLogin(t("signInButton"));
        alert(t("resetPasswordSuccess"));
        navigate("/auth/signin");
      } else {
        setSuccess(false);
        setSuccessText("");
        if (e.errorCode === 7) {
          setErrorText(t("passwordResetLinkExpired"));
        }
      }
      setIsLoading(false);
    });
  });

  return (
    <>
      <div className="register-photo">
        <div className="form-container">
          <div className="image-holder"></div>
          <FormProvider {...methods}>
            <form
              onSubmit={e => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="container"
            >
              <h2 className="text-center">
                <strong>{t("resetPasswordText")}</strong>
              </h2>
              <div className="grid gap-5 md:grid-cols-2">
                <Input
                  {...{
                    name: "Password",
                    type: "password",
                    id: "password",
                    placeholder: t("resetPassword"),
                    autoComplete: "new-password",
                    showPasswordToggle: true,
                    type: "password",
                    compareFrom: {
                      key: "ConfirmPassword",
                      message: t("signUpPasswordsDontMatch"),
                    },
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpPasswordRequired"),
                      },
                      minLength: {
                        value: 6,
                        message: t("signUpPasswordLenghtRequired", { length: 6 }),
                      },
                      pattern: {
                        message: t("signUpPasswordSpaceNotAllowed"),
                        value: /^\S*$/,
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "ConfirmPassword",
                    type: "password",
                    id: "confirmPassword",
                    placeholder: t("confirmResetPassword"),
                    autoComplete: "new-password",
                    showPasswordToggle: true,
                    type: "password",
                    compareTo: {
                      key: "Password",
                      message: t("signUpPasswordsDontMatch"),
                    },
                  }}
                />
              </div>

              <div className="form-group">
                {success && (
                  // <p className="font-semibold text-green-500 mb-5 flex items-center gap-1">
                  //     {successText}
                  // </p>
                  <div className="already">
                    {t("resetPasswordSuccess")}
                    <Link to="/auth/signin">{t("goBackToLogin")}</Link>
                  </div>
                )}
                <div className={!success ? "invalid-feedback d-block" : "d-none"}>
                  {errorText}
                </div>
                <Button
                  func={onSubmit}
                  text={t("resetPasswordButton")}
                  isLoading={isLoading}
                />
              </div>
              <Link
                to="/auth/signup"
                className="already"
              >
                {t("dontHaveAnAccount")}
              </Link>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
