import { Link, useParams } from "react-router-dom";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useState, useEffect } from "react";

export default function NavigateShortURL() {
  const { id } = useParams();
  const [error, setError] = useState(false);
  console.log(id);
  const navigateToURL = useAsyncCall();
  useEffect(() => {
    navigateToURL.request("get", `/ShortUrl/GetShortUrl?value=${id}`).then(e => {
      if (e.success) {
        window.location.href = e.data;
        setError(false);
      }
      if (e.errorCode === 3) {
        setError(true);
      }
    });
  }, []);
  return (
    <div>
      {error && (
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column">
            <h1>Not Found</h1>
            <Link to="/">Go to Home</Link>
          </div>
        </div>
      )}
    </div>
  );
}
