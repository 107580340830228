import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  cards: null,
  token: null,
  isAuthorized: false,
  selectedUserId: null,
  selectedCard: null,
  registerUsers: [],
  users: [],
  toggleSideBar: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: state => {
      state.token = null;
    },

    setAuthorized: state => {
      state.isAuthorized = true;
    },
    removeAuthorization: state => {
      state.isAuthorized = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    updateCardItemInput: (state, action) => {
      const { cardIndex, inputIndex, value } = action.payload;
      state.cards[cardIndex].cardItemInputs[inputIndex].value = value;
    },
    toggleAnonymous: (state, action) => {
      const { cardIndex, newValue } = action.payload;
      state.cards[cardIndex].isAnonymous = newValue;
    },
    signOutSuccess: state => {
      state.user = null;
      state.cards = null;
      state.token = null;
      state.isAuthorized = false;
    },
    selectUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    unSelectUserId: state => {
      state.selectedUserId = null;
    },
    setRegisterUsers: (state, action) => {
      state.registerUsers.push(action.payload);
    },

    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    setToggleSideBar: (state, action) => {
      state.toggleSideBar = !state.toggleSideBar;
    },
  },
});

export const {
  setToken,
  setAuthorized,
  setUser,
  setCards,
  signOutSuccess,
  removeAuthorization,
  removeToken,
  updateCardItemInput,
  toggleAnonymous,
  selectUserId,
  unSelectUserId,
  setRegisterUsers,
  setUsers,
  setSelectedCard,
  setToggleSideBar,
} = userSlice.actions;

export default userSlice.reducer;
