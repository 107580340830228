import React from "react";
import ScreenShot1 from "../../assets/img/user garet.svg";
import ScreenShot2 from "../../assets/img/1.svg";
import ScreenShot3 from "../../assets/img/2.svg";
import ScreenShot4 from "../../assets/img/3.svg";
import ScreenShot5 from "../../assets/img/4.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Carousel from "../carousels/carousel";
import { Link } from "react-router-dom";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container-xxl position-relative p-0"
      id="home"
    >
      <div className="container-xxl bg-primary hero-header">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-8 text-center text-lg-start">
              <h1 className="text-white mb-4 animated slideInDown">
                {t("heroTitle")}
              </h1>
              <p className="text-white pb-3 animated slideInDown">{t("heroText")}</p>
              <Link
                to="/auth/signup"
                className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft"
              >
                {t("register")}
              </Link>
            </div>
            <div
              className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              {/* <div className="owl-carousel screenshot-carousel screenshot-carousel-2">
                <img
                  className="img-fluid"
                  src={ScreenShot1}
                  alt=""
                />
                <img
                  className="img-fluid"
                  src={ScreenShot2}
                  alt=""
                />
                <img
                  className="img-fluid"
                  src={ScreenShot3}
                  alt=""
                />
                <img
                  className="img-fluid"
                  src={ScreenShot4}
                  alt=""
                />
                <img
                  className="img-fluid"
                  src={ScreenShot5}
                  alt=""
                />
              </div> */}
              <Carousel />
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        {/* <script>
          {`
                      $(".screenshot-carousel-2").owlCarousel({
                        autoplay: true,
                        smartSpeed: 1000,
                        loop: true,
                        dots: true,
                        items: 1
                    });
                    `}
        </script> */}
      </Helmet>
    </div>
  );
};

export default Hero;
