export default function PricingPlans({
  pricingData,
  userData,
  handleSelectPricingTier,
}) {
  return (
    <>
      <div className="row gap-md-0 gap-3">
        {pricingData?.data?.map(pricingTier => (
          <div
            className="col-md-4 mb-3 "
            key={pricingTier?.id}
          >
            <div
              className="card overflow-y-scroll"
              style={{
                height: "400px",
                borderColor: "#8f12fd",
              }}
            >
              <div className="card-header d-flex justify-content-between sticky-top bg-white">
                <h3>{pricingTier?.name}</h3>
                <button
                  className="btn btn-success "
                  onClick={() => handleSelectPricingTier(pricingTier)}
                >
                  {userData?.data?.pricingPlan?.name === pricingTier?.name
                    ? "Selected"
                    : "Select"}
                </button>
              </div>
              <div className="card-body ">
                <p>Card Limit : {pricingTier?.cardLimit}</p>

                <p>Card ShortURL : {pricingTier?.shortLinkLimit}</p>

                <p>Card QR code Limit : {pricingTier?.qrLimit}</p>

                <p>Duration in months : {pricingTier?.duration}</p>

                <p>
                  {pricingTier?.canChooseTemplate
                    ? "Can Choose template"
                    : "Can not choose template"}
                </p>
                <p>
                  {pricingTier?.canAnalytics ? "Has analytics" : "Has not analytics"}
                </p>
                <p>
                  {pricingTier?.canChangeUsername
                    ? "Can change username"
                    : "Can not change username"}
                </p>
                <p>
                  {pricingTier?.canChooseQRLogo
                    ? "Can choose QR Logo"
                    : "Can not choose QR Logo"}
                </p>
                <p>
                  {pricingTier?.companyLimit > 0
                    ? `Company Limit : ${pricingTier?.companyLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.websiteLimit > 0
                    ? `Website Limit : ${pricingTier?.websiteLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.emailLimit > 0
                    ? `Email Limit : ${pricingTier?.emailLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.phoneLimit > 0
                    ? `Phone Limit : ${pricingTier?.phoneLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.positionLimit > 0
                    ? `Position Limit : ${pricingTier?.positionLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.facebookLimit > 0
                    ? `Facebook Limit : ${pricingTier?.facebookLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.twitterLimit > 0
                    ? `Twitter Limit : ${pricingTier?.twitterLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.linkedinLimit > 0
                    ? `Linkedin Limit : ${pricingTier?.linkedinLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.instagramLimit > 0
                    ? `Instagram Limit : ${pricingTier?.instagramLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.githubLimit > 0
                    ? `Github Limit : ${pricingTier?.githubLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.pinterestLimit > 0
                    ? `Pinterest Limit : ${pricingTier?.pinterestLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.youtubeLimit > 0
                    ? `Youtube Limit : ${pricingTier?.youtubeLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.spotifyLimit > 0
                    ? `Spotify Limit : ${pricingTier?.spotifyLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.telegramLimit > 0
                    ? `Telegram Limit : ${pricingTier?.telegramLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.viberLimit > 0
                    ? `Viber Limit : ${pricingTier?.viberLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.wechatLimit > 0
                    ? `Wechat Limit : ${pricingTier?.wechatLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.whatsappLimit > 0
                    ? `Whatsapp Limit : ${pricingTier?.whatsappLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.tiktokLimit > 0
                    ? `Tiktok Limit : ${pricingTier?.tiktokLimit}`
                    : ""}
                </p>
                <p>
                  {pricingTier?.udemyLimit > 0
                    ? `Udemy Limit : ${pricingTier?.udemyLimit}`
                    : ""}
                </p>

                <p>
                  {pricingTier?.adressLimit > 0
                    ? `Adress Limit : ${pricingTier?.adressLimit}`
                    : ""}
                </p>

                <p>
                  {pricingTier?.skypeLimit > 0
                    ? `Skype Limit : ${pricingTier?.skypeLimit}`
                    : ""}
                </p>

                <p>
                  {pricingTier?.firstnameLimit > 0
                    ? `Firstname Limit : ${pricingTier?.firstnameLimit}`
                    : ""}
                </p>

                <p>
                  {pricingTier?.lastnameLimit > 0
                    ? `Lastname Limit : ${pricingTier?.lastnameLimit}`
                    : ""}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
