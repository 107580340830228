import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import UserLink from "./UserLink";
import { useCardItemInputs } from "../../hooks/useCardItemInputs";

import AddContacts from "./AddContacts";
import { QR } from "./QR";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const TemplateThree = ({ id }) => {
  const { cardId } = useParams();
  const [item, setItem] = useState("");
  const globalStatment = useSelector(state => state.user);
  const {
    card,
    firstName,
    lastName,
    position,
    instagram,
    facebook,
    twitter,
    whatsapp,
    viber,
    email,
    linkedin,
    tiktok,
    skype,
    github,
    pinterest,
    spotify,
  } = useCardItemInputs({ cardId: item });

  useEffect(() => {
    if (cardId !== undefined) {
      setItem(cardId);
      return;
    } else {
      setItem(id);
    }
  }, [cardId, id]);

  useEffect(() => { 
    if (globalStatment) {
      console.log(globalStatment,'globalStatment'); 
    }
  }, [globalStatment]); 

  return (
    <div
      style={{
        background: "linear-gradient(to right, #0a0a0a 0%, #0a0a0a 100%)",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container text-center my-5">
        {/* Profile image section */}
        <section>
          <img
            src={`https://storage.googleapis.com/addmeimages/${card?.data?.cardImage}`}
            alt="Profile"
            className="rounded-circle mb-4"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        </section>

        {/* Name section */}
        <section
          className="mb-4"
          style={{ color: "#fff" }}
        >
          <h2 className="mb-4">
            {firstName || "Loading..."} {lastName || "Loading..."}
          </h2>
          <h3 className="mb-4">{position || "Loading..."}</h3>
        </section>

        {/* Icons section */}
        <section className="my-4">
          <QR />
          <AddContacts />
        </section>

        {/* Links section */}
        <section className="d-grid gap-3 col-10 col-md-6 mx-auto">
          {instagram.map((link, index) => (
            <UserLink
              key={index}
              background={
                "linear-gradient(45deg, #D62976, #E1306C, #F77737, #FCAF45, #FCAF45)"
              }
              text={"Instagram"}
              link={link}
              icon={"fab fa-instagram"}
              color={"#fff"}
            />
          ))}
          {email.map((address, index) => (
            <UserLink
              key={index}
              background={"linear-gradient(to right, #ffffff 0%, #ffffff 100%)"}
              text={"Email"}
              link={address}
              icon={"fas fa-envelope"}
              color={"#000000"}
            />
          ))}
          {facebook.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #3b5999 0%, #3b5999 100%)"}
              text={"Facebook"}
              link={address}
              icon={"fab fa-facebook"}
              color={"#fff"}
            />
          ))}
          {twitter.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #000000 0%, #000000 100%)"}
              text={"Twitter"}
              link={address}
              icon={"fab fa-twitter"}
              color={"#fff"}
            />
          ))}
          {whatsapp.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #25D366 0%, #25D366 100%)"}
              text={"Whatsapp"}
              link={address}
              icon={"fab fa-whatsapp"}
              color={"#fff"}
            />
          ))}
          {viber.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #7360F2 0%, #7360F2 100%)"}
              text={"Viber"}
              link={address}
              icon={"fab fa-viber"}
              color={"#fff"}
            />
          ))}
          {linkedin.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #0e76a8 0%, #0e76a8 100%)"}
              text={"Linkedin"}
              link={address}
              icon={"fab fa-linkedin"}
              color={"#fff"}
            />
          ))}
          {tiktok.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #010101 0%, #010101 100%)"}
              text={"Tiktok"}
              link={address}
              icon={"fab fa-tiktok"}
              color={"#fff"}
            />
          ))}
          {skype.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #0078D7 0%, #0078D7 100%)"}
              text={"Skype"}
              link={address}
              icon={"fab fa-skype"}
              color={"#fff"}
            />
          ))}
          {github.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #000000 0%, #000000 100%)"}
              text={"Github"}
              link={address}
              icon={"fab fa-github"}
              color={"#fff"}
            />
          ))}
          {pinterest.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #BD081C 0%, #BD081C 100%)"}
              text={"Pinterest"}
              link={address}
              icon={"fab fa-pinterest"}
              color={"#fff"}
            />
          ))}
          {spotify.map((address, index) => (
            <UserLink
              background={"linear-gradient(to right, #1DB954 0%, #1DB954 100%)"}
              text={"Spotify"}
              link={address}
              icon={"fab fa-spotify"}
              color={"#fff"}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default TemplateThree;
