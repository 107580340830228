import * as React from "react";
import { Modal as ModalWindow } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { setUsers } from "../../Redux/slices/UserSlice";

export default function Modal() {
  const { isAuthorized, users } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [toggleStep, setToggleStep] = React.useState({
    authLogin: true,
    authRegister: false,
  });
  const [registrationValue, setregistrationValue] = React.useState({
    name: "",
    surname: "",
    email: "",
    password: "",
  });
  const [loginValue, setLoginValue] = React.useState({
    email: "",
    password: "",
  });

  const { email: authEmail, password: authPassword } = loginValue;
  const { name, surname, email, password } = registrationValue;
  const { authLogin, authRegister } = toggleStep;
  console.log(users);

  const handleToggleStep = key => {
    setToggleStep(() => ({
      authLogin: key === "authLogin" ? true : false,
      authRegister: key === "authRegister" ? true : false,
    }));
  };

  const handleInputValues = value => {
    if (authLogin) {
      setLoginValue(prevState => ({ ...prevState, ...value }));
    }
    if (authRegister) {
      setregistrationValue(prevState => ({ ...prevState, ...value }));
    }
  };

  const authUser = () => {
    if (authLogin) {
      dispatch(
        setUsers({
          email: authEmail,
          isNew: false,
          avatar:
            "https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png",
        })
      );
    }
    if (authRegister) {
      dispatch(
        setUsers({
          name,
          surname,
          email,
          isNew: true,
          avatar:
            "https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png",
        })
      );
    }
  };

  return (
    <>
      <button onClick={() => setShowModal(true)}>Registration</button>

      <ModalWindow
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal"
      >
        <ModalWindow.Header closeButton>
          <ModalWindow.Title>Registration to the event</ModalWindow.Title>
        </ModalWindow.Header>
        <ModalWindow.Body>
          {isAuthorized ? (
            <div>
              <p>ღონისძიებაზე რეგისტრაცია</p>
              <button onClick={authUser}>რეგისტრაცია</button>
            </div>
          ) : (
            <div>
              <p> გაიარეთ ავტორიზაცია რათა დარეგისტრირდეთ ღონისძიებაზე</p>
              <div className="d-flex gap-3">
                <button
                  onClick={e => handleToggleStep(e.target.name)}
                  name="authLogin"
                  value={authLogin}
                  className={`btn btn-primary-gradient ${
                    authLogin ? "active" : ""
                  } `}
                >
                  შესვლა
                </button>
                <button
                  onClick={e => handleToggleStep(e.target.name)}
                  name="authRegister"
                  value={authRegister}
                  className={`btn btn-primary-gradient ${
                    authRegister ? "active" : ""
                  } `}
                >
                  რეგისტრაცია
                </button>
              </div>
              {authLogin && (
                <div className="d-flex flex-column gap-3 mt-2 form-control py-3">
                  <input
                    onChange={e => handleInputValues({ email: e.target.value })}
                    value={authEmail}
                    name="email"
                    type="text"
                    placeholder="ელ-ფოსტა"
                    className="rounded-2 border py-2 px-3"
                  />
                  <input
                    onChange={e => handleInputValues({ password: e.target.value })}
                    value={authPassword}
                    name="password"
                    type="password"
                    placeholder="პაროლი"
                    className="rounded-2 border py-2 px-3"
                  />
                  <button
                    className="btn btn-primary-gradient"
                    onClick={authUser}
                  >
                    შესვლა
                  </button>
                </div>
              )}
              {authRegister && (
                <div className="d-flex flex-column gap-3 mt-2 form-control py-3">
                  <input
                    onChange={e => handleInputValues({ name: e.target.value })}
                    value={name}
                    name="name"
                    type="text"
                    placeholder="სახელი"
                    className="rounded-2 border py-2 px-3"
                  />
                  <input
                    onChange={e => handleInputValues({ surname: e.target.value })}
                    value={surname}
                    name="surname"
                    type="text"
                    placeholder="გვარი"
                    className="rounded-2 border py-2 px-3"
                  />
                  <input
                    onChange={e => handleInputValues({ email: e.target.value })}
                    value={email}
                    name="email"
                    type="text"
                    placeholder="ელ-ფოსტა"
                    className="rounded-2 border py-2 px-3"
                  />
                  <input
                    onChange={e => handleInputValues({ password: e.target.value })}
                    value={password}
                    name="password"
                    type="password"
                    placeholder="პაროლი"
                    className="rounded-2 border py-2 px-3"
                  />
                  <button
                    className="btn btn-primary-gradient"
                    onClick={authUser}
                  >
                    რეგისტრაცია
                  </button>
                </div>
              )}
            </div>
          )}
        </ModalWindow.Body>
        <ModalWindow.Footer>
          <button
            onClick={() => setShowModal(false)}
            className="btn btn-danger"
          >
            Close
          </button>
        </ModalWindow.Footer>
      </ModalWindow>
    </>
  );
}
