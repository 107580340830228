import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";
import ModalComponent from "../../components/modal/Modal";
import Navbar from "../../components/navbar/navbar";
import { useTranslation } from "react-i18next";
import PremiumIcon from "../../assets/newIcons/4.svg";
import timeIcon from "../../assets/newIcons/time.svg";
import okeyIcon from "../../assets/newIcons/on.svg";
import xIcon from "../../assets/newIcons/x.svg";
import dropdownIcon from "../../assets/newIcons/dropdown.svg";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import useAsyncCall from "../../hooks/useAsyncCall";
import Header from "../../components/header/header";

const PremiumFeatures = () => {
  const userSubscription = useSelector(state => state.user.user.pricingPlan);
  const user = useSelector(state => state.user.user);
  console.log(user);
  const [showLimmites, setShowLimmites] = useState(true);
  const [showPermissions, setShowPermissions] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sendMessage = useAsyncCall();
  const { t } = useTranslation();

  const handleSendMessage = message => {
    const formData = {
      name: user.firstname + " " + user.lastname,
      mail: user.email,
      subject: "Upgrade Premium",
      messageText: message,
    };

    sendMessage.request("post", "/contact/sendmessage", formData).then(e => {
      alert(t("messageSendSuccess"));
      setShowModal(false);
    });
  };

  function formatKey(key) {
    const words = key.match(/[A-Z]?[a-z]+|[A-Z]+(?=[A-Z][a-z])/g);
    const formattedKey = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedKey;
  }
  const limitArray = Object.entries(userSubscription)
    .filter(([key]) => key.includes("Limit"))
    .map(([key, value]) => ({ [formatKey(key)]: value }));
  const permissions = Object.entries(userSubscription)
    .filter(([key]) => key.includes("can"))
    .map(([key, value]) => ({ [formatKey(key)]: value }));

  const targetTime = new Date(
    userSubscription.userPricingPlans[0].expireDate
  ).getTime();

  const handleShowLimmites = () => {
    setShowLimmites(prev => !prev);
  };

  const handleShowPermissions = () => {
    setShowPermissions(prev => !prev);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    // Optionally, collapse the sidebar when resizing
    // setExpanded(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <Header />
      ) : (
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
      )}

      <div
        className="container-xxl "
        style={{
          marginLeft: isMobile ? "0" : "6rem",
          marginTop: isMobile ? "0" : "6rem",
          paddingRight: isMobile ? "0" : "10rem",
          transition: "margin-left 0.5s",
        }}
      >
        <Sidebar />
        <div className="">
          <div
            className="align-items-center d-flex  gap-3 d-md-inline-flex"
            style={{
              padding: "0.5rem",
              borderRadius: "30px",
              backgroundColor: "#7836B5",
              marginBottom: "1rem",
              color: "white",
              marginRight: "10px",
            }}
          >
            <img
              src={PremiumIcon}
              alt=""
              width="50"
              height="50"
            />
            <h4
              className="fs-6 m-0"
              style={{
                width: "fit-content",
              }}
            >
              {t("PremiumFeatures")}
            </h4>
          </div>
          <div className="d-flex flex-md-row flex-column justify-content-start justify-content-md-between ">
            <div
              className="d-flex gap-3 align-items-center "
              style={{
                backgroundColor: "#503392",
                color: "white",
                borderRadius: "30px",
                height: "50px",
                padding: "10px",
                marginRight: "10px",
              }}
            >
              {userSubscription.name !== "Free" ? (
                <h3 className="small d-md-inline m-0">
                  {" "}
                  {t("premiumName")}: {t("premium")}
                </h3>
              ) : (
                <h3 className="small d-md-inline m-0">
                  {t("premiumName")}: {t("free")}
                </h3>
              )}
            </div>
            <div className="d-flex gap-2 my-3 align-items-center">
              <img
                src={timeIcon}
                alt=""
                width="40"
                height="40"
              />
              <FlipClockCountdown
                to={targetTime} // Countdown to 24 hours from now
                className="flip-clock"
                labels={[t("Days"), t("Hours"), t("Minutes"), t("Seconds")]}
                labelStyle={{ color: "#000", fontSize: "10px" }}
                duration={0.5} // Speed of the animation
                separator={true}
                digitBlockStyle={{
                  width: "15px", // Adjust width of each block
                  height: "20px", // Adjust height of each block
                  fontSize: "10px", // Adjust font size of digits
                }}
              />
            </div>
          </div>
          <div className="d-flex d-md-none justify-content-center">
            <button
              className="small"
              style={{
                backgroundColor: "#01BF63",
                color: "white",
                padding: "0.5rem 1rem",
                borderRadius: "30px",
                border: "none",
              }}
              onClick={() => setShowModal(true)}
            >
              {t("premiumRenew")}
            </button>
          </div>
          <div className="my-3 d-flex flex-column gap-2">
            <div className="d-flex gap-2 align-items-center">
              <img
                src={dropdownIcon}
                alt=""
                width="40"
                height="40"
                onClick={() => handleShowPermissions()}
                style={{ cursor: "pointer" }}
              />

              <h4
                className="fs-6   text-center "
                style={{
                  color: "#ffffff",
                  backgroundColor: "#A4599E",
                  padding: "0.5rem",
                  borderRadius: "30px",
                  margin: "0",
                }}
              >
                {t("activeNotActive")}
              </h4>
            </div>
            <div className="mt-2">
              <ul
                className={`${
                  showPermissions ? "d-flex" : "d-none"
                } list-unstyled  flex-wrap gap-2 justify-content-center mb-0`}
              >
                {permissions.map((permission, index) => (
                  <li
                    key={index}
                    className="mb-0 d-flex  gap-3 align-items-center"
                    style={{
                      backgroundColor: "#9D59F5",
                      // color: "white",
                      fontWeight: "bold",
                      padding: "0.25rem 0.5rem",
                      borderRadius: "30px",
                      width: "180px",
                    }}
                  >
                    {Object.entries(permission).map(([key, value]) => (
                      <p
                        key={key}
                        className="mb-0 w-100  d-flex gap-3 justify-content-between align-items-center text-nowrap"
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        {key}
                        {value ? (
                          <>
                            <img
                              src={okeyIcon}
                              alt=""
                              width="20"
                              height="20"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={xIcon}
                              alt=""
                              width="20"
                              height="20"
                            />
                          </>
                        )}
                      </p>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-2">
            <div className="d-flex align-items-center gap-2">
              <img
                src={dropdownIcon}
                alt=""
                width="40"
                height="40"
                onClick={() => handleShowLimmites()}
                style={{ cursor: "pointer" }}
              />
              <h4
                className="fs-6    text-center"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#A4599E",
                  padding: "0.5rem",
                  borderRadius: "30px",
                  margin: "0",
                }}
              >
                {t("limits")}
              </h4>
            </div>
            <div className={`${showLimmites ? "d-block" : "d-none"} mt-3`}>
              <ul className="list-unstyled row gap-1 justify-content-center">
                {limitArray.map((limit, index) => (
                  <li
                    key={index}
                    className=" col-md-3 col-6 justify-content-center"
                    style={{
                      backgroundColor: "#9D59F4",
                      // color: "white",
                      fontWeight: "bold",
                      padding: "0.5rem",
                      borderRadius: "30px",
                      width: "150px",
                    }}
                  >
                    {Object.entries(limit).map(([key, value]) => (
                      <div
                        key={key}
                        className="mb-0 d-flex justify-content-between align-items-center"
                      >
                        <span className="small">{key} </span>
                        <span className="border rounded-circle p-2">{value}</span>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3">
            <button
              className=" "
              style={{
                backgroundColor: "#01BF63",
                color: "white",
                padding: "0.5rem 1rem",
                borderRadius: "30px",
                border: "none",
              }}
              onClick={() => setShowModal(true)}
            >
              {t("premiumRenew")}
            </button>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showModal}
        title={t("premiumRenew")}
        desc={t("premiumDesc")}
        placeholder={t("message")}
        showTextField={true}
        func={handleSendMessage}
        handleModal={() => setShowModal(false)}
      />
    </>
  );
};

export default PremiumFeatures;
