import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const shortURLApi = createApi({
  reducerPath: "shortURLApi",
  baseQuery: baseQuery,
  tagTypes: ["ShortURL"],
  endpoints: builder => ({
    createShortURL: builder.mutation({
      query: data => ({
        url: "/api/ShortUrl/GenerateShortUrl",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["ShortURL"],
    }),
    getShortURLs: builder.query({
      query: () => ({
        url: "/api/ShortUrl/GetShortUrls",
        method: "GET",
      }),
      providesTags: ["ShortURL"],
    }),
    getShortURL: builder.query({
      query: value => ({
        url: `/api/ShortUrl/GetShortUrl?value=${value}`,
        method: "GET",
      }),
    }),
    deleteShortURL: builder.mutation({
      query: id => ({
        url: `/api/ShortUrl/DeleteShortUrl?id=${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["ShortURL"],
    }),
  }),
});

export const {
  useCreateShortURLMutation,
  useGetShortURLsQuery,
  useGetShortURLQuery,
  useDeleteShortURLMutation,
} = shortURLApi;
