import { useParams, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import {
  useGetPricingPlansQuery,
  useUpdateUserPricingPlanMutation,
} from "../../Redux/services/PricingPlanApi";
import { useGetUserQuery } from "../../Redux/services/UserApi";
import { useGetUserQRCodesQuery } from "../../Redux/services/CardApi";
import { selectUserId, unSelectUserId } from "../../Redux/slices/UserSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector, useDispatch } from "react-redux";
import AboutUser from "./components/AboutUser";
import CardQRCodes from "./components/CardQRCodes";
import UserReferrals from "./components/UserReferrals";
import UserCards from "./components/UserCards";
import UserShortURLS from "./components/UserShortURLS";
import UserCustomQRs from "./components/UserCustomQRs";
import PricingPlans from "./components/PricingPlans";

export default function UserProfile() {
  const { users } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [referralUsers, setReferralUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTier, setSelectedTier] = useState({});
  const [duration, setDuration] = useState(0);
  const [selectedQrId, setSelectedQrId] = useState("");
  const [toggleCard, setToggleCard] = useState(false);
  const { userId } = useParams();

  const {
    data: userData,
    isLoading: userDataLoading,
    refetch,
  } = useGetUserQuery(userId);
  const {
    data: qrData,

    isFetching: qrDataFetching,
  } = useGetUserQRCodesQuery(selectedQrId ? selectedQrId : skipToken);
  const { data: pricingData } = useGetPricingPlansQuery();
  const [updateUserPricingPlanMutate] = useUpdateUserPricingPlanMutation();

  const skeletonCount = userData?.data?.cards.length || 3;

  const hasNFCSticker = [
    "sinjikashvili@gmail.com",
    "a.amiranashvili@alte.edu.ge",
    "youtubevardosanidze@yahoo.com",
    "anri.shakiashvili@gmail.com",
    "eleniko.rukhadze@gmail.com",
    "anaendzeladzee@gmail.com",
    "o.napetvaridze@gmail.com",
    "gigikatamadze1@gmail.com",
    "od-mln@aiesec.net",
    "sopo@kurdadze.ge",
    "koteromelashvili@gmail.com",
  ];

  const checkEmailAddresses = hasNFCSticker.includes(userData?.data?.email);

  const handleSelectPricingTier = plan => {
    setSelectedTier(plan);
    setShowModal(true);
  };

  const handleUpdateUserPricingPlan = async id => {
    const response = await updateUserPricingPlanMutate({ userId, id, duration });
    try {
      if (response?.data?.success) {
        setShowModal(false);
        setDuration(0);
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetUserQRs = id => {
    setSelectedQrId(id);
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleCardView = () => {
    setToggleCard(prev => !prev);
  };

  useEffect(() => {
    dispatch(selectUserId(userId));
    const filteredUsers = users.filter(
      user => userData?.data?.email === user.referralEmail
    );

    setReferralUsers(filteredUsers);
  }, [dispatch, userData?.data?.email, userId, users]);

  return (
    <>
      <div className="container">
        <button className="btn btn-primary my-3 ">
          <Link
            onClick={() => dispatch(unSelectUserId())}
            to={"/admin/users"}
            className="text-white text-decoration-none"
          >
            Back To Users Page
          </Link>
        </button>
        <h1>User Profile</h1>
        <div className="row">
          <AboutUser
            userData={userData}
            userDataLoading={userDataLoading}
            checkEmailAddresses={checkEmailAddresses}
            dispatch={dispatch}
            selectUserId={selectUserId}
          />
          <div className="col-md-4 ">
            <h2>QR Codes</h2>
            <CardQRCodes
              qrDataFetching={qrDataFetching}
              qrData={qrData}
              handleDownload={handleDownload}
            />
          </div>
        </div>

        <UserReferrals
          referralUsers={referralUsers}
          toggleCard={toggleCard}
          toggleCardView={toggleCardView}
        />
        <div className="row mt-3 ">
          <h2>Cards</h2>

          <UserCards
            userDataLoading={userDataLoading}
            userData={userData}
            handleGetUserQRs={handleGetUserQRs}
            skeletonCount={skeletonCount}
          />
        </div>

        <div className="row">
          <h2>Short Urls</h2>
          <UserShortURLS userData={userData} />
        </div>
        <div className="row">
          <h2>Custom QRs</h2>
          <UserCustomQRs
            userData={userData}
            handleDownload={handleDownload}
          />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center ">
          <div className="d-flex flex-column  justify-content-between  align-items-center gap-3 ">
            <div className="d-flex flex-column  align-items-center gap-3">
              <h4>Select Plan for user </h4>
              <PricingPlans
                pricingData={pricingData}
                userData={userData}
                handleSelectPricingTier={handleSelectPricingTier}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center ">
            <h2>Update Pricing Plan</h2>
            <h3>{selectedTier?.name}</h3>
            <p>Duration in months </p>
            <input
              type="number"
              value={duration}
              name="duration"
              className="input-group  "
              onChange={e => setDuration(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => handleUpdateUserPricingPlan(selectedTier?.id)}
            >
              {" "}
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
