import { Link } from "react-router-dom";
import { Skeleton } from "antd";

export default function CardQRCodes({ qrDataFetching, qrData, handleDownload }) {
  return (
    <>
      {qrDataFetching ? (
        <>
          <div className="d-flex flex-column align-items-center mb-3 gap-3">
            <Skeleton.Input
              style={{ width: 150, height: 25 }}
              active
            />
            <Skeleton.Avatar
              shape="square"
              size={150}
              active
            />
            <Skeleton.Button active />
          </div>
          <div className="d-flex flex-column align-items-center mb-3 gap-3">
            <Skeleton.Input
              style={{ width: 150, height: 25 }}
              active
            />
            <Skeleton.Avatar
              shape="square"
              size={150}
              active
            />
            <Skeleton.Button active />
          </div>
        </>
      ) : (
        qrData?.data?.type1QR && (
          <>
            <div className="d-flex flex-column align-items-center mb-3 ">
              <span>Link</span>
              <Link to={qrData?.data?.link}>{qrData?.data?.link}</Link>
              <span>Link QR</span>
              <img
                src={qrData?.data?.type1QR}
                alt="profile"
                className="img-thumbnail w-50 "
              />
              <button
                onClick={() => handleDownload(qrData?.data?.type1QR, "QR_Link.png")}
                className="mt-2 btn btn-primary-gradient"
              >
                Save Link QR
              </button>
            </div>
            <div className="d-flex flex-column align-items-center">
              <span>VCard QR</span>
              <img
                src={qrData?.data?.type2QR}
                alt="profile"
                className="img-thumbnail w-50 "
              />

              <button
                onClick={() => handleDownload(qrData?.data?.type2QR, "QR_VCard.png")}
                className="mt-2 btn btn-primary-gradient"
              >
                Save VCard QR
              </button>
            </div>
          </>
        )
      )}
    </>
  );
}
