import "../../assets/share.css";
import { useEffect, useState } from "react";
import { IconPlus, IconShare } from "@tabler/icons-react";

import useAsyncCall from "../../hooks/useAsyncCall";
import Modal from "react-bootstrap/Modal"; // Assuming you're using Bootstrap via react-bootstrap
import { useTranslation } from "react-i18next";
import { WhatsappShareButton, TelegramShareButton } from "react-share";
import QrCode from "../../assets/newIcons/share.png";
import DownloadButton from "../../assets/newIcons/50.svg";

const Share = ({ isUserPage, username, telegram, whatsapp }) => {
  // const username = window.location.href.split(/[/ ]+/).pop();
  const [color, setColor] = useState("");
  const [qr, setQR] = useState(false);
  const [vCard, setVCard] = useState("");
  const [link, setLink] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const getqr = useAsyncCall();
  const [notFound, setNotfound] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const url = window.location.href;
  const message =
    "Check this out! I have an ADDME card. Scan the QR code or click the link to save my contact details.";

  const handleSmsShare = () => {
    const smsUrl = `sms:?&body=${encodeURIComponent(message)} ${encodeURIComponent(
      url
    )}`;
    window.location.href = smsUrl;
    console.log(smsUrl);
  };

  const handlePickContact = async () => {
    if ("contacts" in navigator && navigator.contacts.select) {
      try {
        const contacts = await navigator.contacts.select(["name", "tel", "email"], {
          multiple: true,
        });
        console.log("Selected contacts:", contacts);
        // Process the selected contacts as needed
      } catch (error) {
        console.error("Error selecting contacts:", error);
      }
    } else {
      alert("Contact Picker API is not supported by your browser.");
    }
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/${whatsapp}?text=${encodeURIComponent(
      message
    )} ${encodeURIComponent(url)}`;
    window.location.href = whatsappUrl;
  };

  // Dynamic Telegram sharing with specific username
  const handleTelegramShare = () => {
    const telegramUrl = `https://t.me/${telegram}`;
    window.open(
      `${telegramUrl}?text=${encodeURIComponent(message)} ${encodeURIComponent(
        url
      )}`,
      "_blank"
    );
  };
  const handleShare = async () => {
    const message =
      "Check this out! I have an ADDME card. Scan the QR code or click the link to save my contact details.";
    if (navigator.share) {
      const url = window.location;
      // Use the Web Share API
      try {
        await navigator.share({
          title: "ADDME",
          url: url,
          text: message,
        });
        console.log("Shared successfully");
      } catch (err) {
        console.error("Error sharing:", err);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      // Copy URL to clipboard
      navigator.clipboard
        .writeText(url)
        .then(() => {
          alert("URL copied to clipboard!");
        })
        .catch(err => {
          console.error("Error copying URL:", err);
        });
    }
  };

  // const handleShare = async () => {
  //   const message =
  //     "Check this out! I have an ADDME card. Scan the QR code or click the link to save my contact details.";
  //   const url = window.location.href;

  //   if (navigator.canShare && navigator.canShare({ files: [] })) {
  //     const imageFile = new File(
  //       [await (await fetch("../../assets/addmewavelogo.png")).blob()],
  //       "icon.png",
  //       { type: "image/png" }
  //     );

  //     try {
  //       await navigator.share({
  //         title: "ADDME",
  //         text: message,
  //         url: url,
  //         files: [imageFile], // Share the image file
  //       });
  //       console.log("Shared successfully");
  //     } catch (err) {
  //       console.error("Error sharing:", err);
  //     }
  //   } else {
  //     // Fallback: share URL or copy to clipboard if files are not supported
  //     navigator.clipboard
  //       .writeText(url)
  //       .then(() => {
  //         alert("URL copied to clipboard!");
  //       })
  //       .catch(err => {
  //         console.error("Error copying URL:", err);
  //       });
  //   }
  // };

  useEffect(() => {
    let requestUrl = `/Card/GetCardQR?typeId=${isUserPage ? 2 : 1}&${
      username ? `userName=${username}` : ""
    }`;
    getqr
      .request("get", requestUrl)
      .then(e => {
        if (e.success) {
          setQR(e.data.qr);
          setLink(e.data.link);
          setVCard(e.data.vCard);
        } else if (e.errorCode === 3) {
          setNotfound(true);
        } else if (e.errorCode === 8) {
          setIsAnonymous(true);
        }
      })
      .finally(setLoading(false));
  }, [isUserPage]);
  useEffect(() => {
    if (username.toLocaleLowerCase() === "agilementor") {
      setColor("#FDD835");
    } else if (username.toLocaleLowerCase() === "tradershub") {
      setColor("#9D1C1F");
    } else if (username.toLocaleLowerCase() === "bc463") {
      setColor("#ec7345");
    } else {
      setColor("#fff");
    }
  }, [username]);

  const handleDownload = () => {
    var blob = new Blob([vCard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);
    const newLink = document.createElement("a");
    newLink.download = "vCard.vcf";
    newLink.href = url;

    // Append link to the body temporarily to trigger the download
    document.body.appendChild(newLink);
    newLink.click();
    document.body.removeChild(newLink); // Clean up
    return vCard;
  };

  if (!loading && notFound) {
    return "";
  }

  if (!loading && isAnonymous) {
    return "";
  }

  return (
    <>
      <div
        className="text-center d-flex justify-content-center align-items-center position-fixed sticky-bottom w-100 "
        style={{
          backgroundColor: color,
          gap: "5rem",
          left: "0px",
          height: "4rem",
          boxShadow:
            username === "agilementor"
              ? "0px 0px 10px 0px #6966EE"
              : username === "tradershub"
              ? "0px 0px 10px 0px #6966EE"
              : username === "bc463"
              ? "0px 0px 10px 0px #6966EE"
              : "none",
        }}
      >
        {username === "agilementor" ? (
          <div
            className="border-top d-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>
        ) : (
          <div
            className=" d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>
        )}
        {/* <button
          className="share-item py-2 px-3"
          onClick={() => setShowModal(true)}
        >
          <img
            src={QrCode}
            alt=""
            className="img-fluid "
            width={54}
            height={54}
          />
        </button> */}
        <div className="dropdown align-items-center">
          {/* <button
                className="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                open
              </button> */}
          <button
            className="rounded-circle d-flex justify-content-center align-items-center p-2"
            style={{ backgroundColor: "#9445EF", color: "#fff", border: "none" }}
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            // onClick={() => setShowModal(true)}
          >
            <IconShare size={20} />
          </button>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <button
                className="dropdown-item btn btn-primary"
                onClick={() => setShowModal(true)}
                style={{ color: "#9445EF" }}
              >
                {t("shareviaQR")}
              </button>
            </li>

            <li>
              <button
                className="dropdown-item btn btn-primary"
                onClick={handleShare}
                style={{ color: "#9445EF" }}
              >
                {t("share")}
              </button>
            </li>
          </ul>
        </div>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <img
                src={qr}
                width={250}
                height={250}
                alt="QR Code"
              />
              <a
                className="d-block"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </a>
            </div>
          </Modal.Body>
        </Modal>
        {isUserPage && (
          <div
            className="d-flex justify-content-center align-items-center gap-3 rounded-5 py-2 px-2"
            style={{ backgroundColor: "#9445EF", color: "#fff" }}
          >
            <span
              className="share-item small text-white text-wrap"
              onClick={() => handleDownload()}

              // id="download"
            >
              {/* <img
              src={DownloadButton}
              alt=""
              className="img-fluid "
              width={50}
              height={50}
            /> */}

              {t("addContact")}
            </span>
            <div
              className="rounded-circle d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#fff", color: "#9445EF" }}
            >
              <IconPlus size={20} />
            </div>
          </div>
        )}
        {/* <div
          className="border-top d-md-none"
          style={{
            width: "100vw",
            position: "absolute",
            bottom: "0",
          }}
        ></div> */}
      </div>
    </>
  );
};

export default Share;
