import Sidebar from "../../components/sidebar/Sidebar";
import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import ImageCrop from "../../components/image/imageCrop";
import ImageCropProvider from "../../providers/imageCropProvider";
import Button from "../../components/ui/button";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import Incognito from "../../assets/img/incognito.png";
import QrCodeTypeTwo from "../../assets/qr_download.png";
import QrCodeTypeOne from "../../assets/qr_web.png";
import Modal from "react-bootstrap/Modal";
import "../../assets/share.css";
import Navbar from "../../components/navbar/navbar";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { FaCopy } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../components/modal/Modal";
import { Helmet } from "react-helmet";
import "../../App.css";
import {
  updateCardItemInput,
  toggleAnonymous,
  setSelectedCard,
} from "../../Redux/slices/UserSlice";
import { useGetUserProfileDataQuery } from "../../Redux/services/UserApi";
import {
  useGetCardQRsQuery,
  useCreateCardMutation,
  useDeleteCardInputItemMutation,
  useDeleteCardMutation,
  useAddCardInputItemMutation,
  useGetCardsQuery,
  useUpdateCardMutation,
  useChangeUsernameMutation,
} from "../../Redux/services/CardApi";

import { skipToken } from "@reduxjs/toolkit/query";

import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import username from "../../assets/newIcons/18.svg";
import Header from "../../components/header/header";

export default function NewProfile() {
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [color, setColor] = useState(false);
  const { t } = useTranslation();
  const [showModalFirst, setShowModalFirst] = useState(false);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [cardInputValue, setCardInputValue] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [activeCardId, setActiveCardId] = useState("");
  const [activeCard, setActiveCard] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [selectedCardItemId, setSelectedCardItemId] = useState("");
  const [changeUsername] = useChangeUsernameMutation();
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [newUsername, setNewUsername] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    // Optionally, collapse the sidebar when resizing
    // setExpanded(false);
  };

  const handleChangeUsername = e => {
    setNewUsername(e.target.value);
  };
  const handleSendNewUsername = async () => {
    const response = await changeUsername({
      cardId: selectedCard.cardId,
      name: newUsername,
    });
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("usernameChangeSuccess"),
            type: "success",
          })
        );

        setNewUsername("");
        setShowUsernameModal(false);
      }
      if (response.data.errorCode === 9) {
        dispatch(
          setToastNotification({
            message: t("usernameError"),
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const dispatch = useDispatch();
  const { data: userData } = useGetUserProfileDataQuery();
  const { data: userCard, isSuccess: successFetch } = useGetCardsQuery();
  const { data: cardQRs, isFetching: fetchingQRs } = useGetCardQRsQuery(
    activeCardId ? activeCardId : skipToken
  );

  const [createNewCard] = useCreateCardMutation();
  const [updateCard, { isLoading: updateCardLoader }] = useUpdateCardMutation();
  const [deleteCard] = useDeleteCardMutation();
  const [deleteCardInputItem] = useDeleteCardInputItemMutation();
  const [addCardInputItem] = useAddCardInputItemMutation();

  const {
    token: accessToken,
    user,
    cards,
    selectedCard,
  } = useSelector(state => state.user);

  const decodedToken = accessToken ? jwtDecode(accessToken) : null;

  const inputChangeHandler = async event => {
    event.preventDefault();
    const responseBody = {};
    const formData = new FormData(event.currentTarget);
    formData.forEach((value, property) => (responseBody[property] = value));

    const response = await updateCard(responseBody);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("updateCardSuccess"), type: "success" })
        );
      } else {
        dispatch(setToastNotification({ message: t("error"), type: "error" }));
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
      console.log(error);
    }
  };

  const handleInputChange = (cardIndex, inputIndex, value, id) => {
    dispatch(updateCardItemInput({ cardIndex, inputIndex, value }));
  };

  const handleIsAnonymousChange = (cardIndex, e) => {
    const newValue = e.target.checked;

    dispatch(toggleAnonymous({ cardIndex, newValue }));
  };

  function onCardImageChange(image, e) {
    const reader = new FileReader();

    reader.readAsDataURL(image);
    reader.onload = () => {
      document.getElementById(`hidden_base64_${e}`).value =
        reader.result.split(",")[1];
      document.getElementById(`hidden_name_${e}`).value = image.name;
      document.getElementById(`hidden_size_${e}`).value = image.size;
      document.getElementById(`hidden_type_${e}`).value = image.type;
    };
  }

  const handleCreateCard = async () => {
    const response = await createNewCard(cardInputValue);

    console.log(response);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardCreated"), type: "success" })
        );

        setShowCardModal(false);
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }

    setCardInputValue("");
  };

  const handleDeleteCard = async id => {
    const response = await deleteCard(id);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardDeleted"), type: "success" })
        );

        setSelectedCardIndex(0);
        setActiveCardId(cards[0]?.cardId);
        setActiveCard(cards[0]);
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomCardValue = e => {
    setCardInputValue(e.target.value);
  };

  const handleShowCard = (index, id) => {
    setSelectedCardIndex(index);
    setActiveCardId(id);
  };

  const handleAddItemToCard = async (id, item, index) => {
    const response = await addCardInputItem({ id, item });
    console.log(response.data);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("cardInputItemAdd"), type: "success" })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const handleDeleteItem = async () => {
    const response = await deleteCardInputItem(selectedCardItemId);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("cardInputItemDelete"),
            type: "success",
          })
        );
        setShowDeleteItemModal(false);
      }
    } catch (error) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const handleOpenModal = id => {
    setShowDeleteItemModal(true);
    setSelectedCardItemId(id);
  };

  const visibleCards = cards?.slice(0, user?.pricingPlan.cardLimit);

  const handleShowProfileInfo = () => {
    setShowProfileInfo(prev => !prev);
  };
  useEffect(() => {
    if (successFetch) {
      const getActiveCard = visibleCards?.find(card => card.cardId === activeCardId);
      if (selectedCardIndex === 0) {
        setActiveCardId(cards[0]?.cardId);
        dispatch(setSelectedCard(cards[0]));
      }
      if (getActiveCard) {
        setActiveCard(getActiveCard);
        dispatch(setSelectedCard(getActiveCard));
      } else {
        setActiveCard(cards[0]);
        dispatch(setSelectedCard(cards[0]));
      }
    }
  }, [
    activeCardId,
    cards,
    dispatch,
    selectedCardIndex,
    successFetch,
    userCard?.data,
    visibleCards,
  ]);

  // console.log(selectedCard);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <Header />
      ) : (
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
      )}
      <Helmet>
        <title>{user?.username}</title>
        <meta
          property="og:title"
          content="Dynamic Page Title"
        />
        <meta
          property="og:description"
          content="Dynamic description for this particular page."
        />
        <meta
          property="og:image"
          content="../../assets/addmewavelogo.png"
        />
        <meta
          property="og:url"
          content={window.location}
        />
      </Helmet>{" "}
      <div
        className="d-flex flex-column position-relative"
        style={{
          marginTop: isMobile ? "0rem" : "5rem",
        }}
      >
        <Sidebar
          handleShowProfileInfo={handleShowProfileInfo}
          buttonX={"85%"}
          buttonY={"94%"}
        />
        <div className="d-flex ">
          <div className="container-xl">
            {decodedToken?.Role !== "User" ? (
              <div className="row justify-content-end  pt-md-5 pb-md-3  ">
                {(decodedToken?.Role === "Admin") |
                (decodedToken?.Role === "SuperAdmin") ? (
                  <div className="col-md-6 col-12 align-items-center d-flex justify-content-center mb-lg-0 mb-3 ">
                    <Link
                      to="/admin"
                      className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                    >
                      Admin
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div
              className="row"
              style={{ justifyContent: "center" }}
            >
              <div className="col-xl-8">
                <div
                  className="align-items-center d-flex  gap-3 d-md-inline-flex mt-1 mb-2"
                  style={{
                    padding: "0.5rem",
                    borderRadius: "30px",
                    backgroundColor: "#7836B5",
                    color: "white",
                  }}
                >
                  <h4
                    className="fs-3 m-0"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    {t("bussinessvisitcard")}
                  </h4>
                </div>
                <div className="row ">
                  {user?.pricingPlan?.cardLimit >= 1 && (
                    <div className="mb-0">
                      {visibleCards?.map((item, index) => (
                        <button
                          onClick={() => handleShowCard(index, item.cardId)}
                          className={`btn btn-primary-gradient mx-2  mb-3  ${
                            visibleCards[selectedCardIndex]?.cardId === item.cardId
                              ? "active"
                              : ""
                          }`}
                        >
                          {item?.cardName}
                        </button>
                      ))}

                      <button
                        className="btn btn-primary-gradient mb-3 align-self-center"
                        onClick={() => setShowCardModal(true)}
                        disabled={
                          visibleCards?.length === user?.pricingPlan?.cardLimit
                        }
                      >
                        {t("createCard")}
                      </button>
                      <Modal
                        onHide={() => setShowCardModal(false)}
                        show={showCardModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-center align-items-center gap-3 ">
                            <div className="d-flex flex-column gap-3 ">
                              <h6 className="small">{t("createCard")}</h6>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={cardInputValue}
                                onChange={handleCustomCardValue}
                                className="form-control insidetext"
                                placeholder={t("createCardPlaceholder")}
                              />
                            </div>
                            <button
                              className="btn btn-primary-gradient align-self-end "
                              onClick={handleCreateCard}
                            >
                              {t("create")}
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                  {user?.pricingPlan?.canChangeUsername ? (
                    <div
                      className="d-flex align-items-center gap-4 "
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setShowUsernameModal(true)}
                    >
                      <div
                        className="px-3 py-3"
                        style={{
                          width: "2rem",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={username}
                          alt=""
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      </div>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          textDecoration: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        {t("changeURL")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {cards?.map((item, index) => {
                    return (
                      <>
                        {selectedCardIndex === index && (
                          <div
                            className="col-xl-12"
                            key={index}
                          >
                            <div className="card mb-4">
                              <div className="card-header bg-white">
                                {t("cardDetails")}
                              </div>
                              <div className="card-body p-0 p-md-1">
                                <form
                                  onSubmit={inputChangeHandler}
                                  noValidate
                                  autoComplete="off"
                                  className="container"
                                >
                                  <input
                                    hidden
                                    name="CardId"
                                    value={item.cardId}
                                  />
                                  <div className="row gx-3 mb-3 text-center">
                                    <ImageCropProvider>
                                      <ImageCrop
                                        id={`image_${item.cardId}_${index}`}
                                        image={`https://storage.googleapis.com/addmeimages/${item.cardImage}`}
                                        width={185}
                                        height={185}
                                        showChange={true}
                                        classes={"rounded-circle"}
                                        onChange={onCardImageChange}
                                      />
                                    </ImageCropProvider>
                                    <input
                                      name="CardImageBase64"
                                      id={`hidden_base64_image_${item.cardId}_${index}`}
                                      hidden
                                    />
                                    <input
                                      name="CardImageName"
                                      id={`hidden_name_image_${item.cardId}_${index}`}
                                      hidden
                                    />
                                    <input
                                      name="CardImageSize"
                                      id={`hidden_size_image_${item.cardId}_${index}`}
                                      hidden
                                    />
                                    <input
                                      name="CardImageType"
                                      id={`hidden_type_image_${item.cardId}_${index}`}
                                      hidden
                                    />
                                    <span>{t("imageSize")}</span>
                                  </div>
                                  <div className="row gx-3 mb-3">
                                    {item?.cardItemInputs?.map(
                                      (subItem, subIndex) => {
                                        return (
                                          <div className="col-md-6 p-0 p-md-1">
                                            {subItem.icon ? (
                                              <div className="input-group mb-3 d-flex flex-column ">
                                                <div className="d-flex">
                                                  <div className="input-group-prepend">
                                                    <span
                                                      className="input-group-text bg-white"
                                                      id="basic-addon1"
                                                    >
                                                      <img
                                                        width={30}
                                                        height={30}
                                                        src={`${window.location.origin}/${subItem.icon}`}
                                                        alt="icon"
                                                      />
                                                    </span>
                                                  </div>
                                                  {subItem.isPhoneFormat ? (
                                                    <PhoneInput
                                                      placeholder="Enter phone number"
                                                      className="form-control d-flex"
                                                      name={subItem.id}
                                                      defaultCountry="GE"
                                                      withCountryCallingCode={true}
                                                      country="GE"
                                                      value={subItem.value}
                                                      onChange={e =>
                                                        handleInputChange(
                                                          index,
                                                          subIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      name={subItem.id}
                                                      placeholder={
                                                        subItem.placeholder
                                                      }
                                                      value={subItem.value}
                                                      onChange={e =>
                                                        handleInputChange(
                                                          index,
                                                          subIndex,
                                                          e.target.value,
                                                          subItem.id
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                                {subItem?.limit > 1 && (
                                                  <>
                                                    <button
                                                      className="btn btn-primary-gradient rounded-3 mt-2 "
                                                      type="button"
                                                      disabled={
                                                        subItem.limit ===
                                                        subItem.count
                                                      }
                                                      onClick={() => {
                                                        handleAddItemToCard(
                                                          item.cardId,
                                                          subItem,
                                                          subIndex
                                                        );
                                                      }}
                                                    >
                                                      {t("add")}
                                                    </button>
                                                    {subItem.count > 1 && (
                                                      <button
                                                        style={{
                                                          backgroundColor:
                                                            "rgb(122 74 201) ",
                                                          border: "none",
                                                        }}
                                                        className="btn deleteButton rounded-3 mt-2"
                                                        type="button"
                                                        onClick={() =>
                                                          handleOpenModal(subItem.id)
                                                        }
                                                      >
                                                        {t("delete")}
                                                      </button>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            ) : subItem.isPhoneFormat ? (
                                              <PhoneInput
                                                placeholder="Enter phone number"
                                                className="form-control"
                                                name={subItem.id}
                                                defaultCountry="GE"
                                                withCountryCallingCode={true}
                                                country="GE"
                                                value={subItem.value}
                                                onChange={e =>
                                                  handleInputChange(
                                                    index,
                                                    subIndex,
                                                    e
                                                  )
                                                }
                                              />
                                            ) : (
                                              <div className="form-group mb-3">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  placeholder={subItem.placeholder}
                                                  value={subItem.value}
                                                  name={subItem.id}
                                                  onChange={e =>
                                                    handleInputChange(
                                                      index,
                                                      subIndex,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                      <div className="form-check">
                                        <input
                                          id={`anonym_${item.cardId}_${index}`}
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={item.isAnonymous}
                                          onChange={e =>
                                            handleIsAnonymousChange(index, e)
                                          }
                                          name="IsAnonymous"
                                          value={item.isAnonymous}
                                        />
                                        <label
                                          className="form-check-label"
                                          for={`anonym_${item.cardId}_${index}`}
                                        >
                                          <img
                                            src={Incognito}
                                            alt="Icon"
                                          />
                                          {t("anonymousOn")}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <Button
                                    type="submit"
                                    text={t("saveCards")}
                                    isLoading={updateCardLoader}
                                  />
                                  {user?.pricingPlan?.name !== "Free" &&
                                    selectedCardIndex !== 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-danger mt-2"
                                        onClick={() => setShowDeleteModal(true)}
                                        style={{
                                          backgroundColor: "rgb(122 74 201) ",
                                          border: "none",
                                          color: color ? "black " : "white ",
                                        }}
                                        onMouseEnter={() => {
                                          setColor(true);
                                        }}
                                        onMouseLeave={() => {
                                          setColor(false);
                                        }}
                                      >
                                        {t("delete")}
                                      </button>
                                    )}
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        <div
          className="text-center  justify-content-center d-flex align-items-center    sticky-bottom "
          style={{
            backgroundColor: "white",
            gap: "5rem",

            width: "100%",
          }}
        >
          <div
            className="border-top d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>

          <button
            className="share-item py-2 px-3"
            onClick={() => setShowModalSecond(true)}
          >
            <img
              src={QrCodeTypeOne}
              alt=""
              className="img-fluid "
              width={54}
              height={54}
            />
          </button>
          <button
            className="share-item py-2 px-3"
            onClick={() => setShowModalFirst(true)}
          >
            <img
              src={QrCodeTypeTwo}
              alt=""
              className="img-fluid "
              width={54}
              height={54}
            />
          </button>

          <div
            className="border-top d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              bottom: "0",
            }}
          ></div>
        </div>
        <div
          className="text-center d-flex justify-content-center d-md-none   align-items-center    sticky-bottom "
          style={{
            backgroundColor: "white",
            gap: "5rem",
            left: "0px",
          }}
        >
          <div
            className="border-top d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>

          <Modal
            show={showModalFirst}
            onHide={() => setShowModalFirst(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="d-flex flex-column  justify-content-center align-items-center ">
                <p>{t("typeTwoQRTitle")}</p>
                <img
                  src={cardQRs?.data?.type2QR}
                  width={250}
                  height={250}
                  alt="QR Code"
                />
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showModalSecond}
            onHide={() => setShowModalSecond(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              {fetchingQRs ? (
                <div className="container d-flex justify-content-center align-content-center">
                  <div
                    className="spinner-border "
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column  justify-content-center align-items-center ">
                  <p>{t("typeOneQRTitle")}</p>
                  <img
                    src={cardQRs?.data?.type1QR}
                    alt=""
                    width={250}
                    height={250}
                    className=""
                  />
                  <a
                    className="d-block"
                    href={cardQRs?.data?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cardQRs?.data?.link}
                  </a>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <div
            className="border-top d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              bottom: "0",
            }}
          ></div>
        </div>
      </div>
      <ModalComponent
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        title={t("deleteCard")}
        desc={`${t("deleteCardDesc")} ${activeCard?.cardName}`}
        func={() => handleDeleteCard(activeCardId)}
        handleModal={() => setShowDeleteModal(false)}
      />
      <ModalComponent
        show={showDeleteItemModal}
        title={t("deleteCardItemInput")}
        desc={`${t("deleteCardItemInputDesc")} ${
          activeCard?.cardItemInputs?.find(input => input.id === selectedCardItemId)
            ?.placeholder
        }`}
        func={handleDeleteItem}
        handleModal={() => setShowDeleteItemModal(false)}
      />
      <Modal
        onHide={() => setShowUsernameModal(false)}
        show={showUsernameModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column gap-3 ">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column gap-1 ">
                <h6 className="fs-5">
                  {t("cardName")} - {selectedCard?.cardName}
                </h6>
                <h6 className="small">
                  {t("changeUsername")} - {selectedCard?.userName}
                </h6>
                <h6 className="small">{t("sendAndWait")}</h6>

                {/* <input
                  type="text"
                  id="newUsername"
                  name="newUsername"
                  value={newUsername}
                  onChange={handleChangeUsername}
                  className="form-control w-100"
                  placeholder={selectedCard?.userName}
                /> */}
              </div>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <input
                  type="text"
                  id="newUsername"
                  name="newUsername"
                  value={newUsername}
                  onChange={handleChangeUsername}
                  className="form-control w-100"
                  placeholder={selectedCard?.userName}
                />
                <button
                  className="btn btn-primary-gradient align-self-end w-75"
                  onClick={handleSendNewUsername}
                  // style={{
                  //   fontSize: "1rem !important",
                  // }}
                >
                  {t("send")}
                </button>
              </div>
            </div>
            <p>
              {t("yourcardlinkwillbe")}{" "}
              <strong>
                https://addme.ge/{newUsername ? newUsername : selectedCard?.userName}
              </strong>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
