import { Link } from "react-router-dom";
import AboutImage from "../../assets/img/eco.jpeg";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container-xxl py-5"
      id="about"
    >
      <div className="container py-5 px-lg-5">
        <div className="row g-5 align-items-center">
          <div
            className="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h5 className="text-primary-gradient fw-medium">{t("aboutTitle")}</h5>
            <h1 className="mb-4">{t("aboutSubTitle")}</h1>
            <p className="mb-4">{t("aboutText")}</p>
            <div className="row g-4 mb-4">
              <div
                className="col-sm-6 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <div className="d-flex">
                  <i className="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                  <div className="ms-3">
                    <h2
                      className="mb-0"
                      data-toggle="counter-up"
                    >
                      1234
                    </h2>
                    <p className="text-primary-gradient mb-0">
                      {t("aboutActiveInstalls")}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-6 wow fadeIn"
                data-wow-delay="0.7s"
              >
                <div className="d-flex">
                  <i className="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                  <div className="ms-3">
                    <h2
                      className="mb-0"
                      data-toggle="counter-up"
                    >
                      1234
                    </h2>
                    <p className="text-secondary-gradient mb-0">
                      {t("aboutClientReview")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Link
              to=""
              className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3"
            >
              {t("aboutReadMore")}
            </Link>
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid wow fadeInUp"
              data-wow-delay="0.5s"
              src={AboutImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
