import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config/config";
import { setToastNotification } from "../slices/ToastNotificationSlice";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery,
  endpoints: builder => ({
    signIn: builder.mutation({
      query: user => ({
        url: "/api/auth/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: user,
      }),
      // Add extra logic to store token after successful login
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          localStorage.setItem("token", data.token);
          // if (!data.success) {
          //   dispatch(
          //     setToastNotification({
          //       type: "error",
          //       message: "Invalid email or password",
          //     })
          //   );
          // }
        } catch (error) {
          console.error("Error storing tokens:", error);
          dispatch(
            setToastNotification({
              type: "error",
              message: "An error occurred while storing tokens",
            })
          );
        }
      },
    }),
    signUp: builder.mutation({
      query: user => ({
        url: "/api/auth/register",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: user,
      }),
    }),
    googleAuth: builder.mutation({
      query: token => ({
        url: "/api/auth/login-google",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: token,
      }),
    }),
  }),
});

export const { useSignInMutation, useGoogleAuthMutation, useSignUpMutation } =
  authApi;
