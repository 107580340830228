import React from "react";
import ScreenShot1 from "../../assets/img/user garet.svg";
import ScreenShot2 from "../../assets/img/1.svg";
import ScreenShot3 from "../../assets/img/2.svg";
import ScreenShot4 from "../../assets/img/3.svg";
import ScreenShot5 from "../../assets/img/4.svg";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";
import Carousel from "../carousels/carousel";

const Screenshots = () => {
  const { t } = useTranslation();
  return (
    <div className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <div className="row g-5 align-items-center">
          <div
            className="col-lg-8 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h1 className="mb-4">{t("screenshotsSubTitle")}</h1>
            <p className="mb-4">{t("screenshotText")}</p>
            <p>
              <i className="fa fa-check text-primary-gradient me-3"></i>
              {t("screenshots1")}
            </p>
            <p>
              <i className="fa fa-check text-primary-gradient me-3"></i>
              {t("screenshots2")}
            </p>
            <p className="mb-4">
              <i className="fa fa-check text-primary-gradient me-3"></i>
              {t("screenshots3")}
            </p>
          </div>
          <div
            className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <Carousel />
            {/* <div className="owl-carousel screenshot-carousel screenshot-carousel-2">
              <img
                className="img-fluid"
                src={ScreenShot1}
                alt=""
              />
              <img
                className="img-fluid"
                src={ScreenShot2}
                alt=""
              />
              <img
                className="img-fluid"
                src={ScreenShot3}
                alt=""
              />
              <img
                className="img-fluid"
                src={ScreenShot4}
                alt=""
              />
              <img
                className="img-fluid"
                src={ScreenShot5}
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Helmet>
        <script>
          {`
                      $(".screenshot-carousel-2").owlCarousel({
                        autoplay: true,
                        smartSpeed: 1000,
                        loop: true,
                        dots: true,
                        items: 1
                    });
                    `}
        </script>
      </Helmet> */}
    </div>
  );
};

export default Screenshots;
