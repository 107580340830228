import axios from 'axios';
import config from '../config/config'

const isLocal = window.location.href.includes("localhost")

const _axios = axios.create({
    // baseURL: '/api/',3
    ...(isLocal ? {baseURL: `${config.BASE_URL}/api/`} : {baseURL: `${config.BASE_URL}/api/`}),
    headers: {
        authorization: localStorage.getItem('token'),
        language: localStorage.getItem('language'),
        withCredentials: false
    }
})

export default _axios;