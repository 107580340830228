import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const pricingPlanApi = createApi({
  reducerPath: "pricingPlanApi",
  baseQuery: baseQuery,
  tagTypes: ["userProfile", "pricingTier"],
  endpoints: builder => ({
    getPricingPlans: builder.query({
      query: () => ({
        url: "/api/PricingPlan/GetPricingPlans",
        method: "GET",
      }),
      providesTags: ["pricingTier"],
    }),

    createPricingPlan: builder.mutation({
      query: body => ({
        url: "/api/PricingPlan/CreatePricingPlan",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }),
      invalidatesTags: ["pricingTier"],
    }),
    deletePricingPlan: builder.mutation({
      query: id => ({
        url: `/api/PricingPlan/DeletePricingPlan?id=${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["pricingTier"],
    }),
    editPricingPlan: builder.mutation({
      query: ({ id, editablePricingTier }) => ({
        url: `/api/PricingPlan/UpdatePricingPlan?id=${id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: editablePricingTier,
      }),
      invalidatesTags: ["pricingTier"],
    }),
    updateUserPricingPlan: builder.mutation({
      query: ({ userId, id, duration }) => ({
        url: `api/PricingPlan/UpdateUserPricingPlan?userId=${userId}&pricingPlanId=${id}&duration=${duration}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["userProfile"],
    }),
  }),
});

export const {
  useGetPricingPlansQuery,
  useUpdateUserPricingPlanMutation,
  useEditPricingPlanMutation,
  useDeletePricingPlanMutation,
  useCreatePricingPlanMutation,
} = pricingPlanApi;
