import { Link } from "react-router-dom";
import useAsyncCall from "../../hooks/useAsyncCall";

import {
  useGetSubscribersQuery,
  useRemoveSubscriberMutation,
} from "../../Redux/services/SubscribeApi";

export default function Subscription() {
  const { data: subscribers, error, isLoading } = useGetSubscribersQuery();
  const [removeSubscriber] = useRemoveSubscriberMutation();

  const getSubscribers = useAsyncCall();
  const deleteSubscriber = useAsyncCall();

  const handleRemoveSubscriber = async id => {
    const res = await removeSubscriber(id);
  };

  return (
    <div className="container mt-5 ">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {subscribers?.data?.map((subscriber, index) => (
            <tr key={subscriber}>
              <td>{subscriber}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    handleRemoveSubscriber(subscriber);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="btn btn-primary-gradient">
        <Link
          to="/admin"
          className="text-decoration-none text-white"
        >
          Go To Admin Page
        </Link>
      </button>
    </div>
  );
}
