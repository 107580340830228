import "../../assets/share.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useSearchParams } from "react-router-dom";

const QR = ({ isUserPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let { guid } = useParams();
  let typeID = searchParams.get("type");
  const getqr = useAsyncCall();
  const getUsername = useAsyncCall();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeID == 1) {
      getUsername.request("get", `/Card/GetUserNameByGuid/${guid}`).then(e => {
        if (e.success) {
          window.location.href = `/${e.data}`;
        } else if (e.errorCode === 3) {
          alert("User not found");
        }
      });
    } else {
      getqr.request("get", `/Card/GetVCard/${guid}`).then(e => {
        if (e.success && e.data.isAnonymous) {
          alert("Card is anonymous");
        } else if (e.success && e.data.vCard) {
          console.log(e.data.vCard);
          var vCard = e.data.vCard;
          setLoading(false);
          var blob = new Blob([vCard], { type: "text/vcard" });
          var url = URL.createObjectURL(blob);
          const newLink = document.createElement("a");
          newLink.download = "vCard.vcf";
          newLink.href = url;
          document.body.appendChild(newLink);
          newLink.click();
          document.body.removeChild(newLink);
        } else {
          alert("Card not found");
        }
      });
    }
  }, []);

  return <></>;
};

export default QR;
