import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import TemplateThree from "../../components/templates/templateThree";
import TemplateTwo from "../../components/templates/templateTwo";
import { useSelector } from "react-redux";

const Templates = () => {
  const { selectedCard } = useSelector(state => state.user);

  const previewContainerStyle = {
    width: "300px", // ფიქსირებული სიგანე
    height: "400px", // ფიქსირებული სიმაღლე
    border: "1px solid #ddd",
    overflow: "auto", // ან 'hidden' იმისთვის, რომ შიგნით არსებული შინაარსი მოწესრიგებულად ჩანდეს
    // position: "fixed", // ფიქსირებული პოზიცია
    // bottom: "20px", // გვერდის ქვემოთ (შეგიძლია შეცვალო)
    // right: "20px", // გვერდის მარჯვნივ (შეგიძლია შეცვალო)
    backgroundColor: "#fff",
    // zIndex: 1000, // ზემო ზღვარზე დასმული ელემენტები რომ არ დაფარონ
    padding: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <>
      <Sidebar />
      <div className="container-xxl ms-5 ps-5 d-flex flex-wrap gap-4">
        <div style={previewContainerStyle}>
          <TemplateTwo username={selectedCard?.userName} />
        </div>
        <div style={previewContainerStyle}>
          <TemplateThree id={selectedCard?.userName} />
        </div>
        {/* <div style={previewContainerStyle}>
          <TemplateOne />
        </div> */}
      </div>
    </>
  );
};

export default Templates;
