export default function PricingTiers({
  pricingTiers,
  pricingTierLoading,
  handleDeletePricingTier,
  setEditablePricingTier,
  setEditableModal,
}) {
  return (
    <>
      {pricingTierLoading ? (
        <div className="container d-flex justify-content-center ">
          <div
            className="spinner-border "
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="row">
          {pricingTiers?.data?.map(pricingTier => (
            <div className="col-md-4 mb-3 ">
              <div className="card">
                <div className="card-header">
                  <h3>{pricingTier?.name}</h3>
                </div>
                <div className="card-body">
                  <p>Card Limit : {pricingTier?.cardLimit}</p>

                  <p>Card ShortURL : {pricingTier?.shortLinkLimit}</p>

                  <p>Card QR code Limit : {pricingTier?.qrLimit}</p>

                  <p>
                    {pricingTier?.canChooseTemplate
                      ? "Can Choose template"
                      : "Can not choose template"}
                  </p>
                  <p>
                    {pricingTier?.canAnalytics
                      ? "Has analytics"
                      : "Has not analytics"}
                  </p>
                  <p>
                    {pricingTier?.canChangeUsername
                      ? "Can change username"
                      : "Can not change username"}
                  </p>
                  <p>
                    {pricingTier?.canChooseQRLogo
                      ? "Can choose QR Logo"
                      : "Can not choose QR Logo"}
                  </p>
                  <p>
                    {pricingTier.phoneLimit
                      ? `Phone Limit : ${pricingTier.phoneLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.viberLimit
                      ? `Viber Limit : ${pricingTier.viberLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.whatsappLimit
                      ? `Whatsapp Limit : ${pricingTier.whatsappLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.skypeLimit
                      ? `Skype Limit : ${pricingTier.skypeLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.emailLimit
                      ? `Email Limit : ${pricingTier.emailLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.youtubeLimit
                      ? `Youtube Limit : ${pricingTier.youtubeLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.firstnameLimit
                      ? `Firstname Limit : ${pricingTier.firstnameLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.telegramLimit
                      ? `Telegram Limit : ${pricingTier.telegramLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.tiktokLimit
                      ? `Tiktok Limit : ${pricingTier.tiktokLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.adressLimit
                      ? `Adress Limit : ${pricingTier.adressLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.githubLimit
                      ? `Github Limit : ${pricingTier.githubLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.pinterestLimit
                      ? `Pinterest Limit : ${pricingTier.pinterestLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.twitterLimit
                      ? `Twitter Limit : ${pricingTier.twitterLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.instagramLimit
                      ? `Instagram Limit : ${pricingTier.instagramLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.spotifyLimit
                      ? `Spotify Limit : ${pricingTier.spotifyLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.companyLimit
                      ? `Company Limit : ${pricingTier.companyLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.facebookLimit
                      ? `Facebook Limit : ${pricingTier.facebookLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.linkedinLimit
                      ? `Linkedin Limit : ${pricingTier.linkedinLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.wechatLimit
                      ? `Wechat Limit : ${pricingTier.wechatLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.websiteLimit
                      ? `Website Limit : ${pricingTier.websiteLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.udemyLimit
                      ? `Udemy Limit : ${pricingTier.udemyLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.lastnameLimit
                      ? `Lastname Limit : ${pricingTier.lastnameLimit}`
                      : ""}
                  </p>
                  <p>
                    {pricingTier.positionLimit
                      ? `Position Limit : ${pricingTier.positionLimit}`
                      : ""}
                  </p>

                  <div className="d-flex gap-4 ">
                    <button
                      className="btn btn-danger "
                      onClick={() => handleDeletePricingTier(pricingTier?.id)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        setEditablePricingTier(pricingTier);
                        setEditableModal(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
