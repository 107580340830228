import React, { useState } from "react";
import _axios from "../utils/axios";

const useAsyncCall = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const request = (method, url, reqData) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      _axios({
        method: method,
        url: url,
        ...(method === "post" ? { data: reqData } : { params: reqData }),
      })
        .then(resp => {
          setSuccess(true);
          setData(resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          setSuccess(false);
          setError(err);
          reject(err);
        })
        .finally(() => setLoading(false));
    });
  };

  return { data, loading, error, request, success };
};

export default useAsyncCall;
