import { useTranslation } from "react-i18next";
import useAsyncCall from "../../hooks/useAsyncCall";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "../../components/navbar/navbar";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useLazyGetUsersQuery } from "../../Redux/services/UserApi";
import {
  useDeleteUserMutation,
  useChangeUserStatusMutation,
} from "../../Redux/services/AdminApi";
import "react-toastify/dist/ReactToastify.css";

export default function HandleUser() {
  const [trigger, { data: getUsers }] = useLazyGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [changeUserStatus] = useChangeUserStatusMutation();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();
  // const getUsers = useAsyncCall();
  // const deleteUser = useAsyncCall();
  // const changeUserStatus = useAsyncCall();

  const handleApproveDelete = async id => {
    // deleteUser.request("delete", `/Admin/DeleteUser/${id}`).then(res => {
    //   if (res.success) {
    //     alert("User deleted successfully");
    //     setOpenModal(false);
    //     setSelectedUser({});
    //     getUsers.request("get", "/User/GetUsers").then(res => {
    //       setUsers(res.data);
    //     });
    //   }
    // });

    const response = await deleteUser(id).unwrap();

    try {
      if (response.success) {
        // alert("User deleted successfully");
        toast.success("User deleted successfully", {
          autoClose: 2000,
          position: "top-right",
        });
        setOpenModal(false);
        setSelectedUser({});
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred", {
        autoClose: 2000,
        position: "top-right",
      });
    }
  };
  const handleDeleteUser = user => {
    setOpenModal(true);
    setSelectedUser(user);
  };

  const handleChangeUserStatus = async id => {
    // changeUserStatus.request("post", `/Admin/ChangeUserStatus/${id}`).then(res => {
    //   if (res.success) {
    //     getUsers.request("get", "/User/GetUsers").then(res => {
    //       setUsers(res.data);
    //     });
    //   }
    // });

    const response = await changeUserStatus(id).unwrap();
    try {
      if (response.success) {
        toast.success("User status changed successfully", {
          autoClose: 2000,
          position: "top-right",
        });
        trigger();
      } else {
        toast.error("An error occurred", {
          autoClose: 2000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred", {
        autoClose: 2000,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    // getUsers.request("get", "/User/GetUsers").then(res => {
    //   setUsers(res.data);
    // });
    trigger();
  }, []);

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container mt-lg-5 pt-lg-5">
        <div className="row">
          <div className="col-12">
            <h1>Users</h1>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Firstname</th>

                  <th>Lastname</th>

                  <th>Email</th>

                  <th>Status</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {getUsers?.data?.map(user => (
                  <tr key={user.id}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.deactivated === false ? (
                        <span className="btn btn-success ">Active</span>
                      ) : (
                        <span className="btn btn-danger">Passive</span>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-danger me-3"
                        onClick={() => handleDeleteUser(user)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-warning"
                        onClick={() => handleChangeUserStatus(user.id)}
                      >
                        Change Status
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete {selectedUser.email}?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleApproveDelete(selectedUser.id)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}
