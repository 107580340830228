import { Skeleton } from "antd";
import { Link } from "react-router-dom";

export default function AboutUser({
  userData,
  userDataLoading,
  checkEmailAddresses,
  dispatch,
  selectUserId,
}) {
  return (
    <>
      <div className="col-md-8">
        <ul className="list-group">
          <li className="list-group-item">
            First Name:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.firstname
            )}
          </li>

          <li className="list-group-item">
            Last Name:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.lastname
            )}
          </li>
          <li className="list-group-item">
            Email:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.email
            )}
          </li>
          {/* <li className="list-group-item">Email: {profile?.email}</li> */}
          <li className="list-group-item">
            Username:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.cards.map(card => (
                <>{card?.isDefault && <span>{card?.userName}</span>}</>
              ))
            )}
          </li>

          <li className="list-group-item">
            URL:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.cards?.map(card => (
                <>
                  {card?.isDefault && (
                    <Link to={`${window.location.origin}/${card?.userName}`}>
                      {`${window.location.origin}/${card?.userName}`}
                    </Link>
                  )}
                </>
              ))
            )}
          </li>
          <li className="list-group-item">
            NFC Sticker:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              checkEmailAddresses && "ვაჩუქეთ"
            )}
          </li>

          <li className="list-group-item">
            Register Date:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.registerDate
            )}
          </li>
          <li className="list-group-item">
            Referral:
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.referral
            )}
          </li>
          <li className="list-group-item">
            Referral Email:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              userData?.data?.referralEmail
            )}
          </li>
          <li className="list-group-item">
            Pricing Plan:
            <strong>
              {userDataLoading ? (
                <Skeleton.Input
                  style={{ width: 150, marginLeft: 10, height: 25 }}
                  active
                />
              ) : (
                userData?.data?.pricingPlan?.name
              )}
            </strong>
          </li>
          <li className="list-group-item">
            Pricing Plan Expire Date:
            <strong>
              {userDataLoading ? (
                <Skeleton.Input
                  style={{ width: 150, marginLeft: 10, height: 25 }}
                  active
                />
              ) : (
                userData?.data?.pricingPlanExpireDate
              )}
            </strong>
          </li>
          <li className="list-group-item">
            Deactivated:{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : userData?.data?.deactivated ? (
              "Yes"
            ) : (
              "No"
            )}
          </li>
          <li className="list-group-item">
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              <Link
                className="text-decoration-none "
                onClick={() => dispatch(selectUserId(userData?.data?.id))}
                to={`${window.location.origin}/analytics/${userData?.data?.guid}`}
              >
                Go to analytics page
              </Link>
            )}
          </li>
          <li className="list-group-item">
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              <Link
                className="text-decoration-none "
                to={`${window.location.origin}/loginhistory/${userData?.data?.userName}`}
              >
                Go to Login History page
              </Link>
            )}
          </li>
          <li className="list-group-item">
            Referral Link :{" "}
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              `${window.location.origin}/referral/${userData?.data?.guid}`
            )}
          </li>
          <li className="list-group-item">
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              <Link
                className="text-decoration-none "
                to={`${window.location.origin}/qr/${userData?.data?.guid}?type=2`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${window.location.origin}/qr/${userData?.data?.guid}?type=2`}
              </Link>
            )}
          </li>
          <li className="list-group-item">
            {userDataLoading ? (
              <Skeleton.Input
                style={{ width: 150, marginLeft: 10, height: 25 }}
                active
              />
            ) : (
              <Link
                className="text-decoration-none "
                to={`${window.location.origin}/qr/${userData?.data?.guid}?type=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${window.location.origin}/qr/${userData?.data?.guid}?type=1`}
              </Link>
            )}
          </li>
        </ul>
      </div>
    </>
  );
}
