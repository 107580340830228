import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: baseQuery,
  tagTypes: [
    "User",
    "Users",
    "Card",
    "QRCode",
    "ShortURL",
    "CardAnalytics",
    "Type1Analytics",
    "Type2Analytics",
    "QRCodeAnalytics",
    "ShortURLAnalytics",
    "Admin",
    "Usernames",
    "UserProfile",
    "pricingTier",
  ],
  endpoints: builder => ({
    cardAnalytics: builder.query({
      query: ({ username, startDate, endDate }) => ({
        url: username
          ? `api/analytics/cardviewanalytics/${username}?start=${startDate}&end=${endDate}`
          : "api/analytics/cardviewanalytics",
        method: "GET",
      }),
    }),
    type1Analytics: builder.query({
      query: ({ guid, typeId, startDate, endDate }) => ({
        url: `api/Analytics/CardQRViewAnalytics/${guid}?typeId=${typeId}&start=${startDate}&end=${endDate}`,
        method: "GET",
      }),
    }),
    shortURLanalytics: builder.query({
      query: ({ guid, startDate, endDate }) => ({
        url: `api/Analytics/ShortUrlAnalytics/${guid}?start=${startDate}&end=${endDate}`,
        method: "GET",
      }),
    }),
    customQRAnalytics: builder.query({
      query: ({ guid, startDate, endDate }) => ({
        url: `api/Analytics/CustomQRViewAnalytics/${guid}?start=${startDate}&end=${endDate}`,
        method: "GET",
      }),
    }),

    getLogins: builder.query({
      query: userName => ({
        url: `/api/analytics/getlogins?userName=${userName}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetLoginsQuery,
  useLazyCardAnalyticsQuery,
  useLazyType1AnalyticsQuery,
  useLazyShortURLanalyticsQuery,
  useLazyCustomQRAnalyticsQuery,
} = analyticsApi;
