export default function UserReferrals({
  referralUsers,
  toggleCard,
  toggleCardView,
}) {
  return (
    <>
      <div className="card mt-3">
        <div
          className="card-header"
          style={{
            cursor: "pointer",
          }}
          onClick={() => toggleCardView()}
        >
          <h2>User referrals</h2>
        </div>
        <div className={`card-body ${toggleCard ? "d-block" : "d-none"}`}>
          <table className="table w-100">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {referralUsers?.map(user => (
                <tr key={user?.id}>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
