import React, { useEffect, useState, useRef } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar/navbar";
import { useLazyGetUsersQuery } from "../../Redux/services/UserApi";
import { setUsers } from "../../Redux/slices/UserSlice";
import { useDispatch } from "react-redux";
import "./users.css";
import { Link } from "react-router-dom";

const Users = ({ isAuthorized }) => {
  const [trigger, { data: getUsers, isLoading, isSuccess }] = useLazyGetUsersQuery();
  const [pricingPlan, setPricingPlan] = useState("All");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const getUsers = useAsyncCall();

  // Ref for the table to apply drag-scroll functionality
  const tableRef = useRef(null);

  // State for drag-scroll
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredUsers = getUsers?.data
    ? getUsers?.data
        ?.filter(
          user =>
            user?.firstname.toLowerCase().includes(searchItem.toLowerCase()) ||
            user?.lastname.toLowerCase().includes(searchItem.toLowerCase()) ||
            user?.userName.toLowerCase().includes(searchItem.toLowerCase()) ||
            user?.email.toLowerCase().includes(searchItem.toLowerCase()) ||
            user?.pricingPlan?.name.toLowerCase().includes(searchItem.toLowerCase())
        )
        .filter(
          user =>
            pricingPlan === "All" ||
            user?.pricingPlan?.name.toLowerCase() === pricingPlan.toLowerCase()
        )
    : getUsers?.data;

  const plans = getUsers?.data
    .map(user => user.pricingPlan?.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  const itemsPerPage = 100;
  const totalPages = Math.ceil(filteredUsers?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers?.slice(indexOfFirstItem, indexOfLastItem);

  const filterUsersBy = (users, plan) => {
    return users.filter(
      user => user.pricingPlan?.name === plan && console.log(user)
    );
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleChange = event => {
    setSearchItem(event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    trigger();

    if (isSuccess) {
      dispatch(setUsers(getUsers?.data));
    }
  }, [dispatch, getUsers?.data, isSuccess, trigger]);

  // Drag event handlers
  const startDragging = e => {
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDrag = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = x - startX;
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  // if (loading) return <div>Loading...</div>;
  // if (!loading && !permission)
  //   return (
  //     <div className="d-flex justify-content-center p-5 text-center">
  //       <h1>No access</h1>
  //     </div>
  //   );

  if (getUsers?.errorCode === 2) {
    return (
      <div className="d-flex justify-content-center p-5 text-center">
        <h1>No access</h1>
      </div>
    );
  }

  const totalCount = getUsers?.data?.length;

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container mt-lg-5 ">
        {isLoading ? (
          <div className="container d-flex justify-content-center mt-lg-5 pt-lg-5">
            <div
              className="spinner-border "
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center pt-lg-5 ">
            <div className="col-12">
              <h3 style={{ margin: "20px 0" }}>
                {t("userCount")} {totalCount}
              </h3>
              <div className="d-flex gap-2 mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchItem}
                  onChange={handleChange}
                  className="search-input"
                />
                <button
                  onClick={() => trigger()}
                  className="btn btn-primary"
                >
                  Refresh
                </button>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={pricingPlan}
                  onSelect={e => {
                    setPricingPlan(e);
                  }}
                >
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  {plans?.map((plan, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={plan}
                    >
                      {plan}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div
                className="table-responsive table-container"
                ref={tableRef}
                onMouseDown={startDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onMouseMove={onDrag}
                style={{ cursor: isDragging ? "grabbing" : "grab" }}
              >
                <table className="table table-bordered">
                  <thead className="thead-dark sticky-header">
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "120px" }}
                      >
                        Register Date
                      </th>
                      <th
                        scope="col"
                        style={{ width: "50px" }}
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        style={{ width: "150px" }}
                      >
                        Pricing Plan
                      </th>
                      <th
                        scope="col"
                        style={{ width: "50px" }}
                      >
                        Firstname
                      </th>
                      <th
                        scope="col"
                        style={{ width: "100px" }}
                      >
                        Lastname
                      </th>
                      <th
                        scope="col"
                        style={{ width: "150px" }}
                      >
                        Link Username
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((user, index) => (
                      <tr key={index}>
                        <td>{user.registerDate}</td>
                        <td>
                          <Link to={`${user.id}`}>{user.email}</Link>
                        </td>
                        <td>{user.pricingPlan?.name}</td>
                        <td>{user.firstname}</td>
                        <td>{user.lastname}</td>
                        <td>
                          <a
                            href={`${window.location.origin}/${user.userName}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {`${window.location.origin}/${user.userName}`}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex my-3 gap-3 ">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`btn btn-primary-gradient  ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <div>
                <Link
                  to="/admin"
                  className="btn btn-primary "
                >
                  Back To Admin Page
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
