const Terms = () => {
  return (
    <>
      <div className="p-5 mt-3">
        <h3>
          <strong>
            <span style={{ "font-size": "11pt" }}>
              პერსონალურ მონაცემთა დაცვის პოლიტიკა
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ "font-size": "11pt" }}>
            პერსონალურ მონაცემთა დაცვის პოლიტიკა აღწერს თუ როგორ ამუშავებს addme.ge
            თქვენს მიერ ჩვენთვის მოწოდებულ ინფორმაციას და მონაცემებს რათა ვმართოთ
            თქვენი ციფრული სავიზიტო ვებ-გვერდები. თქვენს შესახებ ჩვენთვის მოწოდებულ
            ან სხვაგვარად ჩვენს ხელთ არსებული ნებისმიერი ინფორმაციის დამუშავება ხდება
            წინამდებარე პოლიტიკით განსაზღვრული წესით. ინფორმაციის მოწოდება
            შესაძლებელია ვებ-გვერდ addme.ge-ს საშუალებით ან სხვა საკომუნიკაციო არხით.
            აღნიშნული დოკუმენტით გვსურს, გაგიზიაროთ თუ რა საფუძვლით და მიზნით
            მოვიპოვებთ და ვამუშავებთ თქვენს პერსონალურ მონაცემებს და რა სამართლებრივი
            და ტექნიკური საშუალებებით ვიცავთ მათ. პერსონალურ მონაცემთა დაცვის
            პოლიტიკის მიღებით თქვენ აცნობიერებთ და აცხადებთ, რომ თქვენთვის გასაგებია
            და თანახმა ხართ თქვენი პერსონალური მონაცემების დამუშავება მოხდეს ამ
            პოლიტიკის შესაბამისად.
          </span>
        </p>
        <p>
          <span style={{ "font-size": "11pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "11pt" }}>1. პროფილის შექმნა და მართვა</span>
          <span style={{ "font-size": "11pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "11pt" }}>
            addme.ge-ს პლატფორმის საშუალებით თქვენ შეგიძლიათ შექმნათ ციფრული სავიზიტო
            ბარათი/გვერდი. პროფილის შექმნის შემდეგ &nbsp;თქვენ შეგიძლიათ მართოთ
            თქვენი პროფილი, შეავსოთ სასურველი მონაცემები: სახელი, გვარი, კომპანიის
            დასახელება, პოზიცია, მობილურის ნომერი, ვებ-გვერდი, სოციალური ქსელები და
            ატვირთოთ სურათი, და შემდგომში დაარედაქტიროთ მონაცემები.&nbsp;
          </span>
          <span style={{ "font-size": "11pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "11pt" }}>
            დაუშვებელია სექსულური შინაარსის ან კანონით დასჯადი სხვა სახის სურათების
            ატვირთვა, მსგავსი შინაარსის სურათის/ექაუნთის აღმოჩენის შემთხვევაში
            ადმინისტრაცია იტოვებს უფლებას თქვენთან შეთანხმებისა და შეტყობინების
            გარეშე მოახდინოს მათი წაშლა addme.ge -ს პლატფორმიდან.&nbsp;
          </span>
          <span style={{ "font-size": "11pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "11pt" }}>2.&nbsp;</span>
          <span style={{ "font-size": "10.5pt" }}>ვინ ვართ ჩვენ?</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            პერსონალურ მონაცემთა დაცვის პოლიტიკაში მითითებები addme.ge -ზე
            (&bdquo;ვებგვერდი&ldquo; ან &bdquo;გვერდი&ldquo;). ჩვენ განვსაზღვრავთ
            პერსონალურ მონაცემთა დამუშავების მიზნებსა და საშუალებებს.
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            Როდესაც თქვენ სარგებლობთ ჩვენი მომსახურებით ვებ-გვერდის საშუალებით, ჩვენ
            ვუზრუნველყოფთ თქვენი პერსონალური მონაცემების დაცვასა და უსაფრთხოებას.
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            3.ვისთვის არის გამიზნული ეს დოკუმენტი?
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            addme.ge-ს მომხმარებლებისთვის (მათ შორის ყოფილი, და/ან მომავალი) ან სხვა
            პირებისთვის, ვინც გამოხატა ინტერესი კონკრეტულ პროდუქტსა თუ მომსახურებაზე,
            არის ჩვენი მომხმარებლის კანონიერი წარმომადგენელი, ან საკონტაქტო პირი.
            ასევე სხვა ნებისმიერი პირისთვის, ვისთანაც ჩვენ პირდაპირი კონტაქტი არ
            გვაქვს, მაგრამ მისი ინფორმაციის დამუშავება აუცილებელია/სასურველია ჩვენი
            საქმიანობის განსახორციელებლად ან/და ჩვენი პროდუქტების/მომსახურების
            გასაუმჯობესებლად.
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            4.რა არის თქვენი პერსონალური მონაცემების დამუშავების მიზანი?
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            ჩვენსა და მომხმარებლებს შორის გაფორმებული ხელშეკრულებით ნაკისრი
            ვალდებულებების, მათ შორის სამართლებრივი ვალდებულებების ეფექტური
            შესრულების მიზნით, ჩვენ ვახორციელებთ თქვენი პერსონალური მონაცემების
            დამუშავებას შემდეგი მიზნებით:
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            ხარისხიანი პროდუქტებისა და სერვისის მიწოდებისთვის;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            თქვენგან უკუკავშირის მიღებისა და პრეტენზიებზე რეაგირებისთვის;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            ჩვენი სისტემების არასათანადო გამოყენებისა და დანაშაულის პრევენციისთვის;
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            ჩვენი და თქვენი კანონიერი ინტერესების დასაცავად;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>სხვა ლეგიტიმური მიზნებით.</span>
        </p>
        <p>
          <em>
            <span style={{ "font-size": "10.5pt" }}>პერსონალური მონაცემები</span>
          </em>
          <span style={{ "font-size": "10.5pt" }}>:</span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <u>
            <span style={{ "font-size": "10.5pt" }}>
              მომხმარებლის ანგარიშის დეტალები:
            </span>
          </u>
          <span style={{ "font-size": "10.5pt" }}>
            &nbsp;თქვენს მიერ მომხმარებლის ანგარიშის გახსნისას (რეგისტრაციისას) და
            სერვისებით &nbsp;სარგებლობისას (ციფრული სავიზიტო ბარათის/გვერდის
            მონაცემების შევსებისას).&nbsp;
          </span>
        </p>
        <p>
          <u>
            <span style={{ "font-size": "10.5pt" }}>
              ვებ-გვერდზე შესვლის, ლოგ - ინ ისტორია და ტექნიკური ინფორმაცია :
            </span>
          </u>
          <span style={{ "font-size": "10.5pt" }}>
            &nbsp;სერვისების ფუნქციონირების გაუმჯობესებისა და მომხმარებლისთვის
            უკეთესი გამოცდილების გაზიარების მიზნით, ჩვენ ვაგროვებთ თქვენი
            მოწყობილობით, მათ შორის პროგრამული და ტექნიკური უზრუნველყოფით გადმოცემულ
            ტექნიკურ ინფორმაციას (მაგ.: თუ რა ტიპის ბრაუზერს და ოპერაციულ სისტემას
            იყენებს თქვენი მოწყობილობა, რომელ ენას ანიჭებთ უპირატესობას, შესვლის დრო
            და ვებ-გვერდის დომენის სახელი, საიდანაც თქვენ ან სხვა პირები უკავშირდებით
            სერვისებს და ა.შ.).&nbsp;
          </span>
        </p>
        <p>
          <u>
            <span style={{ "font-size": "10.5pt" }}>
              რამდენ ხანს ვინახავთ თქვენს პერსონალურ მონაცემებს ?
            </span>
          </u>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            addme.ge უზრუნველყოფს თქვენი პერსონალური მონაცემების თანმიმდევრული და
            მაღალი ხარისხით დაცვასა და უსაფრთხოებას. &nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            თქვენს მონაცემებს ვინახავთ იმ ვადით, რაც აუცილებელია დამუშავების მიზნის
            მისაღწევად. თუ თქვენ აღარ ხართ addme.ge-ს მომხმარებელი, თქვენი მონაცემები
            შეინახება პოლიტიკის, კანონით და მარეგულირებელი დოკუმენტების
            გათვალისწინებული მიზნების შესაბამისად.
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            5. როგორ ვიცავთ თქვენი პერსონალური მონაცემების უსაფრთხოებას?
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            ჩვენ დიდ ყურადღებას ვუთმობთ თქვენი პერსონალური მონაცემების დაცვის
            ღონისძიებების დანერგვას და განხორციელებას. ჩვენ მიერ დანერგილ იქნა
            უსაფრთხოების სათანადო ფიზიკური, ტექნიკური და ორგანიზაციული ღონისძიებები,
            რათა მოხდეს არასანქცირებული წვდომის პრევენცია და ჩვენ მიერ ინტერნეტით
            შეგროვებული ინფორმაციის სწორი გამოყენების უზრუნველყოფა. უსაფრთხოების ეს
            ზომები განსხვავდება ჩვენ მიერ შეგროვებული და შენახული ინფორმაციის
            სენსიტიურობის მიხედვით.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>6. ონლაინ უსაფრთხოება</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მიუხედავად იმისა, რომ ინფორმაციული უსაფრთხოების მიზნით ვიღებთ ყველა
            საჭირო ზომას, სწრაფი ტექნოლოგიური განვითარების პირობებში არ ვიღებთ
            პასუხისმგებლობას იმ პირების ქმედებებზე, რომლებიც ახორციელებენ
            არასანქცირებულ წვდომას, ბოროტად იყენებენ ან ტეხავენ სერვისებს. ჩვენ არ
            ვიძლევით აშკარა, ნაგულისხმევ ან სხვაგვარ გარანტიას, გარდა იმისა, რომ
            მივიღებთ ყველა ზომას ამგვარი შეღწევის პრევენციისთვის.&nbsp;
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>7.გარე ბმულები</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            გვერდი შეიძლება შეიცავდეს სხვა ვებგვერდებზე ჰიპერბმულებს, რომელთაც
            შეიძლება ჰქონდეთ ჩვენგან განსხვავებული პოლიტიკები და მეთოდები. ის ფაქტი,
            რომ ჩვენ მოვახდინეთ ბმულის უზრუნველყოფა გვერდზე, არ წარმოადგენს ჩვენ მიერ
            მოწონებას, ავტორიზაციას, სპონსორობას ან ჩვენთვის მიკუთვნებას ამ გვერდთან,
            მის მფლობელებთან ან პროვაიდერებთან. სხვა გვერდებზე ბმულები მოცემულია
            მხოლოდ თქვენი გამოცდილების გამარტივების თვალსაზრისით. ჩვენ პასუხს არ
            ვაგებთ ჩვენს ვებგვერდთან დაკავშირებული სხვა ვებგვერდების შინაარსზე,
            უტყუარობაზე, პერსონალურ მონაცემთა დაცვის პოლიტიკასა და საიმედოობაზე.
            რისკი არსებობს ინტერნეტით ნებისმიერი ინფორმაციის, პროდუქტის, პროგრამული
            უზრუნველყოფის და სერვისების გამოყენებისას. ინტერნეტით ამ გვერდების
            გამოყენებამდე, ნდობამდე და რაიმეს შეძენამდე &nbsp;დარწმუნდით, რომ სრულად
            აცნობიერებთ ამ რისკებს.&nbsp;
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>8.თქვენ უფლება გაქვთ:</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მიიღოთ ინფორმაცია თქვენს შესახებ დამუშავებული მონაცემების თაობაზე;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მოითხოვოთ მცდარი, არაზუსტი ან/და არასრული მონაცემების გასწორება,
            განახლება ან/და შევსება;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მოითხოვოთ მონაცემთა დამუშავების შეწყვეტა, წაშლა ან განადგურება, თუ:
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            გაითხოვთ თანხმობას, რომელიც წარმოადგენს მონაცემთა დამუშავების ერთადერთ
            საფუძველს; თანხმობის გათხოვისათვის შეგიძლიათ დაგვიკავშირდეთ საიტზე
            &bdquo;კონტაქტის&ldquo; გრაფაში მითითებულ ელექტრონულ ფოსტაზე;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მონაცემების დამუშავება აღარ არის საჭირო იმ მიზნისთვის, რომლისთვისაც მოხდა
            მათი დამუშავება;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მონაცემთა დამუშავება ხდება უკანონოდ.
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მოითხოვოთ მონაცემთა დაბლოკვა, თუ:
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            სადავოა მონაცემთა ნამდვილობა ან სიზუსტე;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მონაცემთა დამუშავება უკანონოა, თუმცა თქვენ ეწინააღმდეგებით მათ წაშლას და
            სანაცვლოდ ითხოვთ დაბლოკვას;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მონაცემები აღარ არის საჭირო მათი დამუშავების მიზნის მისაღწევად, თუმცა
            თქვენ ისინი გესაჭიროებათ სამართალწარმოების მიზნით;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            მიმდინარეობს მონაცემთა დამუშავების შეწყვეტის, წაშლის ან განადგურების
            მოთხოვნის განხილვა;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            ნებისმიერ დროს გამოითხოვოთ თანხმობა, რომლითაც მოგვანიჭეთ თქვენი მონაცემის
            დამუშავების უფლება. ასეთ შემთხვევაში ჩვენ დამუშავებას შევწყვეტთ, თუ არ
            არსებობს მონაცემთა დამუშავების კანონმდებლობით გათვალისწინებული სხვა
            საფუძველი;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            წარადგინოთ პრეტენზია დამუშავებასთან დაკავშირებით, მათ შორის სახელმწიფო
            ინსპექტორის სამსახურში ან სასამართლოში;
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>თქვენი უფლებების შეზღუდვა:</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            თქვენი უფლებები შეიძლება შეიზღუდოს, თუ ეს პირდაპირ გათვალისწინებულია
            კანონით, ან ამ უფლებების რეალიზაციამ შეიძლება საფრთხე შეუქმნას
            ქვეყნისთვის მნიშვნელოვან ფინანსურ ან ეკონომიკურ ინტერესებს;
            ზედამხედველობის განმახორციელებელი ორგანოების ფუნქციათა და
            უფლებამოსილებათა განხორციელებას; სახელმწიფო, კომერციული, პროფესიული და
            კანონით გათვალისწინებული სხვა სახის საიდუმლოებების დაცვას.
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            9. ცვლილებები პერსონალურ მონაცემთა დაცვის პოლიტიკაში
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            დროთა განმავლობაში შესაძლებელია საჭირო გახდეს ჩვენი პერსონალურ მონაცემთა
            დაცვის პოლიტიკის შეცვლა. ცვლილებების შეტანის შემთხვევაში, ჩვენ
            გამოვაქვეყნებთ ამ ცვლილებებს ჩვენს ვებ-გვერდებზე და მივუთითებთ
            წინამდებარე პოლიტიკის ცვლილების თარიღს. მოდიფიცირებული პერსონალურ
            მონაცემთა დაცვის პოლიტიკა ძალაში შედის გვერდზე გამოქვეყნების შემდეგ.
            თქვენი პასუხისმგებლობაა, რომ პერიოდულად ეწვიოთ ჩვენს ვებგვერდს და
            ცვლილების შემთხვევაში გაეცნოთ შეცვლილ პოლიტიკას. პოლიტიკაში ცვლილებების
            შეტანის შემდეგ ჩვენთვის თქვენს მიერ ინფორმაციის მოწოდება ან ჩვენი
            ვებგვერდებით სარგებლობა ნიშნავს, რომ თქვენ ეთანხმებით ცვლილებებს.
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span
            style={{
              color: "#010101",
              "background-color": "#ffffff",
              "font-size": "10pt",
            }}
          >
            კონფიდენციალურობის წინამდებარე შეტყობინება ბოლოს გადაიხედა და განახლდა:
            27/09/2023
          </span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>10. გამოყენების პირობები</span>
        </p>
        <p>
          <span style={{ "font-size": "10.5pt" }}>
            თქვენს მიერ ჩვენი გვერდის გამოყენება (გარდა იმ შემთხვევისა, თუ გვერდზე
            ოპერირებთ, როგორც სტუმარი) ნიშნავს, რომ თანახმა ხართ ჩვენს მიერ თქვენი
            პერსონალური მონაცემების, მათ შორის მზა ჩანაწერების გამოყენებაზე.
            პერსონალურ მონაცემთა დაცვის პოლიტიკა წარმოადგენს ვებ-გვერდის პირობების
            განუყოფელ ნაწილს.
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            <br />
          </span>
          <span style={{ "font-size": "10.5pt" }}>11.კავშირი ჩვენთან</span>
        </p>
        <p>
          <span
            style={{
              color: "#010101",
              "background-color": "#ffffff",
              "font-size": "10pt",
            }}
          >
            თუ თქვენ გაქვთ რაიმე შეკითხვა წინამდებარე შეტყობინებასთან დაკავშირებით ან
            გსურთ მიიღოთ დამატებითი ინფორმაცია თქვენი უფლებების შესახებ,
            გთხოვთ,&nbsp;
          </span>
          <span style={{ "font-size": "10.5pt" }}>
            დაგვიკავშირდით ელექტრონული ფოსტის საშუალებით: info@addme.ge-ზე.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ "list-style-type": "none", "font-size": "10.5pt" }}>
            <br />
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    </>
  );
};

export default Terms;
