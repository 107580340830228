import { Outlet, Link, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import React from "react";
import Hero from "../../components/landing/hero";
import Share from "../../components/share/share";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const LayoutProfile = ({ isUserPage, isAuthorized }) => {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="container-xxl position-relative p-0">
      <Outlet />
    </div>
  );
};

export default LayoutProfile;
