// import Swiper core and required modules
import { useState, useEffect } from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ScreenShot1 from "../../assets/img/Layer1.jpeg";
import ScreenShot2 from "../../assets/img/Layer2.png";
import ScreenShot3 from "../../assets/img/Layer3.png";
import ScreenShot4 from "../../assets/img/Layer4.png";
import ScreenShot5 from "../../assets/img/Layer5.png";

import "./carousel.css";
import "swiper/css/bundle";
import "swiper/css/pagination";

const Carousel = () => {
  return (
    <div className="d-flex flex-row position-relative gap-5 mx-5 ">
      <div className="mockup">
        <Swiper
          spaceBetween={2}
          speed={700}
          // centeredSlides={true}
          // loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          threshold={2}
          touchRatio={1.8}
          pagination={{
            clickable: true,

            renderBullet: function (_, className) {
              return `<span class= "${className}"> </span>`;
            },
          }}
          b
          modules={[Autoplay, Pagination]}
          className="mySwiper rounded-5"
        >
          <SwiperSlide className="d-flex flex-row justify-content-center align-items-center h-100  rounded-5    ">
            <img
              src={ScreenShot1}
              alt=""
              className="object-fit-cover rounded-5 carouse_img"
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-row justify-content-center align-items-center h-100  rounded-5    ">
            <img
              src={ScreenShot2}
              alt=""
              className="object-fit-cover rounded-5 carouse_img"
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-row justify-content-center align-items-center h-100  rounded-5 ">
            <img
              src={ScreenShot3}
              alt=""
              className="object-fit-cover rounded-5 carouse_img "
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-row justify-content-center align-items-center h-100  rounded-5 ">
            <img
              src={ScreenShot4}
              alt=""
              className="object-fit-cover rounded-5 carouse_img "
            />
          </SwiperSlide>
          <SwiperSlide className="d-flex flex-row justify-content-center align-items-center h-100  rounded-5 ">
            <img
              src={ScreenShot5}
              alt=""
              className="object-fit-cover rounded-5 carouse_img "
            />
          </SwiperSlide>
          {/* <div
            className="swiper-pagination position-absolute top-50 "
            style={{
              left: "320px",
              backgroundColor: "red",
            }}
          ></div> */}
        </Swiper>
      </div>
    </div>
  );
};
export default Carousel;
