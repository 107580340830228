import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
  open: false,
};

const toastNotificationSlice = createSlice({
  name: "toastNotification",
  initialState,
  reducers: {
    setToastNotification: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.open = true;
    },
    resetToastNotification: state => {
      state.message = "";
      state.type = "";
      state.open = false;
    },
  },
});

export const { setToastNotification, resetToastNotification } =
  toastNotificationSlice.actions;

export default toastNotificationSlice.reducer;
