import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "../../App.css";

const ModalComponent = ({
  desc,
  title,
  show,
  handleModal,
  showFooter = true,
  showCancelButton = true,
  showConfirmButton = true,
  showTextField = false,
  placeholder,
  func,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  return (
    <>
      <Modal
        show={show}
        onHide={handleModal}
        className=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className="fs-5">{title}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showTextField ? (
            <>
              <p className="small">{desc}</p>
              <textarea
                className="form-control"
                rows="4" // Adjust the number of rows as needed
                placeholder={placeholder}
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
            </>
          ) : (
            <>
              <p className="small">{desc}</p>
            </>
          )}
        </Modal.Body>
        {showFooter && (
          <Modal.Footer className="d-flex justify-content-end gap-3 align-items-center">
            {showCancelButton && (
              <button
                onClick={handleModal}
                style={{
                  backgroundColor: "rgb(122 74 201) ",
                  border: "none",
                }}
                className="btn deleteButton rounded-3"
              >
                {t("cancel")}
              </button>
            )}
            {showConfirmButton &&
              (showTextField ? (
                <button
                  onClick={() => {
                    func(message);
                  }}
                  className="btn btn-primary-gradient"
                >
                  {t("confirm")}
                </button>
              ) : (
                <button
                  onClick={() => {
                    func();
                  }}
                  className="btn btn-primary-gradient"
                >
                  {t("confirm")}
                </button>
              ))}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ModalComponent;
