import { useParams } from "react-router-dom";

import useAsyncCall from "../../hooks/useAsyncCall";
import { useEffect, useState } from "react";
export default function NavigateQR() {
  const { id } = useParams();
  console.log(id);
  const [error, setError] = useState(false);
  const navigateToURL = useAsyncCall();
  useEffect(() => {
    navigateToURL.request("get", `/User/GetCustomQRLink/${id}`).then(e => {
      if (e.success) {
        window.location.href = e.data;
        setError(false);
      }
      if (e.errorCode === 3) {
        setError(true);
      }
    });
  }, []);
  return <div>NavigateQR</div>;
}
