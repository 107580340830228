import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar/navbar";
import Flag from "react-world-flags";
import { useLazyCustomQRAnalyticsQuery } from "../../Redux/services/AnalyticsApi";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import analyticsIcon from "../../assets/newIcons/16.svg";

import { Link } from "react-router-dom";

function QRAnalytics({ qr, showMargin = true, showHeader = true }) {
  const { token, selectedUserId } = useSelector(state => state.user);

  const decodedToken = jwtDecode(token);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { t } = useTranslation();
  const { guid } = useParams();
  const [fetch, { data: analyticsData }] = useLazyCustomQRAnalyticsQuery();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let url = `/Analytics/CustomQRViewAnalytics/${guid}`;

      // Format start and end dates
      const formattedStartDate = startDate
        .replace("T", " ")
        .replace(/-/g, "/")
        .slice(0, 16);
      const formattedEndDate = endDate
        .replace("T", " ")
        .replace(/-/g, "/")
        .slice(0, 16);

      url += `?start=${formattedStartDate}&end=${formattedEndDate}`;
      url += `&start=${formattedStartDate}&end=${formattedEndDate}`;
      if (guid) {
        fetch({ guid, startDate, endDate });
        return;
      } else {
        fetch({ guid: qr.guid, startDate, endDate });
      }

      // const response = await getAnalytics.request("get", url);
      // setData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterClick = () => {
    fetchData();
  };
  useEffect(() => {
    const currentUTC = new Date();
    currentUTC.setUTCHours(currentUTC.getUTCHours() + 4);

    const formattedStartDate = new Date(currentUTC.getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16);

    const formattedEndDate = currentUTC.toISOString().slice(0, 16);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    const button = document.getElementById("init");
    const clickButton = () => {
      if (button) {
        button.click();
      }
    };
    const timeoutId = setTimeout(clickButton, 100);
    fetchData({ guid: qr?.guid, startDate, endDate });
  }, [endDate, qr?.guid, startDate]);

  return (
    <>
      {showHeader ? (
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
      ) : (
        ""
      )}
      <div
        style={{ marginTop: showMargin ? "100px" : "0" }}
        className="container-xxl"
      >
        {qr?.name ? (
          <div className="d-flex gap-2 align-items-center mb-3">
            <img
              src={analyticsIcon}
              alt="analytics"
              width="50"
              height="50"
            />
            <h1 className="mb-0">
              QR {qr.name} {t("analytics")}{" "}
            </h1>
          </div>
        ) : (
          <h1>{t("customQRAnalytics")}</h1>
        )}
        <div className="d-flex  flex-column gap-2">
          <div className="d-flex flex-column gap-3">
            <label>{t("startDate")}: </label>
            <input
              type="datetime-local"
              value={startDate}
              className="border-primary-gradient rounded-4 border-2 p-1"
              onChange={e => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column gap-3">
            <label>{t("endDate")}: </label>
            <input
              type="datetime-local"
              value={endDate}
              className="border-primary-gradient rounded-4 border-2 p-1"
              onChange={e => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column justify-content-end">
            <button
              id="init"
              onClick={handleFilterClick}
              className="btn btn-primary-gradient"
            >
              {t("filter")}
            </button>
          </div>
        </div>
        {isLoading && <p>Loading...</p>}

        {analyticsData?.data && (
          <div className="mt-5">
            <h2 className="fs-5">
              {t("ViewsintheSelectedDateRange")}:
              {analyticsData?.data?.totalViewCount}
            </h2>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("ViewsbyCountries")}</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {analyticsData?.data?.countryViews.map(country => (
                  <tr key={country}>
                    <td
                      key={country.countryName}
                      className="w-75"
                    >
                      <Flag
                        code={country.countryCode}
                        style={{
                          width: "30px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />

                      {country.countryName}
                    </td>
                    <td className="w-25">
                      {country.viewCount} {t("views")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr className="w-100">
                  <th>{t("ViewsbyCities")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {analyticsData?.data?.cityViews.map(city => (
                  <tr className="w-100">
                    <td
                      key={city.cityName}
                      className="w-75"
                    >
                      {city.cityName}
                    </td>
                    <td className="w-25">
                      {city.viewCount} {t("views")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {decodedToken?.Role === "SuperAdmin" ? (
          <Link
            // onClick={() => dispatch(unSelectUserId())}
            to={`/admin/users/${selectedUserId}`}
            className="btn btn-primary-gradient"
          >
            Back To User Page
          </Link>
        ) : decodedToken?.Role === "Admin" ? (
          <Link
            // onClick={() => dispatch(unSelectUserId())}
            to={`/admin/users/${selectedUserId}`}
            className="btn btn-primary-gradient"
          >
            Back To User Page
          </Link>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default QRAnalytics;
