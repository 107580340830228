import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import config from '../../config/config';
import ka from './translationKA.json';
import en from './translationEN.json';

const isLocal = window.location.href.includes("localhost")

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        lng:  localStorage.getItem('language'),
        // backend: {
        //     ...(isLocal ? {loadPath: `${config.BASE_URL}/api/Language/GetTranslations?language={{lng}}`}
        //         : {loadPath: `${config.BASE_URL}/api/Language/GetTranslations?language={{lng}}`}),
        //     parse: function (data) {
        //         const obj = JSON.parse(data)
        //         return obj.data
        //     },
        // }
        resources: {
            en: {
                translation: en
            },
            ka: {
                translation: ka
            }
        }
        //backend: backendOptions
    });


export default i18n;