import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar/navbar";

import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";

import {
  useGetAdminsQuery,
  useCreateAdminMutation,
  useDeleteAdminMutation,
} from "../../Redux/services/AdminApi";
import {
  useGetUsernamesQuery,
  useApproveCardUsernameMutation,
} from "../../Redux/services/CardApi";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

export default function Admin() {
  const [adminValue, setAdminValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    isSuperAdmin: false,
  });

  const { token } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const decodedToken = token ? jwtDecode(token) : null;
  const isSuperAdmin = decodedToken?.Role === "SuperAdmin";
  const { data: adminsData, isLoading: adminLoading } = useGetAdminsQuery();
  const [createAdmin] = useCreateAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();
  const { data: usernameData, refetch: refetchUsernames } = useGetUsernamesQuery();
  const [approveUsername] = useApproveCardUsernameMutation();

  const [showUsernamesModal, setShowUsernamesModal] = useState(false);
  const [showApproveAdminDeleteModal, setShowApproveAdminDeleteModal] =
    useState(false);

  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [type, setType] = useState("password");
  const { t } = useTranslation();

  const handleAdminInputValue = e => {
    setAdminValue(prev => ({ ...prev, [e.target.id]: e.target.value }));

    if (e.target.type === "checkbox") {
      setAdminValue(prev => ({ ...prev, [e.target.id]: e.target.checked }));
    }
  };

  const handleCreateAdmin = async () => {
    const response = await createAdmin(adminValue);
    try {
      if (response.data.success) {
        if (adminValue.isSuperAdmin) {
          dispatch(
            setToastNotification({
              message: "Super Admin Created Successfully",
              type: "success",
            })
          );
        } else {
          dispatch(
            setToastNotification({
              message: "Admin Created Successfully",
              type: "success",
            })
          );
        }
        setAdminValue({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
        });
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "something went wrong",
          type: "error",
        })
      );
      console.log(error.response.data.message, "error");
    }
  };

  const handleDeleteAdmin = async admin => {
    const response = await deleteAdmin(admin.id);
    try {
      if (response.data.success) {
        if (admin.isSuperAdmin) {
          dispatch(
            setToastNotification({
              message: "Super Admin deleted success",
              type: "success",
            })
          );
        } else {
          dispatch(
            setToastNotification({
              message: "Admin deleted success",
              type: "success",
            })
          );
        }
        setShowApproveAdminDeleteModal(false);
        setSelectedAdmin(null);
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleApproveUsername = async id => {
    const response = await approveUsername(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("usernameApproved"),
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "something went wrong",
          type: "error",
        })
      );
    }
  };
  const handleOpenUsernameModal = () => {
    refetchUsernames();
    setShowUsernamesModal(true);
  };

  const toggleType = () => {
    setType(type === "password" ? "text" : "password");
  };

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <h1 className="mt-lg-5 pt-lg-5 ">Admin</h1>

      <div className=" col-12 align-items-center d-flex justify-content-center mb-lg-2 mb-3 ">
        <Link
          to="/admin/users"
          className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
        >
          {t("usersButton")}
        </Link>
      </div>
      <div className=" col-12 align-items-center d-flex justify-content-center mb-lg-2 mb-3 ">
        <button
          onClick={handleOpenUsernameModal}
          className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
        >
          Usernames
        </button>
      </div>
      <div className=" col-12 align-items-center d-flex justify-content-center mb-lg-2 mb-3 ">
        <Link
          to={"/admin/PricingTier"}
          className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
        >
          Create Pricing Tier
        </Link>
      </div>
      <div className=" col-12 align-items-center d-flex justify-content-center mb-lg-2 mb-3 ">
        <Link
          to={"/admin/subscription"}
          className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
        >
          Go To Subscription Page
        </Link>
      </div>
      <Modal
        onHide={() => setShowUsernamesModal(false)}
        show={showUsernamesModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3 row">
            <div className="d-flex  gap-3 ">
              <h6 className="small">Username</h6>
            </div>
            {usernameData?.data?.map(username => (
              <div className="d-flex flex-column gap-3 col-6">
                <h6 className="mb-0 small">{username?.email}</h6>
                <h6 className="mb-0 small">
                  {username?.userName} wants to change username to{" "}
                  <strong>{username.newUserName}</strong>
                </h6>
                <a
                  href={username?.profile}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-0 small"
                >
                  {username?.profile}
                </a>
                <button
                  onClick={() => handleApproveUsername(username?.id)}
                  className="btn btn-success small"
                >
                  Approve
                </button>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      {isSuperAdmin && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First Name"
                  value={adminValue.firstname}
                  onChange={e => handleAdminInputValue(e)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last Name"
                  value={adminValue.lastname}
                  onChange={e => handleAdminInputValue(e)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  value={adminValue.email}
                  onChange={e => handleAdminInputValue(e)}
                />
              </div>
              <div className="form-group row">
                <div className="col-10">
                  <label htmlFor="password">Password</label>
                  <input
                    type={type}
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={adminValue.password}
                    onChange={e => handleAdminInputValue(e)}
                  />
                </div>
                <div className="col-2 d-flex justify-content-center align-items-end">
                  {type === "password" ? (
                    <span
                      onClick={toggleType}
                      style={{ cursor: "pointer" }}
                      className="text-primary p-2 border border-primary rounded-3"
                    >
                      Show Password
                    </span>
                  ) : (
                    <span
                      onClick={toggleType}
                      style={{ cursor: "pointer" }}
                      className="text-primary p-2 border border-primary rounded-3"
                    >
                      Hide Password
                    </span>
                  )}
                </div>
              </div>
              <div class="form-check form-switch mt-3 ">
                <label
                  className="form-check-label"
                  htmlFor="isSuperAdmin"
                >
                  Super Admin
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isSuperAdmin"
                  name="isSuperAdmin"
                  onChange={e => handleAdminInputValue(e)}
                  value={adminValue.isSuperAdmin}
                  checked={adminValue.isSuperAdmin}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary mb-3"
                onClick={() => {
                  handleCreateAdmin();
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2>Admins</h2>
              {adminLoading ? (
                <div className="container d-flex justify-content-center ">
                  <div
                    className="spinner-border "
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminsData?.data?.map((admin, index) => (
                      <tr key={index}>
                        <td>{admin?.firstname}</td>
                        <td>{admin?.lastname}</td>
                        <td>{admin?.email}</td>
                        <td>{admin?.isSuperAdmin ? "Super Admin" : "Admin"}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setShowApproveAdminDeleteModal(true);
                              setSelectedAdmin(admin);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}

      <Modal
        onHide={() => setShowApproveAdminDeleteModal(false)}
        show={showApproveAdminDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3 ">
            <div className="d-flex flex-column gap-1 ">
              <h6 className="small">Are you sure you want to delete</h6>
              <h6 className="small fw-bold">{selectedAdmin?.email}</h6>
            </div>
            <div className="d-flex  gap-3 ">
              <button
                onClick={() => handleDeleteAdmin(selectedAdmin)}
                className="btn btn-success small"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowApproveAdminDeleteModal(false);
                  setSelectedAdmin(null);
                }}
                className="btn btn-danger small"
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
