import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

export default function AdminRoute({ children }) {
  const { token } = useSelector(state => state.user);
  const decodedToken = token ? jwtDecode(token) : null;
  const admin = decodedToken?.Role === "Admin";
  const superAdmin = decodedToken?.Role === "SuperAdmin";

  return admin | superAdmin ? children : <Navigate to="/auth/signin" />;
}
