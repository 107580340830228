import { useSelector, useDispatch } from "react-redux";
import * as React from "react";
import { Modal } from "react-bootstrap";
export default function EventAuthUser() {
  const { users } = useSelector(state => state.user);
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <div className="d-flex">
      {users?.length >= 5 ? (
        <div
          onClick={() => setOpenModal(true)}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            backgroundColor: "blue",
            cursor: "pointer",
          }}
        >
          <p>{users?.length}+ users</p>
        </div>
      ) : (
        <>
          {users.map(user => (
            <div key={user.email}>
              <img
                style={{ width: "50px", height: "50px" }}
                src={user.avatar}
                alt="avatar"
              />
              <p>{user.email}</p>
            </div>
          ))}
        </>
      )}
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          {users.map(user => (
            <div
              key={user.email}
              className="col-3 d-flex flex-column align-items-center"
            >
              <img
                style={{ width: "50px", height: "50px" }}
                src={user.avatar}
                alt="avatar"
              />
              <p>{user.email}</p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setOpenModal(false)}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
