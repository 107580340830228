import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// Define the API slice using the base query
export const subscribeApi = createApi({
  reducerPath: "subscribeApi",
  baseQuery: baseQuery,
  tagTypes: ["Subscribers"],
  endpoints: builder => ({
    getSubscribers: builder.query({
      query: () => ({
        url: "/api/Subscribe/GetSubscribers",
        method: "GET",
      }),
      providesTags: ["Subscribers"],
    }),
    addSubscriber: builder.mutation({
      query: str => ({
        url: `/api/Subscribe/AddSubscriber?subscriber=${str}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Subscribers"],
    }),
    removeSubscriber: builder.mutation({
      query: id => ({
        url: `/api/Subscribe/RemoveSubscriber?subscriber=${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Subscribers"],
    }),
  }),
});

export const {
  useGetSubscribersQuery,
  useAddSubscriberMutation,
  useRemoveSubscriberMutation,
} = subscribeApi;
