import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config/config";
import { setCards } from "../slices/UserSlice";

// Base query configuration with token from localStorage
const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `${token}`);
    }

    return headers;
  },
});

// Define the API slice using the base query
export const cardApi = createApi({
  reducerPath: "cardApi",
  baseQuery: baseQuery,
  tagTypes: ["Card", "Usernames"],
  endpoints: builder => ({
    changeUsername: builder.mutation({
      query: ({ cardId, name }) => ({
        url: `/api/Card/ChangeUserName?cardId=${cardId}&newUsername=${name}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Card"],
    }),
    getUsernames: builder.query({
      query: () => ({
        url: `/api/Card/ChangeUserNameRequests`,
        method: "GET",
      }),
      providesTags: ["Usernames"],
    }),

    getCards: builder.query({
      query: () => ({
        url: "api/card/getCards",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            dispatch(setCards(data.data));
            console.log("Successfully fetched cards");
          } else {
            console.log("Failed to fetch cards");
          }
          // Dispatch the action to update the global state
        } catch (error) {
          console.log("Something went wrong");
          // Handle any errors
          // აქ ტოსტს გამოვიძახებ
        }
      },

      providesTags: ["Card"],
    }),
    updateCard: builder.mutation({
      query: data => ({
        url: "api/card/updateCard",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Card"],
    }),
    createCard: builder.mutation({
      query: card => ({
        url: `api/card/createCard/${card}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Card"],
    }),
    deleteCard: builder.mutation({
      query: id => ({
        url: `/api/Card/DeleteCard/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Card"],
    }),
    addCardInputItem: builder.mutation({
      query: ({ id, item }) => {
        return {
          url: `/api/Card/AddCardItem?cardId=${id}&cardItemName=${item.name}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Card"],
    }),
    deleteCardInputItem: builder.mutation({
      query: id => ({
        url: `/api/Card/DeleteCardItem?cardItemId=${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Card"],
    }),
    getCardQRs: builder.query({
      query: activeCardId => ({
        url: `/api/Card/GetCardQRs?cardId=${activeCardId}`,
        method: "GET",
      }),
    }),
    getUserQRCodes: builder.query({
      query: id => ({
        url: `/api/Card/GetCardQRs?cardId=${id}`,
        method: "GET",
      }),
    }),
    getCard: builder.query({
      query: username => ({
        url: `/api/Card/GetCard/${username}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "GET",
      }),
      invalidatesTags: ["Card"],
    }),
    getCardUsernames: builder.query({
      query: () => ({
        url: `/api/Card/ChangeUserNameRequests`,
        method: "GET",
      }),
      providesTags: ["Usernames"],
    }),
    approveCardUsername: builder.mutation({
      query: id => ({
        url: `/api/Card/ApproveUsername?Id=${id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Usernames"],
    }),
  }),
});

export const {
  useGetCardsQuery,
  useUpdateCardMutation,
  useCreateCardMutation,
  useDeleteCardMutation,
  useAddCardInputItemMutation,
  useDeleteCardInputItemMutation,
  useChangeUsernameMutation,
  useGetCardQRsQuery,
  useGetUserQRCodesQuery,
  useGetCardQuery,
  useLazyGetCardQuery,
  useGetCardUsernamesQuery,
  useApproveCardUsernameMutation,
  useGetUsernamesQuery,
} = cardApi;
