import React, { useEffect, useState } from "react";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar/navbar";
import { useLazyGetLoginsQuery } from "../../Redux/services/AnalyticsApi";

import { useParams, Link } from "react-router-dom";

import { useSelector } from "react-redux";

const Logins = () => {
  const { userName } = useParams();

  const [trigger, { data: logins, isSuccess }] = useLazyGetLoginsQuery();
  const selectedUserId = useSelector(state => state.user.selectedUserId);
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const getLogins = useAsyncCall();

  useEffect(() => {
    // getLogins
    //   .request("get", `/analytics/getlogins?userName=${userName}`)
    //   .then(response => {
    //     setData(response.data);
    //     setIsLoading(false);
    //   });
    trigger(userName);
  }, [trigger, userName]);

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container mt-lg-5 ">
        <div className="row justify-content-center pt-lg-5 ">
          <div className="d-flex flex-column gap-3 mb-3">
            <h1>UTC+4 Tbilisi</h1>
            <Link
              to={`/admin/users/${selectedUserId}`}
              className="btn btn-primary-gradient w-25 d-md-block d-none"
            >
              Back To User Page
            </Link>
            <Link
              to={`/admin/users/${selectedUserId}`}
              className="btn btn-primary-gradient  d-md-none d-block"
            >
              Back To User Page
            </Link>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th
                      scope="col"
                      style={{ width: "50px" }}
                    >
                      Username
                    </th>
                    <th
                      scope="col"
                      style={{ width: "50px" }}
                    >
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isSuccess &&
                    logins?.data?.map((user, index) => (
                      <tr key={index}>
                        <td>{user.userName}</td>
                        <td>{user.time}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logins;
