import { readFile } from "../../helpers/cropImage";
import { useImageCropContext } from "../../providers/imageCropProvider";
import Button from "../../components/image/base/button";
import Cropper from "../../components/image/cropper/cropper";
import { RotationSlider, ZoomSlider } from "./cropper/sliders";

const ImageCropModalContent = ({ handleDone, handleClose, id }) => {
  const { setImage } = useImageCropContext();

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  return (
    <div className="text-center relative">
      <h5 className="text-gray-800 mb-4">Edit profile picture</h5>
      <div className="border border-dashed border-gray-200 p-5 rounded-lg">
        <div className="d-flex justify-content-center">
          <div className="crop-container mb-4">
            <Cropper />
          </div>
        </div>
        <ZoomSlider className="mb-4" />
        <RotationSlider className="mb-4" />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id={id}
          accept="image/*"
        />

        <Button
          variant="light"
          className="btn btn-secondary btn-block mb-3"
        >
          <label htmlFor={id}>Upload Another Picture</label>
        </Button>
        <div className="d-flex">
          <Button
            variant="secondary"
            className="btn btn-danger mb-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn btn-block btn-primary mb-3 ms-3"
            onClick={handleDone}
          >
            Done & Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModalContent;
