import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "./slices/UserSlice";
import usersSlice from "./slices/UsersSlice";
import toastNotification from "./slices/ToastNotificationSlice";
import { userApi } from "./services/UserApi";
import { authApi } from "./services/AuthApi";
import { adminApi } from "./services/AdminApi";
import { subscribeApi } from "./services/SubscribeApi";
import { analyticsApi } from "./services/AnalyticsApi";
import { cardApi } from "./services/CardApi";
import { pricingPlanApi } from "./services/PricingPlanApi";
import { shortURLApi } from "./services/ShortURLApi";

const persistConfig = {
  key: "user",
  storage,
  version: 1,
  whitelist: ["token", "isAuthorized", "user", "selectedCard"],
};
const apiReducers = {
  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [subscribeApi.reducerPath]: subscribeApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [cardApi.reducerPath]: cardApi.reducer,
  [pricingPlanApi.reducerPath]: pricingPlanApi.reducer,
  [shortURLApi.reducerPath]: shortURLApi.reducer,
};

const rootReducer = combineReducers({
  user: persistReducer(persistConfig, userSlice),
  users: usersSlice,
  toast: toastNotification,
  ...apiReducers,
});

const apiMiddlewares = [
  userApi.middleware,
  authApi.middleware,
  adminApi.middleware,
  subscribeApi.middleware,
  analyticsApi.middleware,
  cardApi.middleware,
  pricingPlanApi.middleware,
  shortURLApi.middleware,
];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...apiMiddlewares),
});

export const persistor = persistStore(store);
