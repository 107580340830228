import Navbar from "../navbar/navbar";
import { useTranslation } from "react-i18next";
import ContainerBasicSettings from "./ContainerBasicSettings";
import Container from "./Container";

export default function CardBuilder() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="mt-5 pt-5  row ">
        <div className="d-flex flex-column border border-2 border-danger col-8">
          <Container />
        </div>
        <div className="d-flex flex-column border border-2 border-success col-4">
          <ContainerBasicSettings />
        </div>
      </div>
    </>
  );
}
