import { Link } from "react-router-dom";

export default function UserShortURLS({ userData }) {
  return (
    <>
      {userData?.data?.shortUrls?.map(shortUrl => (
        <div
          className="card col-4 p-0 mb-3 ms-3 "
          key={shortUrl.id}
        >
          <div className="card-header  ">
            <h3>{shortUrl.name}</h3>
          </div>
          <div className="card-body">
            <p>Short Url : {shortUrl.name}</p>
            <Link
              to={shortUrl?.original}
            >{`${window.location.origin}/${shortUrl.value}`}</Link>
          </div>
          <div className=" col-12 align-items-center d-flex justify-content-md-start justify-content-center  mb-3 ">
            <Link
              to={`/shorturlanalytics/${shortUrl.guid}`}
              className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
            >
              Analytics
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}
