import Hero from "../../components/landing/hero";
import { useTranslation } from "react-i18next";
import Features from "../../components/landing/features";
import About from "../../components/landing/about";
import Screenshots from "../../components/landing/screenshots";
import Process from "../../components/landing/process";
import Pricing from "../../components/landing/pricing";
import Testimonials from "../../components/landing/testimonials";
import Contact from "../../components/landing/contact";
import Footer from "../../components/footer/footer";
import WOW from "wow.js";

import { useEffect } from "react";

const Landing = () => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <div className="container-xxl position-relative p-0">
      <Hero />
      <About />
      <Features />
      <Screenshots />
      <Process />
      <Pricing />
      {/* <Testimonials /> */}
      <Contact />
    </div>
  );
};

export default Landing;
