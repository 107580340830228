import React, { useEffect, useState } from "react";
import useAsyncCall from "../../hooks/useAsyncCall";

import { useNavigate, useParams } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
  FaSquareInstagram,
} from "react-icons/fa6";
import { FaTelegram, FaPhone } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import LogoTradersHub from "../../assets/Tradershub_poster/LogoTradersHub.png";
import AddmeLogo from "../../assets/logoUpdated.png";
import AddmeWaveLogoWhite from "../../assets/addmewavelogowhite.png";
import TradersHubPoster from "../../assets/Tradershub_poster/tradershubPooster.jpg";
import Aiden from "../../assets/Tradershub_poster/AidenCrech.jpeg";
import Anastasia from "../../assets/Tradershub_poster/Anastasia.jpeg";
import Giorgi from "../../assets/Tradershub_poster/GiorgiMeskhi.jpeg";
import Ricardo from "../../assets/Tradershub_poster/RicardoAlves-11.jpeg";
import Viktoria from "../../assets/Tradershub_poster/ViktoriaZhorova1.jpeg";
import Rati from "../../assets/Tradershub_poster/Rati2-1.jpeg";
import Vakhtang from "../../assets/Tradershub_poster/vakhtang.jpg";
import Keti from "../../assets/Tradershub_poster/Keti.jpg";
import "../../Fonts/fonts.css";
import Share from "../share/share";
import { useTranslation } from "react-i18next";
import LanguagePicker from "../selectors/languagePicker";
import Modal from "../eventRegistrationModal/Modal";
import EventAuthUser from "../eventAuthorizedUsers/EventAuthUser";

const contactInfo = [
  {
    icon: <FaFacebook />,
    link: "https://www.facebook.com/tradershub.ge",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },

  {
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/company/%E1%83%A2%E1%83%A0%E1%83%94%E1%83%98%E1%83%93%E1%83%94%E1%83%A0%E1%83%94%E1%83%91%E1%83%98%E1%83%A1-%E1%83%B0%E1%83%90%E1%83%91%E1%83%98-%E2%80%A2-traders-hub/",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaSquareInstagram />,
    link: "https://www.instagram.com/tradershub.ge/",
    className: "px-1",
    style: {
      // fontSize: "20px",
      width: "32px",
      height: "32px",
      color: "black",
      borderRadius: "50%",
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaTelegram />,
    link: "https://t.me/tradershubge",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <TbWorldWww />,
    link: "https://tradershub.ge/",
    className: "bg-white",
    style: {
      width: "32px",
      height: "32px",
      color: "#000",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },

  {
    icon: <MdOutlineMailOutline />,
    link: "mailto:rati@kiria.ge",
    className: "bg-white",
    style: {
      width: "32px",
      height: "32px",
      color: "#000",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaPhone />,
    link: "tel:+995550000899",
    className: "bg-white",
    style: {
      width: "32px",
      height: "32px",
      color: "#000",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaTiktok />,
    link: "https://www.tiktok.com/@tradershub.ge",
    className: "bg-black",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
];

export default function TradersHub({ isUserPage }) {
  const username = window.location.pathname.split("/")[1].toLocaleLowerCase();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [card, setCard] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getCard = useAsyncCall();

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${username}`);
  };

  useEffect(() => {
    const checkIsCardPage = async () => {
      getCard
        .request("get", `/card/getcard/${username}`)
        .then(e => {
          setCard(e.data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    };

    checkIsCardPage();
  }, [username]);

  return (
    <div className="d-flex flex-column  justify-content-center  text-center  ">
      <div
        className="d-flex flex-column  "
        style={{
          fontFamily: "georgian_normal",
          backgroundColor: "#014d75",
        }}
      >
        <header
          className="d-flex flex-row gap-1  mx-3 mt-2   align-items-md-center position-fixed  "
          style={{
            width: "100vw",
            backgroundColor: "#9D1C1F",
            left: "-16px",
            top: "-8px",
            padding: " 5px",
            boxShadow: "0px 0px 10px 0px #6966EE",
          }}
        >
          <img
            src={LogoTradersHub}
            alt=""
            className="logo ms-2 d-none d-md-block"
            style={{ width: "200px", height: "50px" }}
          />

          <figure className="d-none d-md-flex gap-md-1 align-items-md-center position-relative justify-content-between w-100">
            <div className="position-absolute ">
              <div
                className="position-absolute   align-items-md-center d-md-flex "
                style={{
                  width: "200px",
                  top: "-35px",
                }}
              >
                <a
                  className="text-decoration-none "
                  target="_blank"
                  href="https://addme.ge/"
                  style={{
                    color: "#6966EE",
                  }}
                  rel="noreferrer"
                >
                  Powered by
                  <img
                    src={AddmeLogo}
                    alt=""
                    style={{ width: "80px", height: "60px" }}
                  />
                </a>{" "}
              </div>
            </div>
            <div
              className="position-absolute"
              style={{
                right: "20px",
              }}
            >
              <LanguagePicker />
            </div>
          </figure>

          <img
            className="logo d-md-none ms-2"
            src={LogoTradersHub}
            alt=""
            style={{ width: "90px", height: "30px" }}
          />
          <figure className="d-flex d-md-none gap-1 align-items-center">
            <div className="position-relative">
              <div
                className="position-absolute   align-items-center d-flex "
                style={{
                  width: "200px",
                  top: "-30px",
                }}
              >
                <a
                  className="text-decoration-none "
                  target="_blank"
                  href="https://addme.ge/"
                  style={{
                    color: "#6966EE",
                    fontFamily: "georgian_bold",
                    fontSize: "0.7rem",
                  }}
                  rel="noreferrer"
                >
                  Powered by
                  {/* mobile */}
                  <img
                    src={AddmeLogo}
                    alt=""
                    style={{ width: "75px", height: "65px" }}
                  />
                </a>{" "}
              </div>
            </div>{" "}
            <div
              className="position-absolute"
              style={{
                right: "20px",
                top: "10px",
              }}
            >
              <LanguagePicker />
            </div>
          </figure>
        </header>

        <main className="d-md-flex flex-column justify-content-md-center  mt-4 pt-3 mt-md-5  ">
          {/* Links */}
          <section
            className="d-flex flex-column  justify-content-center align-items-center mb-3 mt-4"
            style={{
              // width: "100vw",
              fontSize: "0.8rem",
              fontFamily: "georgian_bold",
            }}
          >
            <span className="text-center   text-black fw-bold text-white">
              {t("tradersand")}
            </span>
            <span
              className="text-center   text-black fw-bold text-white"
              style={{
                height: "12px",
              }}
            >
              {t("instmenstforume")}
            </span>
          </section>
          {/* Banner */}
          <figure className="mx-auto d-flex  justify-content-center my-3 ">
            <img
              src={TradersHubPoster}
              alt=""
              className="d-md-none img-fluid w-75 "
            />
            <img
              src={TradersHubPoster}
              alt=""
              className="d-none d-md-block img-fluid w-50"
            />
          </figure>
          <section className="d-flex gap-3  justify-content-center align-items-center  mt-3 mx-auto ">
            {contactInfo.map((item, index) => (
              <a
                href={item.link}
                target="_blank"
                key={index}
                className={item.className}
                style={item.style}
                rel="noreferrer"
              >
                {item.icon}
              </a>
            ))}
          </section>
          <div className="my-4">
            <a
              href="https://forms.gle/mYoqo3846DPe5PFc8"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-black fs-6  text-white    text-center  py-2 px-4 rounded-3"
              style={{
                border: "2px solid white",
                fontFamily: "georgian_bold",
              }}
            >
              {t("eventRegistration")}
            </a>
          </div>
          {/* Agenda */}
          <section className="container d-flex flex-column justify-content-center align-items-center  gap-2    mt-2 ">
            <div className="d-flex justify-content-center mt-3 ">
              <h1
                className="fs-6 "
                style={{
                  fontFamily: "georgian_bold",
                  color: "#fff",
                }}
              >
                {t("agenda")}
              </h1>
            </div>
            <div className="d-flex flex-column gap-3 justify-content-center align-items-center  text-white">
              <div className="">
                <p className="mb-0">12:00 - 12:30</p>
                <p className="mb-0"> {t("PresentationofTradersHub")}</p>
              </div>
              <div className="">
                <p className="mb-0">12:30 - 13:00</p>
                <p className="mb-0">{t("CompanyDerivpresentation")}</p>
              </div>
              <div className="">
                <p className="mb-0">13:00 - 14:10</p>
                <p className="mb-0">{t("Paneldiscussion")}</p>
              </div>
              <div className="">
                <p className="mb-0">14:10 - 14:30</p>
                <p className="mb-0">{t("Awardceremony")}</p>
              </div>
              <div className="">
                <p className="mb-0">14:30 - 14:55</p>
                <p className="mb-0"> {t("break")} - Coffee Break</p>
              </div>
              <div className="">
                <p className="mb-0">15:00 - 16:00</p>
                <p className="mb-0">{t("Kahootsurvey")}</p>
              </div>
              <div className="">
                <p className="mb-0">16:00 - 17:00</p>

                <p className="mb-0">{t("Simulatedtrading ")}</p>
              </div>
            </div>
          </section>
          {/* Trainers */}
          <section
            className="container d-flex flex-column justify-content-center align-items-center  gap-2    mt-2 "
            style={{
              backgroundColor: "#014d75",
              width: "100vw",
              left: "-60px",
            }}
          >
            <div className="d-flex justify-content-center mt-3 ">
              <h1
                className="fs-6 "
                style={{
                  fontFamily: "georgian_bold",
                  color: "#fff",
                }}
              >
                {t("speakers")}
              </h1>
            </div>

            <figure
              className="row row-cols-1 row-cols-md-3 gap-md-0 gap-3"
              style={{
                fontSize: "0.8rem",
              }}
            >
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column gap-3 justify-content-center  align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Rati}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />

                    <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1 text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        {t("ratikiriaName")}
                      </p>

                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("ratikiriaDesc")}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Vakhtang}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1 text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        {t("vakhtangcharaiaName")}
                      </p>

                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("vakhtangcharaiaDesc")}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Viktoria}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1 text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        Viktoria Zhorova
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {" "}
                        {t("representiveofDerivcom")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Anastasia}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column gap-1 justify-content-center text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        Anastasia Trakhimets
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("representiveofDerivcom")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Ricardo}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column gap-1 justify-content-center text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        Ricardo Alves
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("representiveofDerivcom")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column justify-content-center ">
                    <img
                      src={Aiden}
                      alt=""
                      className="img-fluid  mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column gap-1 justify-content-center text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        Aiden Crech
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("representiveofDerivcom")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto">
                <div className="cols d-flex flex-column gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column">
                    <img
                      src={Giorgi}
                      alt=""
                      className="img-fluid mx-2 mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column gap-1 text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        {t("giorgimeskhiName")}
                      </p>

                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("giorgimeskhiDesc")}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
              <figcaption className="col-3 p-0 mx-auto ">
                <div className="cols d-flex flex-column gap-3 align-items-center rounded-5">
                  <div className="d-flex flex-column">
                    <img
                      src={Keti}
                      alt=""
                      className="img-fluid mx-2 mt-2 align-self-center "
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2 mt-2 d-flex flex-column gap-1 text-white">
                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                        }}
                      >
                        {t("ketiKarseladze")}
                      </p>

                      <p
                        className="mb-0"
                        style={{
                          fontFamily: "georgian_bold",
                          color: "black",
                        }}
                      >
                        {t("ketiKarseladzeDesc")}
                      </p>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </section>

          {/* Offer */}
        </main>
        <footer className="d-flex flex-column gap-3  align-items-center mt-1 mb-4">
          <div
            className="py-1 position-relative align-content-center  d-flex  justify-content-center    text-white  text-decoration-none small rounded-3 mb-md-2   "
            style={{
              backgroundColor: "#6966EE",
              cursor: "pointer",
              paddingLeft: "20px",
              paddingRight: "4px",
            }}
            onClick={copyToClipboard}
          >
            <img
              src={AddmeWaveLogoWhite}
              alt=""
              className="position-absolute "
              style={{ width: "25px", height: "20px", left: "-4px", top: "3px" }}
            />
            <span className="">
              Create Your{" "}
              <span
                style={{
                  fontFamily: "georgian_bold",
                }}
              >
                AddMe
              </span>
            </span>
          </div>
          <div>
            <Modal />
            <EventAuthUser />
          </div>
        </footer>
        <Share
          username={username}
          isUserPage={isUserPage}
        />
      </div>
    </div>
  );
}
