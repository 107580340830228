import classNames from 'classnames';

const Modal = ({ open, children }) => {
    if(open){
        document.body.classList.add('modal-open');
        document.body.classList.add('overflow-hidden');
    }
    else{
        document.body.classList.remove('modal-open');
        document.body.classList.remove('overflow-hidden');
    }
    return (
        <>
        <div className={`modal fade ${open ? 'show' : ''}`} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
            {open && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default Modal;