import React, { useState } from "react";
import LanguagePicker from "../selectors/languagePicker";
import Logo from "../../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import icon from "../../assets/newIcons/1.svg";
import "../../App.css";

import useLogout from "../../hooks/useLogout";
import { setToggleSideBar } from "../../Redux/slices/UserSlice";

export default function Header() {
  const { isAuthorized } = useSelector(state => state.user);
  const [color, setColor] = useState(false);
  const { t } = useTranslation();
  const handleLogOut = useLogout();
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setToggleSideBar());
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 sticky-top  `}
    >
      <Link
        to="/"
        className="navbar-brand p-0 d-lg-none "
      >
        <img
          alt=""
          src={Logo}
          width="120"
          height="23"
          className="d-inline-block align-top"
          id="home"
        />
      </Link>
      <Link
        to="/"
        className="navbar-brand p-0 d-none d-lg-block"
      >
        <img
          alt=""
          src={Logo}
          width="200"
          height="40"
          className="d-inline-block align-top"
          id="home"
        />
      </Link>
      <div className="d-flex gap-3 align-items-center">
        <span
          onClick={handleToggle}
          // className="position-absolute top-0 start-0 p-3 z-3"
        >
          <img
            src={icon}
            alt=""
            style={{ width: "2rem", height: "2rem" }}
          />
        </span>

        <LanguagePicker fontSize={24} />
      </div>

      <div
        className="collapse navbar-collapse navbar-collapse-menu"
        id="navbarCollapse"
      >
        {isAuthorized ? (
          <div className="d-block d-lg-none mt-2 ">
            <Link
              to="/auth/profile/card"
              className="btn btn-primary-gradient rounded-pill py-2 px-3 d-lg-block"
            >
              {t("navbarProfile")}
            </Link>

            <button
              style={{
                marginRight: "15px",
                backgroundColor: "rgb(122 74 201)",
                border: "none",
                color: color ? "black " : "white ",
              }}
              onClick={handleLogOut}
              onMouseEnter={() => {
                setColor(true);
              }}
              onMouseLeave={() => {
                setColor(false);
              }}
              className=" rounded-pill py-2 px-4 ms-3 d-lg-block"
            >
              {t("navbarLogout")}
            </button>
            <LanguagePicker />
          </div>
        ) : (
          <Link
            to="/auth/signin"
            style={{ marginRight: "15px" }}
            className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-3 d-lg-none"
          >
            {t("signInButton")}
          </Link>
        )}
        <div className="navbar-nav mx-auto py-0"></div>
        {isAuthorized ? (
          <div className="d-none d-lg-flex ">
            <Link
              to="/auth/profile/card"
              className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-lg-block"
            >
              {t("navbarProfile")}
            </Link>

            <button
              style={{
                marginRight: "15px",
                backgroundColor: "rgb(122 74 201)",
                border: "none",
                color: color ? "black " : "white ",
              }}
              onClick={handleLogOut}
              onMouseEnter={() => {
                setColor(true);
              }}
              onMouseLeave={() => {
                setColor(false);
              }}
              className="rounded-pill py-2 px-4 ms-3 d-lg-block"
            >
              {t("navbarLogout")}
            </button>
          </div>
        ) : (
          <div className="d-none d-lg-block ">
            <Link
              to="/auth/signin"
              style={{ marginRight: "15px" }}
              className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-lg-block"
            >
              {t("signInButton")}
            </Link>
          </div>
        )}
        <LanguagePicker />
      </div>
    </nav>
  );
}
