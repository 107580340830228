import { useState } from "react";
import Modal from "../../components/image/base/modal";
import { readFile } from "../../helpers/cropImage";
import ImageCropModalContent from "./imageCropModalContent";
import { useImageCropContext } from "../../providers/imageCropProvider";
import { useTranslation } from "react-i18next";
import imageCompression from 'browser-image-compression';

const ImageCrop = ({ image, width, height, classes, showChange, onChange, id }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [preview, setPreview] = useState(image);

  const { getProcessedImage, setImage, resetStates } = useImageCropContext();

  const handleDone = async () => {
    const avatar = await getProcessedImage();

    if (avatar.size / 1024 / 1024 > 2) {
      try {
        // Log the details of the original image
        console.log("Original avatar details:", avatar);
  
        const validImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
        if (!validImageTypes.includes(avatar.type)) {
          alert("Unsupported file type. Please upload a JPEG, PNG, or WebP image.");
          return;
        }
  
        // Compression options
        const options = {
          maxSizeMB: 2, // Ensure the final image size is less than 2MB
          maxWidthOrHeight: 1024, // Resize the image to reduce size
          useWebWorker: true, // Use web workers for better performance
        };
  
        // Compress the image
        const compressedAvatar = await imageCompression(avatar, options);
  
        // Log compressed image details
        console.log("Compressed avatar details:", compressedAvatar);
        console.log("Compressed avatar size:", compressedAvatar.size / 1024 / 1024, "MB");
  
        // If compression fails, compressedAvatar might be undefined or invalid
        if (!compressedAvatar) {
          alert("Compression failed. Please try another image.");
          return;
        }
  
        setPreview(window.URL.createObjectURL(compressedAvatar));
        resetStates();
  
        onChange(compressedAvatar, id);
        setOpenModal(false);
  
      } catch (error) {
        console.error("Compression failed with error:", error);
        alert("Error compressing image: " + error.message);
        return;
      }
    } else {
      try {
        // Log details of the image
        console.log("Image size is within limit. Setting preview:", avatar);
  
        setPreview(window.URL.createObjectURL(avatar));
        resetStates();
  
        onChange(avatar, id);
        setOpenModal(false);
  
      } catch (error) {
        console.error("Error handling image:", error);
      }
    }
  };

  const handleClose = () => {
    resetStates();
    setOpenModal(false);
    // setImage(null);
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];

    if (file) {
      const imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
      setPreview(imageDataUrl);
      setOpenModal(true);
    } else {
      resetStates();
      setImage(null);
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        id={id}
        accept="image/*"
      />
      <label
        htmlFor={id}
        className={`${showChange && "profile-pic position-relative"} ${classes}`}
      >
        <img
          src={preview ?? image}
          height={height}
          width={width}
          className={`object-cover rounded-full h-48 w-48 ${classes}`}
          alt=""
        />
        {showChange && (
          <div className="profilepic-content">
            <span className="profilepic-icon">
              <i className="fas fa-camera"></i>
            </span>
            <span className="profilepic-text w-100 ">{t("uploadImage")}</span>
          </div>
        )}
      </label>

      <Modal
        open={openModal}
        handleClose={handleClose}
      >
        <ImageCropModalContent
          id={id}
          handleDone={handleDone}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
};

export default ImageCrop;
