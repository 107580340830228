import { useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncCall from "../../hooks/useAsyncCall";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const Contact = () => {
  const { t } = useTranslation();
  const methodsOnSubmit = useForm();
  const sendMessage = useAsyncCall();

  const onSubmit = methodsOnSubmit.handleSubmit(data => {
    const formData = methodsOnSubmit.getValues();
    sendMessage.request("post", "/contact/sendmessage", formData).then(e => {
      alert(t("messageSendSuccess"));
    });
    methodsOnSubmit.reset();
  });

  return (
    <FormProvider {...methodsOnSubmit}>
      <div
        className="container-xxl py-5"
        id="contact"
      >
        <div className="container py-5 px-lg-5">
          <div
            className="text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h5 className="text-primary-gradient fw-medium">{t("contactTitle")}</h5>
            <h1 className="mb-5">{t("contactSubTitle")}</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div
                className="wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          {...methodsOnSubmit.register("name", { required: true })}
                        />
                        <label htmlFor="name">{t("contactName")}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          {...methodsOnSubmit.register("mail", { required: true })}
                        />
                        <label htmlFor="email">{t("contactEmail")}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                          {...methodsOnSubmit.register("subject", {
                            required: true,
                          })}
                        />
                        <label htmlFor="subject">{t("contactSubject")}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                          {...methodsOnSubmit.register("messageText", {
                            required: true,
                          })}
                        ></textarea>
                        <label htmlFor="message">{t("contactMessage")}</label>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary-gradient rounded-pill py-3 px-5"
                        type="submit"
                        onClick={onSubmit}
                      >
                        {t("contactSendMessage")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Contact;
