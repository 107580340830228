import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOutSuccess } from "../Redux/slices/UserSlice";
import { userApi } from "../Redux/services/UserApi";
import { authApi } from "../Redux/services/AuthApi";
import { adminApi } from "../Redux/services/AdminApi";
import { subscribeApi } from "../Redux/services/SubscribeApi";
import { analyticsApi } from "../Redux/services/AnalyticsApi";
import { cardApi } from "../Redux/services/CardApi";
import { pricingPlanApi } from "../Redux/services/PricingPlanApi";
import { shortURLApi } from "../Redux/services/ShortURLApi";

export default function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch(signOutSuccess());
    dispatch(userApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(adminApi.util.resetApiState());
    dispatch(subscribeApi.util.resetApiState());
    dispatch(analyticsApi.util.resetApiState());
    dispatch(cardApi.util.resetApiState());
    dispatch(pricingPlanApi.util.resetApiState());
    dispatch(shortURLApi.util.resetApiState());
    navigate("/auth/signin");
  };

  return handleLogOut;
}
