import {useTranslation} from "react-i18next";

const Features = () => {
    const { t } = useTranslation();
    return (
        <div className="container-xxl py-5" id="feature">
            <div className="container py-5 px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">{t("featuresTitle")}</h5>
                    <h1 className="mb-5">{t("featuresSubTitle")}</h1>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                                style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-eye text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">{t("features1Title")}</h5>
                            <p className="m-0">{t("features1Text")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                                style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-layer-group text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3"> {t("features2Title")}</h5>
                            <p className="m-0">{t("features2Text")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                                style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-edit text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">{t("features3Title")}</h5>
                            <p className="m-0">{t("features3Text")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                                style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-shield-alt text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">{t("features4Title")}</h5>
                            <p className="m-0">{t("features4Text")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                                style={{"width": "60px", "height": "60px"}}>
                                <i className="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">{t("features5Title")}</h5>
                            <p className="m-0">{t("features5Text")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded p-4">
                            <div
                                className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                                style={{"width": "60px", "height": "60px"}}>
                                <i className="fa fa-mobile-alt text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">{t("features6Title")}</h5>
                            <p className="m-0">{t("features6Text")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;