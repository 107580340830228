import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/navbar/navbar";
import {
  useCreateCustomQRMutation,
  useGetCustomQRQuery,
  useDeleteCustomQRMutation,
} from "../../Redux/services/UserApi";
import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import QRAnalytics from "../qranalytics/qranalytics";
import analyticsIcon from "../../assets/newIcons/16.svg";
import deleteIcon from "../../assets/newIcons/17.svg";
import download from "../../assets/newIcons/download.svg";
import ModalComponent from "../../components/modal/Modal";
import Header from "../../components/header/header";

export default function CustomQRs() {
  const [qrValue, setQrValue] = useState({
    link: "",
    name: "",
  });
  const [show, setShow] = useState(false);
  const { data: qrs, isSuccess } = useGetCustomQRQuery();
  const [createCustomQR] = useCreateCustomQRMutation();
  const [deleteCustomQR] = useDeleteCustomQRMutation();
  const [selectedQR, setSelectedQR] = useState(qrs?.data[0]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    // Optionally, collapse the sidebar when resizing
    // setExpanded(false);
  };

  const handleQRValues = e => {
    setQrValue(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCreateQR = async () => {
    const response = await createCustomQR({ url: qrValue.link, name: qrValue.name });

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeCreate"), type: "success" })
        );
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: t("toastErrorMessageQR"), type: "error" })
      );
    }

    setQrValue({
      link: "",
      name: "",
    });
  };
  const handleDeleteQR = async id => {
    const response = await deleteCustomQR(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeDelete"), type: "success" })
        );
      }
      setShow(false);
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const handleModal = qr => {
    setSelectedQR(qr);
    setShow(!show);
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedQR(qrs?.data[0]);
    }
  }, [isSuccess, qrs?.data]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <Header />
      ) : (
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
      )}
      <Sidebar />
      <div
        className="container-xxl row "
        style={{
          marginTop: isMobile ? "0" : "5rem",
          marginLeft: isMobile ? "0" : "2rem",
          transition: "margin-top 0.5s",
        }}
      >
        <div className="col-md-6 col-12">
          <div className=" d-flex flex-column gap-2 ">
            <div
              className="align-items-center d-flex  gap-3 d-md-inline-flex "
              style={{
                padding: "0.5rem",
                borderRadius: "30px",
                backgroundColor: "#7836B5",

                color: "white",
              }}
            >
              <h4
                className="fs-3 m-0"
                style={{
                  width: "fit-content",
                }}
              >
                {t("CreateQRCode")}
              </h4>
            </div>
            <input
              type="text"
              name="name"
              className="form-control mt-1"
              placeholder={t("nameQr")}
              value={qrValue.name}
              onChange={e => handleQRValues(e)}
            />
            <input
              type="text"
              name="link"
              className="form-control"
              placeholder={t("enterURL")}
              value={qrValue.link}
              onChange={e => handleQRValues(e)}
            />
            <button
              className="btn btn-primary-gradient mt-3"
              disabled={user?.pricingPlan?.qrLimit === qrs?.data?.length}
              onClick={handleCreateQR}
            >
              {t("generateQR")}
            </button>
          </div>
          <div className="">
            <table className="table ">
              <thead>
                <tr className="small">
                  <th>{t("name")}</th>

                  <th className="d-flex justify-content-center ">{t("qrCode")}</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {qrs?.data?.map(qr => (
                  <tr key={qr.id}>
                    <td>{qr.name}</td>

                    <td className="d-flex justify-content-center ">
                      <img
                        src={qr.qr}
                        alt={qr.name}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </td>
                    <td className=" pt-3">
                      <div className="d-flex flex-column justify-content-between  gap-3">
                        <div className="d-flex align-items-center gap-3">
                          {user?.pricingPlan?.canAnalytics && (
                            <span
                              onClick={() => {
                                setSelectedQR(qr);
                                console.log(qr);
                              }}
                              className="d-flex "
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={analyticsIcon}
                                alt="analytics"
                                width={30}
                                height={30}
                              />
                            </span>
                          )}
                          <span
                            onClick={() => handleDownload(qr.qr, `QR_${qr.name}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={download}
                              alt="download"
                              width={30}
                              height={30}
                            />
                          </span>
                          <span
                            className="d-none d-md-flex ms-4 "
                            onClick={() => handleModal(qr)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={deleteIcon}
                              alt="delete"
                              width={30}
                              height={30}
                            />
                          </span>
                        </div>

                        <span
                          className="d-md-none d-flex justify-content-around mt-3"
                          onClick={() => handleModal(qr)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete"
                            width={30}
                            height={30}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="my-3 d-none d-md-block">
            <Link
              className="btn btn-primary-gradient mt-3"
              to="/auth/profile/card"
            >
              {t("backToProfile")}
            </Link>
          </div>
        </div>
        {user?.pricingPlan?.canAnalytics ? (
          <div className="col-md-6 col-12">
            <QRAnalytics
              qr={selectedQR}
              showMargin={false}
              showHeader={false}
            />
          </div>
        ) : (
          ""
        )}
        <div className="my-3 d-block d-md-none">
          <Link
            className="btn btn-primary-gradient"
            to="/auth/profile/card"
          >
            {t("backToProfile")}
          </Link>
        </div>
      </div>
      <ModalComponent
        show={show}
        handleModal={() => handleModal(null)}
        title={t("deleteQR")}
        desc={`${t("confirmDeleteQR")} ${selectedQR?.name}?`}
        func={() => handleDeleteQR(selectedQR?.id)}
      />
    </>
  );
}
